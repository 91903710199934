// import { Tune } from "@material-ui/icons";
import React, { Component } from "react";
import CustomSnackbar from "../Components/CustomSnackbar";
import Loader from "../Components/Loader";
import { setFellowForm } from "../Sevices/service";

class FellowQuestions extends Component {
    constructor() {
        super();
        this.myRef = React.createRef();
        this.state = {
            fields: {
                name: "",
                age: undefined,
                mobileno: undefined,
                email: undefined,
                language: undefined,
                qualification: undefined,
                qualificationOther: undefined,
                address: "surat",
                graduationDate: "dd/mm/yyyy",
                hearAboutasadhran: "Word of mouth",
                comfortableGrade: "Yes",
                previousWorkExp: undefined,
                comfortable: "Any-Medium",
                laptopuse: "",
                laptop: "",
                reasonOfJoining: "",
                efforts: "",
                school: "",
                commitment: "",
                file: undefined
            },
            open: false,
            alertMessage: "",
            errors: {},
            loaderAction: true,
            isEnglish: true,
            // isEnglish: "हिन्दी",
            // isGujarati: "ગુજરાતિ"
        }
    }

    componentDidMount() {
        this._isMounted = true;
        window.scrollTo(0, 0);
        setTimeout(() => {
            if (this._isMounted) {
                this.setState({ loaderAction: false });
            }
        }, 2000)
    }

    handleChange = (e) => {
        console.log('log', e.target.value);
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields
        });

        if (e.target.name === "qualificationOther") {
            fields["qualification"] = e.target.value;
            this.setState({ fields });
        }
    }

    handleFileUpload = event => {
        let fields = this.state.fields;
        fields['file'] = event.target.files[0];
        this.setState({ fields });
    };

    submituserRegistrationForm = (e) => {
        console.log('submit');
        e.preventDefault();
        // if (this.validateForm()) {
            let fields = {};
            fields["name"] = "";
            fields["mobileno"] = "";
            fields["email"] = "";
            fields["age"] = "";
            fields["gender"] = "";
            fields["qualification"] = "";
            fields["language"] = "";
            fields["address"] = "Surat";
            fields["graduationDate"] = "";
            fields["hearAboutasadhran"] = "Word of mouth";
            fields["comfortableGrade"] = "Yes";
            fields["previousWorkExp"] = "";
            fields["laptopuse"] = "";
            fields["laptop"] = "";
            fields["comfortable"] = "Any-Medium";
            fields["reasonOfJoining"] = "";
            fields["efforts"] = "";
            fields["school"] = "";
            fields["commitment"] = "";
            fields["file"] = "";

            this.setState({ fields: fields });

            const formData = new FormData();
            formData.append('full_name', this.state.fields.name);
            formData.append('mobile_number', this.state.fields.mobileno);
            formData.append('email', this.state.fields.email);
            formData.append('age', this.state.fields.age);
            formData.append('gender', this.state.fields.gender);
            formData.append('higestQualification', this.state.fields.qualification);
            formData.append('language', this.state.fields.language);
            formData.append('city', this.state.fields.address);
            formData.append('dateOfGraduation', this.state.fields.graduationDate);
            formData.append('hearAboutasadhran', this.state.fields.hearAboutasadhran);
            formData.append('comfortableGrade', this.state.fields.comfortableGrade);
            formData.append('previousWorkExp', this.state.fields.previousWorkExp);
            formData.append('useOflaptop', this.state.fields.laptopuse);
            formData.append('havelaptop', this.state.fields.laptop);
            formData.append('mediumOfComfort', this.state.fields.comfortable);
            formData.append('reasonOfJoining', this.state.fields.reasonOfJoining);
            formData.append('extraEfforts', this.state.fields.efforts);
            formData.append('excellentSchool', this.state.fields.school);
            formData.append('workCommitment', this.state.fields.commitment);
            formData.append('resume', this.state.fields.file);

            setFellowForm(formData)
                .then(response => {
                    // console.log('response', response);
                    this.setState({ open: true, alertMessage: "Message successfully sent" });
                    this.triggerChildAlert();
                })
                .catch(error => {
                    // console.log('error', error);
                    this.setState({ open: true, alertMessage: "Somthing Went wrong. Please try again" });
                    this.triggerChildAlert();
                })
        // }

    }

    validateForm = () => {

        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        // if (!fields["name"]) {
        //     formIsValid = false;
        //     errors["name"] = "*Please enter your name.";
        // }

        if (typeof fields["name"] !== "undefined") {
            if (!fields["name"].match(/^[a-zA-Z ]*$/)) {
                formIsValid = false;
                errors["name"] = "*Please enter alphabet characters only.";
            }
        }

        if (typeof fields["age"] !== "undefined") {
            if (!fields["age"].match(/^(0?[1-9]|[1-9][0-9]|[1][1-9][1-9]|200)$/)) {
                formIsValid = false;
                errors["age"] = "*Please enter valid Age.";
            }
        }

        if (typeof fields["mobileno"] !== "undefined") {
            if (!fields["mobileno"].match(/^[0-9]{10}$/)) {
                formIsValid = false;
                errors["mobileno"] = "*Please enter valid mobile no.";
            }
        }

        if (typeof fields["email"] !== "undefined") {
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(fields["email"])) {
                formIsValid = false;
                errors["email"] = "*Please enter valid email-ID.";
            }
        }

        // if (typeof fields["language"] !== "undefined") {
        //     var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        //     if (!pattern.test(fields["language"])) {
        //         formIsValid = false;
        //         errors["language"] = "*Please enter valid email-ID.";
        //     }
        // }

        this.setState({
            errors: errors
        });
        return formIsValid;


    }

    triggerChildAlert() {
        this.myRef.current.handleClick(this.state.open, this.state.alertMessage);
    }

    handleLanguage = () => {
        this.setState({ isEnglish: !this.state.isEnglish });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return (
            <div className="full" id="join_us">
                {this.state.loaderAction ? <Loader /> : null}

                {/* <!-- Section Programs --> */}
                <CustomSnackbar ref={this.myRef} />
                <section className="color-section white-bgcolor">
                    <div className="container pt-150">
                        <div className="col-lg-12">
                            <div className="section-heading flex-center">
                                <img src="img/fellow2.jpg" alt="" className="img-responsive" />
                            </div>
                            <img src="img/boy.png" alt="" className="img-responsive mt-50 boy-icon" />
                            <img src="img/girl.png" alt="" className="img-responsive mt-50 girl-icon" />
                        </div>
                        <div className="col-lg-12 form-border">
                            <div className="mt-40 row d-flex justify-content-center">
                                <button
                                    className="btn center-block col-lg-3"
                                    onClick={this.handleLanguage}
                                >
                                    {this.state.isEnglish ? "English" : "हिन्दी"}
                                </button>
                                {/* <button
                                    className="btn center-block col-lg-3"
                                    onClick={e => this.handleLanguage(true)}
                                >
                                    हिन्दी
                                </button>
                                <button
                                    className="btn center-block col-lg-3"
                                    onClick={e => this.handleLanguage(false)}
                                >
                                    ગુજરાતિ
                                </button> */}
                            </div>

                            <div className="row pad-vh">
                                <form method="post" name="userRegistrationForm" onSubmit={this.submituserRegistrationForm}>
                                    {/* <!-- Form Starts --> */}
                                    <div id="fellow_form" className="row">
                                        <div className="row">
                                            <div className="form-group col-lg-6">
                                                <label htmlFor="name">{this.state.isEnglish ? "1. Full Name *" : "१. पूरा नाम *"}</label>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    id="name"
                                                    className="form-control input-field"
                                                    placeholder="Enter Full Name"
                                                    value={this.state.fields.name}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                                <div className="errorMsg">{this.state.errors.name}</div>
                                            </div>
                                            <div className="form-group col-lg-6">
                                                <label htmlFor="number">{this.state.isEnglish ? "2. Mobile Number *" : "२. मोबाइल नंबर *"}</label>
                                                <input
                                                    type="number"
                                                    name="mobileno"
                                                    id="number"
                                                    className="form-control input-field"
                                                    placeholder="Enter Mobile Number"
                                                    value={this.state.fields.mobileno}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                                <div className="errorMsg">{this.state.errors.mobileno}</div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-lg-6">
                                                <label htmlFor="email">{this.state.isEnglish ? "3. Email *" : "२. ईमेल *"}</label>
                                                <input
                                                    type="email"
                                                    name="email"
                                                    id="email"
                                                    className="form-control input-field"
                                                    placeholder="Enter email"
                                                    value={this.state.fields.email}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                                <div className="errorMsg">{this.state.errors.email}</div>
                                            </div>
                                            <div className="form-group col-lg-6">
                                                <label htmlFor="language">{this.state.isEnglish ? "4. Your language *" : "२. आपकी भाषा *"}</label>
                                                <select name="language" id="language" value={this.state.fields.language} className="form-control input-field" onChange={this.handleChange}>
                                                    <option value="english" >English</option>
                                                    <option value="hindi">Hindi</option>
                                                    <option value="gujrati">Gujrati</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-lg-6">
                                                <label htmlFor="age">{this.state.isEnglish ? "5. Age *" : "३. उम्र *"}</label>
                                                <input
                                                    type="number"
                                                    name="age"
                                                    id="age"
                                                    className="form-control input-field"
                                                    placeholder="Enter Age"
                                                    value={this.state.fields.age}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                                <div className="errorMsg">{this.state.errors.age}</div>
                                            </div>
                                            <div className="form-group col-lg-6">
                                                <label>6. Gender</label>
                                                <ul className="radio-btn">
                                                    <li>
                                                        <label className="container-cust text-left">
                                                            Male
                                                            <input type="radio" checked={this.state.fields.gender === "Male"} name="gender" value="Male" onChange={this.handleChange}
                                                            />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label className="container-cust text-left">
                                                            Female
                                                            <input type="radio" name="gender" checked={this.state.fields.gender === "Female"} value="Female" onChange={this.handleChange} />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label className="container-cust text-left">
                                                            Other
                                                            <input type="radio" name="gender" checked={this.state.fields.gender === "Other"} value="Other" onChange={this.handleChange}
                                                            />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label className="container-cust text-left">
                                                            Prefer not to tell
                                                            <input type="radio" name="gender" checked={this.state.fields.gender === "Prefer not to tell"} value="Prefer not to tell" onChange={this.handleChange}
                                                            />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </li>
                                                </ul>
                                            </div>

                                        </div>
                                        <div className="row">
                                            <div className="form-group col-lg-6">
                                                <label htmlFor="address">{this.state.isEnglish ? "7. Address, City (Only Surat) *" : "५. पता"}</label>
                                                <input
                                                    type="text"
                                                    name="address"
                                                    id="address"
                                                    className="form-control input-field"
                                                    placeholder="Enter Address"
                                                    value="Surat"
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group col-lg-6">
                                                <label htmlFor="date">8. Date of Graduation or Post-graduation *</label>
                                                <input
                                                    type="text"
                                                    name="graduationDate"
                                                    id="date"
                                                    className="form-control input-field"
                                                    placeholder="Enter date"
                                                    value={this.state.fields.graduationDate}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-lg-6">
                                                <label>{this.state.isEnglish ? "9. Highest Education Qualification *" : "४. शैक्षणिक योग्यता *"}</label>
                                                <ul className="radio-btn">
                                                    <li className="col-lg-6">
                                                        <label className="container-cust text-left">
                                                            {this.state.isEnglish ? "Graduate Student" : "स्नातक (ग्रैजूएशन)"}
                                                            <input type="radio" checked={this.state.fields.qualification === "Graduate Student"} name="qualification" value="Graduate Student" onChange={this.handleChange} />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </li>
                                                    <li className="col-lg-6">
                                                        <label className="container-cust text-left">
                                                            {this.state.isEnglish ? "Post Graduate Student" : "स्नातकोत्तर (पोस्ट-ग्रैजूएशन)"}
                                                            <input type="radio" checked={this.state.fields.qualification === "Post Graduate Student"} name="qualification" value="Post Graduate Student" onChange={this.handleChange} />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </li>
                                                    <li className="col-lg-6">
                                                        <label className="container-cust text-left">
                                                            <textarea
                                                                type="text"
                                                                name="qualificationOther"
                                                                className="form-control input-field"
                                                                placeholder="Other"
                                                                value={this.state.fields.qualificationOther || ""}
                                                                onChange={this.handleChange}
                                                                rows={1}
                                                            />

                                                            <input type="radio" checked={this.state.fields.qualification === this.state.fields.qualificationOther} name="timing" value={this.state.fields.qualificationOther} onChange={this.handleChange}
                                                            />
                                                            <span className="checkmark"></span>

                                                        </label>
                                                    </li>
                                                </ul>
                                                <div className="errorMsg">{this.state.errors.qualification}</div>
                                            </div>
                                            <div className="form-group col-lg-6">
                                                <label>{this.state.isEnglish ? "10. Will you be comfortable to teach Grade 1, 2 & 3 students? *" : "९. क्या आप ग्रेड 1, 2 और 3 के छात्रों को पढ़ाने में सहज हैं? *"}</label>
                                                <ul className="radio-btn">
                                                    <li>
                                                        <label className="container-cust text-left">
                                                            {this.state.isEnglish ? "Yes" : "हां"}
                                                            <input type="radio" checked={this.state.fields.comfortableGrade === "Yes"} name="comfortableGrade" value="Yes" onChange={this.handleChange}
                                                            />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label className="container-cust text-left">
                                                            {this.state.isEnglish ? "No" : "नहीं"}
                                                            <input type="radio" name="comfortableGrade" checked={this.state.fields.comfortableGrade === "No"} value="No" onChange={this.handleChange} />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="form-group col-lg-6">
                                                <label>{this.state.isEnglish ? "11. Where did you hear about us? *" : "१२. आपने हमारे बारे में कहाँ सुना? *"}</label>
                                                <ul className="radio-btn">
                                                    <li className="col-lg-6">
                                                        <label className="container-cust text-left">
                                                            Friends & Colleagues
                                                            <input type="radio" checked={this.state.fields.hearAboutasadhran === "Friends & Colleagues"} name="hearAboutasadhran" value="Friends & Colleagues" onChange={this.handleChange} />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </li>
                                                    <li className="col-lg-6">
                                                        <label className="container-cust text-left">
                                                            Asaadharan team
                                                            <input type="radio" checked={this.state.fields.hearAboutasadhran === "Asaadharan team"} name="hearAboutasadhran" value="Asaadharan team" onChange={this.handleChange} />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </li>
                                                    <li className="col-lg-6">
                                                        <label className="container-cust text-left">
                                                            Facebook
                                                            <input type="radio" checked={this.state.fields.hearAboutasadhran === "Facebook"} name="hearAboutasadhran" value="Facebook" onChange={this.handleChange} />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </li>
                                                    <li className="col-lg-6">
                                                        <label className="container-cust text-left">
                                                            Instagram
                                                            <input type="radio" checked={this.state.fields.hearAboutasadhran === "Instagram"} name="hearAboutasadhran" value="Instagram" onChange={this.handleChange} />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </li>
                                                    <li className="col-lg-6">
                                                        <label className="container-cust text-left">
                                                            Word of Mouth
                                                            <input type="radio" checked={this.state.fields.hearAboutasadhran === "Word of Mouth"} name="hearAboutasadhran" value="Word of Mouth" onChange={this.handleChange} />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </li>
                                                    <li className="col-lg-6">
                                                        <label className="container-cust text-left">
                                                            Newspaper
                                                            <input type="radio" checked={this.state.fields.hearAboutasadhran === "Newspaper"} name="hearAboutasadhran" value="Newspaper" onChange={this.handleChange} />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="form-group col-lg-6">
                                                <label>{this.state.isEnglish ? "12. In which medium school/(s) would you be more comfortable in Teaching? *" : "20. आप किस माध्यम के स्कूल/स्कूलों में अध्यापन में अधिक सहज महसूस करेंगे?*"}</label>
                                                <ul className="radio-btn">
                                                    <li>
                                                        <label className="container-cust text-left">
                                                            {this.state.fields.comfortable ? "English-medium Government school" : "अंग्रेज़ी मीडीयम सरकारी स्कूल"}
                                                            <input type="radio" checked={this.state.fields.comfortable === "English-medium"} name="comfortable" value="English-medium" onChange={this.handleChange}
                                                            />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label className="container-cust text-left">
                                                            {this.state.fields.comfortable ? "Hindi-medium Government shool" : "हिंदी मीडीयम सरकारी स्कूल"}
                                                            <input type="radio" name="comfortable" checked={this.state.fields.comfortable === "Hindi-medium"} value="Hindi-medium" onChange={this.handleChange} />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label className="container-cust text-left">
                                                            {this.state.fields.comfortable ? "Gujarati Medium School" : "गुजराती मीडीयम सरकारी स्कूल"}
                                                            <input type="radio" name="comfortable" checked={this.state.fields.comfortable === "Gujarati-Medium"} value="Gujarati-Medium" onChange={this.handleChange} />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label className="container-cust text-left">
                                                            {this.state.fields.comfortable ? "Any Medium School" : "कोई भी - अंग्रेज़ी या हिंदी मीडीयम सरकारी स्कूल"}
                                                            <input type="radio" name="comfortable" checked={this.state.fields.comfortable === "Any-Medium"} value="Any-Medium" onChange={this.handleChange} />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-lg-6">
                                                <label>{this.state.isEnglish ? "13. Tell us about your previous work experience? *" : "१६. अपने पिछले कार्य अनुभव के बारे में बताएं? *"}</label>
                                                <div className="d-flex">
                                                    <input
                                                        type="text"
                                                        name="previousWorkExp"
                                                        className="form-control input-field"
                                                        value={this.state.fields.previousWorkExp}
                                                        onChange={this.handleChange}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group col-lg-6">
                                                <label>{this.state.isEnglish ? "14. Do you know how to use the laptop *" : "१8. क्या आप लैपटॉप का उपयोग करना जानते हैं *"}</label>
                                                <div className="d-flex">
                                                    <input
                                                        type="text"
                                                        name="laptopuse"
                                                        className="form-control input-field"
                                                        value={this.state.fields.laptopuse}
                                                        onChange={this.handleChange}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="form-group col-lg-6">
                                                <label>{this.state.isEnglish ? "15. Do you have your own laptop? *" : "१९. क्या आपके पास अपना लैपटॉप है? *"}</label>
                                                <div className="d-flex">
                                                    <input
                                                        type="text"
                                                        name="laptop"
                                                        className="form-control input-field"
                                                        value={this.state.fields.laptop}
                                                        onChange={this.handleChange}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group col-lg-6">
                                                <label>{this.state.isEnglish ? "16. Why do you want to join Asaadharan Fellowship? *" : "19. आप असाधारण फेलोशिप से क्यों जुड़ना चाहते हैं? *"}</label>
                                                <div className="d-flex">
                                                    <input
                                                        type="text"
                                                        name="reasonOfJoining"
                                                        className="form-control input-field"
                                                        value={this.state.fields.reasonOfJoining}
                                                        onChange={this.handleChange}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="form-group col-lg-6">
                                                <label>{this.state.isEnglish ? "17. Tell us about a time when you took exceptional efforts to achieve something. *" : "22. हमें उस समय के बारे में बताएं जब आपने कुछ हासिल करने के लिए असाधारण प्रयास किए थे *"}</label>
                                                <div className="d-flex">
                                                    <input
                                                        type="text"
                                                        name="efforts"
                                                        className="form-control input-field"
                                                        value={this.state.fields.efforts}
                                                        onChange={this.handleChange}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group col-lg-6">
                                                <label>{this.state.isEnglish ? "18. What according to you is an excellent School? *" : "22. आपके अनुसार एक उत्कृष्ट विद्यालय क्या है? *"}</label>

                                                <div className="d-flex">
                                                    <input
                                                        type="text"
                                                        name="school"
                                                        className="form-control input-field"
                                                        value={this.state.fields.school}
                                                        onChange={this.handleChange}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="form-group col-lg-6">
                                                <label> {this.state.isEnglish ? "19. This Fellowship requires 3 years of commitment, are you willing to give that commitment? *" : "23. इस फैलोशिप के लिए 3 साल की प्रतिबद्धता की आवश्यकता है, क्या आप वह प्रतिबद्धता देने को तैयार हैं? *"}</label>
                                                <ul className="radio-btn">
                                                    <li>
                                                        <label className="container-cust text-left">
                                                            {this.state.isEnglish ? "Yes" : "हां"}
                                                            <input type="radio" checked={this.state.fields.commitment === "Yes"} name="commitment" value="Yes" onChange={this.handleChange}
                                                            />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label className="container-cust text-left">
                                                            {this.state.isEnglish ? "No" : "नहीं"}
                                                            <input type="radio" name="commitment" checked={this.state.fields.commitment === "No"} value="No" onChange={this.handleChange} />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="col-lg-6">
                                                <label> 20. Attach Your Resume</label>
                                                <input type="file"
                                                    onChange={this.handleFileUpload} />
                                            </div>
                                        </div>

                                        <div className="col-lg-12 d-flex justify-content-center">
                                            <button
                                                type="submit"
                                                id="submit_btn_fellow"
                                                value="Submit"
                                                className="btn center-block mt-40 col-lg-4"
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="mt-20 col-lg-12 d-flex">
                                <p className="text-left">NOTE — Asaadharan fellows are going to be trained by us to work as teachers in schools, who will provide holistic education to children at foundational level and will also help identify the loopholes in the education system and work on them.</p>
                                <p className="text-left"> </p>
                            </div>
                        </div>

                    </div>
                    {/* <!-- /container--> */}
                </section>
                {/* <!-- /Section ends --> */}
            </div>
        );
    }
}

export default FellowQuestions;
