import React, { Component } from "react";

class OurCoreValues extends Component {
  state = {};
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="full" id="about_foundation">
        <section id="about">
          <div className="row features">
            <h3>Our Core Values</h3>
            {/* <!-- First Collumn --> */}
            <div className="col-lg-12 col-sm-12 mb-20">
              {/* <!-- item1 --> */}
              <div className="col-md-4 media text-center">
                {/* <i className="glyph-icon flaticon-school-4"></i> */}
                <i className="fa fa-users"></i>
                <div className="media-body">
                  <h3 className="media-heading">Leadership</h3>
                  <p>We are agile, operate with a high sense of possibility and continuously seek opportunities to learn. We inspire each other to achieve our vision.</p>
                </div>
              </div>
              {/* <!-- item2 --> */}
              <div className="col-md-4 media text-center">
                {/* <i className="glyph-icon flaticon-people-5"></i> */}
                <i className="fa fa-handshake-o"></i>
                <div className="media-body">
                  <h3 className="media-heading">Collaboration</h3>
                  <p>We learn from the best practices of our stakeholders and create win-win partnerships to multiply the impact.</p>
                </div>
              </div>
              {/* <!-- item3 --> */}
              <div className="col-md-4 media text-center">
                {/* <i className="glyph-icon flaticon-music"></i> */}
                <i className="fa fa-heart"></i>
                <div className="media-body">
                  <h3 className="media-heading">Love</h3>
                  <p>We are a fun-loving organization that operates with compassion and commitment to create an inclusive environment.</p>
                </div>
              </div>
            </div>
            {/* <!-- Second Collumn --> */}
            <div className="col-lg-12 col-sm-12">
              {/* <!-- item4 --> */}
              <div className="col-md-6 media text-center">
                {/* <i className="glyph-icon flaticon-person"></i> */}
                <i className="fa fa-eye"></i>
                <div className="media-body">
                  <h3 className="media-heading">Reflection</h3>
                  <p>We grow by continuously learning from our mistakes, giving and receiving feedback and becoming aware of our actions and mindsets.</p>
                </div>
              </div>
              {/* <!-- item5 --> */}
              <div className="col-md-6 media text-center">
                {/* <i className="glyph-icon flaticon-people-8"></i> */}
                <i className="fa fa-balance-scale"></i>
                <div className="media-body">
                  <h3 className="media-heading">Integrity</h3>
                  <p>Our words and actions are aligned with our vision. This enables us to create an honest and trustworthy ecosystem.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default OurCoreValues;
