import { create, read } from '../Sevices/index';

// export function getGallery() {
//     let userParam = {
//         route: '/get-gallary',
//         headers:  { }
//     }
//     return read(userParam);
// }

export function getGalleryGroup() {
    let userParam = {
        route: '/sitemanager/api/get-gallery-grp',
        headers:  { }
    }
    return read(userParam);
}

export function getGallery(groupId) {
    let userParam = {
        route: `/api/filter_by/${groupId}`,
        headers:  { }
    }
    return read(userParam);
}

export function setFellowForm(fellowObject) {
    console.log('service',fellowObject);
    let userParam = {
        route: '/sitemanager/api/save-fellow',
        jsonObject: fellowObject,
        headers:  { }
    }
    return create(userParam);
}

export function setCareerForm(careerObject) {
    let userParam = {
        route: '/sitemanager/api/save-career',
        jsonObject: careerObject,
        headers: {  'content-type': 'multipart/form-data'}
    }
    return create(userParam);
}

export function setVolunteerForm(volunteerObject) {
    let userParam = {
        route: '/sitemanager/api/save-volunteer',
        jsonObject: volunteerObject,
        headers: {  'content-type': 'multipart/form-data'}
    }
    return create(userParam);
}

export function setInquire(InquireObject) {
    let userParam = {
        route: '/sitemanager/api/save-contact',
        jsonObject: InquireObject,
        headers: {  'content-type': 'multipart/form-data'}
    }
    return create(userParam);
}