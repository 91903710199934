import React from "react";

class ImgSlider extends React.Component {
    state = {}
    render() {
        return (
            <div className="col-sm-12 col-lg-12">
            <div id={this.props.id} className="owl-carousel">
                <div className="item">
                    <img className="img-responsive img-curved" src="img/fellow_1.jpg" alt="" />
                </div>
                <div className="item">
                    <img className="img-responsive img-curved" src="img/fellow_2.jpg" alt="" />
                </div>
                <div className="item">
                    <img className="img-responsive img-curved" src="img/fellow_3.jpg" alt="" />
                </div>
                <div className="item">
                    <img className="img-responsive img-curved" src="img/fellow_4.jpg" alt="" />
                </div>
            </div>
        </div>
        );
    }
}

export default ImgSlider;