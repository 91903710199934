import React, { Component } from "react";
import FloatDonate from "./FloatDonate";

class Footer extends Component {
  state = {};
  render() {
    return (
      <footer >
        <div
          className="parallax-object2 hidden-sm hidden-xs"
          data-0="opacity:1;"
          data-start="margin-top:45%"
          data-100="transform:translatey(0%);"
          data-center-center="transform:translatey(-140%);"
        >
          <img src="img/parallaxobject2.png" alt="" />
        </div>
        <div
          className="parallax-object3 hidden-sm hidden-xs"
          data-0="opacity:1;"
          data-100="transform:translatex(0%);"
          data-center-center="transform:translatex(380%);"
        >
          <img src="img/parallaxobject3.png" alt="" />
        </div>
        <section id="prices" className="color-section white-bgcolor">
          <svg
            id="triangleShadow"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            width="100%"
            height="100"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
          >
            <path className="trianglePath1" d="M0 0 L50 100 L100 0 Z" />
          </svg>
          <div className="container">
            <div className="col-lg-8 col-lg-offset-2">
              <div className="section-heading">
                {/* <h2>Our Prices</h2> */}
              </div>
            </div>
          </div>
        </section>

        <div className="light-gray footer-p">

          <div className="container-fluid">
            {/* <!-- Newsletter --> */}
            <div className="col-lg-4 col-md-12 text-center res-margin">
              <img
                src="img/ALogo.png"
                alt=""
                className="center-block"
              />
            </div>
            {/* <!-- /col-lg-4 --> */}
            {/* <!-- Bottom Credits --> */}
            <div className="col-lg-4 col-md-12 res-margin visible-lg-block hidden">
              <a href="#page-top">
                <h5 className="text-light mb-40 mt-30 blue-color">
                  {/* Connect With Us */}
                </h5>
              </a>
            </div>
            {/* <!-- /col-lg-4 --> */}
            {/* <!-- Opening Hours --> */}
            <div className="col-lg-4 col-md-12 text-center">
              {/* <!-- Sign--> */}
              <h6 className="text-light blue-color">Opening Hours:</h6>
              {/* <!-- Table--> */}
              <table className="table">
                <tbody>
                  <tr>
                    <td className="text-left pink-color">Monday to Friday</td>
                    <td className="text-right pink-color">7 a.m. to 7 p.m.</td>
                  </tr>
                  <tr>
                    <td className="text-left pink-color">Weekends / Holidays</td>
                    <td className="text-right">
                      <span className="label label-danger">Closed</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {/* <!-- /col-lg-4 --> */}
          </div>
          {/* <!-- / container --> */}
          <div className="border-bottom-footer"></div>
          <div className="d-flex justify-content-between flex-sm-wrap justify-sm-center">
            <div className="footer-links">
              <a className="cursor-p" href="process.env.PUBLIC_URL">Privacy Policy</a>
              <a href="pdf/Child_Protection_Policy.pdf" className="cursor-p" target="_blank">Children Protection Policy</a>
              <a className="cursor-p" href="process.env.PUBLIC_URL">Terms And Conditions</a>
            </div>
            {/* <!-- social-icons --> */}
            <div className="social-media">
              <a href="https://twitter.com/asaadharan" title="" target="_blank" rel="noreferrer">
                <i className="fa fa-twitter"></i>
              </a>
              <a
                href="https://www.facebook.com/asaadharan/"
                title=""
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa fa-facebook"></i>
              </a>
              <a
                href="https://www.instagram.com/asaadharanfoundation/"
                title=""
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa fa-instagram"></i>
              </a>
              <a
                href="https://www.linkedin.com/in/asaadharan-foundation-48ab441b0"
                title=""
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa fa-linkedin"></i>
              </a>
            </div>
          </div>
          <div>
            <p className="gray-color font-12">
              Copyright © 2020 - 2021 / Designed by{" "}
              <a href="http://4eversolutions.com" className="gray-color" target="_blank" rel="noreferrer">
                4eversolutions.com
              </a>
            </p>
          </div>
          {/* <!-- /container --> */}
          {/* <!-- Go To Top Link --> */}
          <div className="page-scroll hidden-sm hidden-xs">
            <a href="#page-top" className="back-to-top">
              <i className="fa fa-angle-up"></i>
            </a>
          </div>
        </div>
        <FloatDonate />
      </footer >
      /* <!-- /footer ends --> */
    );
  }
}

export default Footer;
