import React, { Component } from "react";
import Loader from "../Components/Loader";
import JobOpening from "./JobOpening";

class Career extends Component {
    state = { loaderAction: true };
    handleApply = (route) => {
        if (window.location.pathname === "/beta/" && route === "/") {
        } else {
            this.props.history.push(process.env.PUBLIC_URL + route);
            window.location.reload(true);
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        setTimeout(() => {
            this.setState({ loaderAction: false });
        }, 2000)
    }
    render() {
        return (
            <div className="full" id="join_us">
                {this.state.loaderAction ? <Loader /> : null}

                {/* <!-- Section Programs --> */}
                <section className="color-section white-bgcolor">
                    <div className="container pt-150">
                        <div className="col-lg-12">
                            {/* <!-- Section heading --> */}
                            <div className="section-heading flex-center">
                                <img src="img/careerr.jpg" alt="" className="img-responsive" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12 mb-20">
                                <h3 className="blue-color">“As you grow older, you will discover that you have two hands — one for helping yourself, the other for helping others.” — Audrey Hepburn</h3>
                                <p>Join our team to work with us full time and become a major catalyst in changing children’s foundational education. This is also a great opportunity to develop necessary leadership skills and identify your potential while working and empathising with the hardships faced by children at ground level. Don’t just dream about bringing a change but take this opportunity to actually be the change-maker.
                                    We assure you that we are a lovely team to work with.</p>
                            </div>
                            <div className="col-lg-12 position-relative">
                                <img src="img/y3.jpg" alt="career" className="img-responsive zig-zag-border" />
                                <button
                                    // onClick={(e) => {
                                    //     e.preventDefault();
                                    //     window.location.href = 'https://docs.google.com/forms/d/1DBv2Kz_ms0x9eL5IFXmpBEVFF9R8sD7ZXmTeJ-tqYjU/edit';
                                    // }}
                                    onClick={() => this.handleApply("/career-questions")}
                                    className="btn center-block mt-40 float-right career-btn"
                                >
                                    Apply Now
                                </button>
                            </div>
                        </div>
                    </div>
                    {/* <!-- /container--> */}
                </section>

                <section className="color-section white-bgcolor">
                    <div className="container pt-0 pb-40">
                        <div className="row">
                            <h2 className="gallery-head mb-20">Job Openings</h2>
                            <div className="col-lg-12 mb-20">
                                <JobOpening />
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- /Section ends --> */}
            </div>
        );
    }
}

export default Career;
