import React, { Component } from 'react';
import Loader from '../Components/Loader';
import OwlSilder from '../Components/OwlSlider';
import OurCoreValues from './OurCoreValues';

class AboutUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
          loaderAction: true
        };
      }
        componentDidMount() {
        window.scrollTo(0, 0);
        setTimeout(() => {
            this.setState({loaderAction: false});
          }, 2000)
    }
    render() {
        return (<div className="full" id="about_foundation">
            {this.state.loaderAction ? <Loader /> : null}

            <section id="about">
                <div className="container pt-150">
                    <div className="col-lg-12">
                        {/* <!-- Section heading --> */}
                        <div className="section-heading flex-center">
                            <img src="img/who-weare.jpg" alt="" className="img-responsive" />
                        </div>
                    </div>
                    <div className="row">
                        {/* <OwlSilder id={"owl-about"} /> */}
                        <div className="col-sm-12 col-lg-5">
                            <div className="mt-20 mb-20">
                                <img className="center-block img-responsive img-curved" src="img/imgg.jpg" alt="" />
                            </div>
                        </div>
                        {/* <!-- text --> */}
                        <div className="col-lg-7 col-sm-12">
                            {/* <h3>Who We Are</h3> */}
                            <p><b>Asaadharan Foundation is a Non-Governmental Organisation</b> registered under
                                Section 8 of the Companies Act, 2013. Asaadharan Foundation was <b>founded in
                                    January 2019</b>, by two passionate and young individuals, <b>Aastha Kanodia and
                                        Avani Chadha Fatehpuria</b>, with the objective of ensuring that quality
                                education and quality educators are accessible in the urban slum areas,
                                this objective is in line with the government’s continuous efforts of
                                providing free and accessible education to all.
                            </p>
                            <p>However, through our experience, we have witnessed that despite the
                                government’s relentless efforts to ensure that education and relevant
                                facilities are available in the most vulnerable parts of the country,
                                dropouts, retention of students, and availability of qualified teachers
                                still continues to remain an issue of concern.Therefore, it is evident
                                that free and compulsory primary education is not enough to ensure that
                                the child will pursue education further or will actually be educated.
                            </p>
                        </div>
                        <div className="col-lg-12 col-sm-12">
                            <p className="text-left">
                                In line with this, Asaadharan is committing itself to mitigate the issue of
                                dropouts, access to quality education and educators in the urban slum pockets
                                of Surat, Gujarat through its various capacity building programs with Municipal
                                school teachers and students.
                            </p>
                        </div>
                        {/* <!-- /col-lg-8 --> */}
                    </div>

                    <div className="row features">
                        <div className="d-flex justify-content-center">
                            <div className="col-lg-4 col-sm-12 home-approach-icons-item">
                                <div className="rel-wrapper">
                                    <img alt="Approach icon" data-src="img/vis-png.png" className="lazyloaded vision-img" src="img/vis-png.png" />
                                </div>
                                <div>
                                    <h3 className="cursive-text">Our Vision</h3>
                                    <div className="home-approach-hidden-info">
                                        <p>All Children have an equal opportunity to attain holistic education.</p></div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-12 home-approach-icons-item">
                                <div className="rel-wrapper">
                                    <img style={{ opacity: 1, width: '50%' }} alt="Approach icon" data-src="img/miss-png.png" className=" lazyloaded" src="img/miss-png.png" />
                                </div>
                                <div>
                                    <h3 className="cursive-text">Our Mission</h3>
                                    <div className="home-approach-hidden-info">
                                        <p>Building a movement of teachers, part of municipal schools and the community, who are empowered to provide holistic educational outcomes to children.</p></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row features">
                        <div className="col-lg-7 col-sm-12 vertical-center" >
                            <h3 className="text-transform-i">Philosophy</h3>
                            <p className="text-left">
                                Asaadharan works with a simple philosophy that learning has to be fun, hands-on and value-based in order to make a longer impact on a child. We understand that every child is unique and needs a different teaching method to reach his/her greatest potential. Hence, we focus on creative pedagogy and experiential learning to give a child the best holistic foundational education.</p>
                        </div>
                        <OwlSilder id={"owl-about_1"} />
                    </div>
                    {/* <!-- /row --> */}
                    <div className="row features">
                        {/* <!-- text --> */}
                        <div className="col-lg-10 col-sm-12">
                            <div className="comments-block m-0">
                                <h4 className="text-center">Our Problem</h4>
                                <div className="comment media orange-bgcolor white-color mb-0">
                                    <div className="media-body">
                                        <p className="text-left"><b>Reality : </b>  In Gujarat, more than 47% of grade 5 students and more than 27% of grade 8 students cannot read a simple text which has the difficulty of a grade 2 level. More than 80% of grade 5 students and more than 65% of grade 8 students cannot solve simple 3 digits by 1 digit division problems of a grade 2 level. This illustrates a severe lack of foundational skills in students which is leading to a 24% dropout rate after grade 8.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-10 col-sm-12 float-right">
                            <div className="comments-block m-0">
                                <h4 className="text-center">Our Solution</h4>
                                <div className="comment media green-bgcolor white-color mb-0">
                                    <div className="media-body">
                                        <p className="text-left"><b>Underlying causes : </b> Reduced importance of holistic foundational education which has varied complex reasons such as : -<br />
                                            Dearth of teachers at elementary level<br />
                                            Lack of creative pedagogy<br />
                                            Use of rote learning methods<br />
                                            Different priorities system wide and many more<br />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <OurCoreValues />
                    {/* </div> */}
                </div>

                {/* <!--/container--> */}
            </section >

        </div >);
    }
}

export default AboutUs;