import React, { Component } from "react";
import Donate from './Donate';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      classNameTeam: '',
      classNameProgram: '',
      classNameJoin: '',
      classNameAbout: '',
      // isTeam: false,
      // isProgram: false,
      // isJoin: false,
      // isAbout: false,
    };
  }
  showDropdown = (value) => {
    this.setState(value);
  }

  hideDropdown = (value) => {
    this.setState(value);
  }

  handleReload = (route) => {
    if (window.location.pathname === "/beta/" && route === "/") {
    } else {
      this.props.history.push(process.env.PUBLIC_URL + route);
      window.location.reload(true);
    }
  };

  render() {
    return (
      /* <!-- Navbar --> */
      <nav className="navbar navbar-custom navbar-fixed-top">
        <div className="text-left pad-top-header">
          <Donate />
        </div>
        {/* <!-- Brand and toggle get grouped for better mobile display --> */}
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle"
            data-toggle="collapse"
            data-target="#navbar-brand-centered"
          >
            <i className="fa fa-bars"></i>
          </button>
          <div className="navbar-brand-centered page-scroll">
            <a href="/">
              <img src="img/ALogo.png" alt="" />
            </a>
          </div>
        </div>

        {/* <!-- Collect the nav links, forms, and other content for toggling --> */}
        <div
          className="collapse navbar-collapse nav-height"
          style={{ height: "125px !important" }}
          id="navbar-brand-centered"
        >
          <div className="container d-flex justify-content-end">
            <ul className="nav navbar-nav page-scroll navbar-left">
              <li className={this.state.isActive}>
                <a
                  href="#page-top"
                  className="green-color top_nav"
                  onClick={() => this.handleReload("/")}
                >
                  Home
                </a>
              </li>

              <li className={"dropdown " + this.state.classNameAbout}
                onMouseEnter={() => this.showDropdown({ classNameAbout: 'open' })}
                onMouseLeave={() => this.hideDropdown({ classNameAbout: '' })}
              >
                <a
                  id="#about_foundation"
                  href="#about_foundation"
                  className="dropdown-toggle blue-color top_nav"
                  data-toggle="dropdown"
                  // onClick={() => !this.state.isAbout ? this.showDropdown({ classNameAbout: 'open', isAbout: !this.state.isAbout, isJoin: false, isProgram: false, isTeam: false, classNameJoin: '',classNameProgram: '',classNameTeam: '' }) : this.hideDropdown({ classNameAbout: '', isAbout: !this.state.isAbout })}
                >
                  About Us<b className="caret"></b>
                </a>
                <ul className="dropdown-menu white-gray-bgcolor width-fit">
                  <li>
                    <a
                      href="#about_foundation"
                      className="black-color subnav white-gray-bgcolor nav-height-down width-fit"
                      onClick={() => this.handleReload("/aboutus")}
                    >
                      Who We Are
                    </a>
                  </li>
                  <li>
                    <a
                      href="#about_foundation"
                      className="black-color subnav white-gray-bgcolor nav-height-down width-fit"
                      onClick={() => this.handleReload("/our-story")}
                    >
                      Our Story
                    </a>
                  </li>
                </ul>
              </li>

              <li className={"dropdown " + this.state.classNameTeam}
                onMouseEnter={() => this.showDropdown({ classNameTeam: 'open' })}
                onMouseLeave={() => this.hideDropdown({ classNameTeam: '' })}
              >
                <a
                  id="#our_team"
                  href="#our_team"
                  className="dropdown-toggle pink-color top_nav"
                  data-toggle="dropdown"
                  // onClick={() => !this.state.isTeam ? this.showDropdown({ classNameTeam: 'open', isTeam: !this.state.isTeam, isAbout: false, isJoin: false, isProgram: false, classNameJoin: '',classNameProgram: '',classNameAbout: '' }) : this.hideDropdown({ classNameTeam: '', isTeam: !this.state.isTeam })}
                >
                  Team<b className="caret"></b>
                </a>
                <ul className="dropdown-menu white-gray-bgcolor width-fit">
                  <li>
                    <a
                      href="#core_team"
                      className="black-color subnav white-gray-bgcolor nav-height-down width-fit"
                      onClick={() => this.handleReload("/advisors")}
                    >
                      Advisors
                    </a>
                  </li>
                  <li>
                    <a
                      href="#core_team"
                      className="black-color subnav white-gray-bgcolor nav-height-down width-fit"
                      onClick={() => this.handleReload("/team")}
                    >
                      Team
                    </a>
                  </li>
                </ul>
              </li>

              <li className={"dropdown " + this.state.classNameProgram}
                onMouseEnter={() => this.showDropdown({ classNameProgram: 'open' })}
                onMouseLeave={() => this.hideDropdown({ classNameProgram: '' })}
              >
                <a
                  id="#our_programs"
                  className="dropdown-toggle orange-color top_nav"
                  data-toggle="dropdown"
                  href="#our_programs"
                  // onClick={() => !this.state.isProgram ? this.showDropdown({ classNameProgram: 'open', isProgram: !this.state.isProgram, isAbout: false, isJoin: false, isTeam: false, classNameJoin: '',classNameAbout: '',classNameTeam: '' }) : this.hideDropdown({ classNameProgram: '', isProgram: !this.state.isProgram })}
                >
                  Programs<b className="caret"></b>
                </a>
                <ul className="dropdown-menu white-gray-bgcolor width-prog-fit">
                  <li>
                    <a
                      href="#our_programs"
                      className="black-color subnav white-gray-bgcolor nav-height-down width-fit top_nav"
                      onClick={() => this.handleReload("/fellowship")}
                    >
                      Asaadharan Fellowship
                    </a>
                  </li>
                  <li>
                    <a
                      href="#our_programs"
                      className="black-color subnav white-gray-bgcolor nav-height-down width-fit top_nav"
                      onClick={() => this.handleReload("/adda")}
                    >
                      Buddy Project
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a
                  href="#gallery"
                  className="blue-color top_nav"
                  onClick={() => this.handleReload("/gallery-group")}
                >
                  Gallery
                </a>
              </li>
              <li className={"dropdown " + this.state.classNameJoin}
                onMouseEnter={() => this.showDropdown({ classNameJoin: 'open' })}
                onMouseLeave={() => this.hideDropdown({ classNameJoin: '' })}>
                <a
                  id="#join_us"
                  className="dropdown-toggle pink-bgcolor top_nav"
                  data-toggle="dropdown"
                  href="#join_us"
                  // onClick={() => !this.state.isJoin ? this.showDropdown({ classNameJoin: 'open', isJoin: !this.state.isJoin, isAbout: false, isProgram: false, isTeam: false, classNameAbout: '',classNameProgram: '',classNameTeam: '' }) : this.hideDropdown({ classNameJoin: '', isJoin: !this.state.isJoin })}
                >
                  Join Us<b className="caret"></b>
                </a>
                <ul className="dropdown-menu white-gray-bgcolor width-fit">
                  <li>
                    <a
                      href="#join_us"
                      className="black-color subnav white-gray-bgcolor nav-height-down width-fit"
                      onClick={() => this.handleReload("/career")}
                    >
                      Career
                    </a>
                  </li>
                  <li>
                    <a
                      href="#join_us"
                      className="black-color subnav white-gray-bgcolor nav-height-down width-fit"
                      onClick={() => this.handleReload("/fellow")}
                    >
                      Fellowship
                    </a>
                  </li>
                  <li>
                    <a
                      href="#join_us"
                      className="black-color subnav white-gray-bgcolor nav-height-down width-fit"
                      onClick={() => this.handleReload("/volunteer")}
                    >
                      Volunteer
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a
                  href="#reach_us"
                  className="red-color top_nav"
                  onClick={() => this.handleReload("/reachus")}
                >
                  Reach Us
                </a>
              </li>
            </ul>
          </div>
        </div>
        {/* <!-- /.navbar-collapse --> */}
      </nav>
      /* <!-- /navbar ends --> */
    );
  }
}

export default Header;
