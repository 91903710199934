
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  createTheme,
  MuiThemeProvider,
} from "@material-ui/core";
import { getGalleryGroup } from "../Sevices/service";
import Loader from "../Components/Loader";

const styles = (theme) => ({
  formControl: {
    padding: "10px 0 2px",
    margin: "8px 0",
    boxShadow: "6px 6px 0px rgb(103 102 102 / 20%)",
    borderRadius: "12px",
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(1),
  },
  chipLable: {
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen","Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue","sans-serif" !important',
    fontSize: "17px !important",
    padding: "0 15px",
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
});

const myTheme = createTheme({
  overrides: {
    MuiInput: {
      formControl: {
        "&&:hover": {
          "border-bottom": "0px solid rgba(0, 0, 0, 0.42) !important",
        },
      },
      underline: {
        "&:before": {
          "border-bottom": "0px solid rgba(0, 0, 0, 0.42) !important",
        },
        "&:after": {
          "border-bottom": "0px solid #3f51b5 !important",
        },
      },
    },
    MuiSelect: {
      select: {
        "&:focus": {
          borderRadius: "0",
          backgroundColor: "white",
        },
      },
    },
    "label+": {
      MuiInput: {
        formControl: {
          marginTop: "5px !important",
        },
      },
    },
    MuiChip: {
      root: {
        fontSize: "1rem",
      },
    },
  },
});

class GalleryGroup extends Component {
  _isMounted = false;

  state = {
    galleryGroup: [],
    loaderAction: true
  };

  componentDidMount() {

    window.scrollTo(0, 0);
    localStorage.clear();
    getGalleryGroup()
      .then(response => {
            this._isMounted = true;
        if (this._isMounted) {
          this.setState({ galleryGroup: response.data.gallery }, () => console.log());
          setTimeout(() => {
            this.setState({ loaderAction: false });
          }, 5000);
        }
      })
      .catch(error => {
        if (this._isMounted) {
        setTimeout(() => {
          this.setState({ loaderAction: false });
        }, 3000);
        // alert("Something went wrong.")
      }
      })
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleGalleryImage = (groupId, group_title, group_description) => {
    localStorage.setItem('groupId', groupId);
    localStorage.setItem('group_title', group_title);
    localStorage.setItem('group_description', group_description);
    this.props.history.push(process.env.PUBLIC_URL + `/gallery`);
  };

  render() {
    const displayImages = this.state.galleryGroup.map((groupRecord, index) => {
      if (groupRecord.status === '1' && groupRecord.image !== null && groupRecord.image.length !== 0 && groupRecord.image.match(/.(jpg|jpeg|png|gif)$/i)) {
        return (
          <div className="col-sm-cust-7 col-md-6 col-lg-4 events  mb-50 mb-sm-50" key={index}>
            <div className="portfolio-item">
              <div className="gallery-thumb">
                <img className="img-responsive" src={`https://asaadharan.org/beta/sitemanager/uploads/${groupRecord.image}`} alt="" />
                <span className="overlay-mask"></span>
                <a href={process.env.PUBLIC_URL + `/gallery` } onClick={() => this.handleGalleryImage(groupRecord.id, groupRecord.title, groupRecord.description)} data-gal="prettyPhoto[gallery]" className="link centered"
                  title="You can add caption to pictures.">
                  <i className="fa fa-expand"></i></a>
              </div>
            </div>
            <h3>{groupRecord.title}</h3>
          </div>
        )
      } else {
        return (<div></div>)
      }
    });

    return (
      <div className="full" id="gallery">
        {this.state.loaderAction ? <Loader /> : null}

        <MuiThemeProvider theme={myTheme}>
          <section id="gallery" className="color-section white-bgcolor">
            <svg
              id="triangleShadow"
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              width="100%"
              height="100"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
            >
              <path className="trianglePath1" d="M0 0 L50 100 L100 0 Z" />
            </svg>
            <div className="container pt-75">
              <div className="col-lg-12">
                {/* <!-- Section heading --> */}
                <div className="section-heading flex-center">
                  <img src="img/gallery.jpg" alt="" className="img-responsive" />
                </div>
              </div>
              <div className="text-center col-md-12">
                <ul className="nav nav-pills cat text-center" role="tablist" id="gallerytab">
                </ul>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div id="lightbox">
                    {displayImages}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </MuiThemeProvider>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(GalleryGroup);
