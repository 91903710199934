import React, { Component } from "react";

class Loader extends Component {
    render() {
        return (
            <div id = "preloader" >
            <div className="preloader">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
      </div >);
    }
}

export default Loader;