import React, { Component } from "react";
import Loader from "../Components/Loader";
import TeamPopup from "../Components/TeamPopup";

class Team extends Component {
  constructor(props) {
    super(props);
    this.teamPopupRef = React.createRef();
  }

  state = {
    name: "",
    post: "",
    content: "",
    color: "",
    bgcolor: "",
    loaderAction: true
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleClick = (name, post, content, bgcolor) => {
    this.teamPopupRef.current.handleClickOpen();
    this.setState({ name: name, post: post, content: content, bgcolor: bgcolor });
  };

  render() {
    return (
      <div className="full" id="core_team">
        {this.state.loaderAction ? <Loader /> : null}

        <section id="Founder">
          <div className="container pt-150">
            <div className="col-lg-12">
              {/* <!-- Section heading --> */}
              <div className="section-heading flex-center">
                {/* <h2>Team</h2> */}
                <img src="img/teamnew.jpg" alt="" className="img-responsive" />
              </div>
            </div>
            <TeamPopup
              ref={this.teamPopupRef}
              name={this.state.name}
              post={this.state.post}
              content={this.state.content}
              bgcolor={this.state.bgcolor}
            />

            <div className="row d-flex justify-content-center flex-wrap">
              <div
                className="col-md-6 col-lg-4 cursor-p"
                onClick={() =>
                  this.handleClick(
                    "Aastha A Kanodia",
                    "(Co-Founder)",
                    `<p> Aastha was born and raised in Surat. Aastha is a Lawyer by
                    profession, she completed her B.A.L.L.B (Cons.) from Nirma
                    University, Ahmedabad. After completing her Law degree, she
                    practised law for a few years by working as an Assistant Legal
                    Advisor at Surat Urban Development Authority (SUDA).  She also
                    counselled domestic violence cases at the Surat District Court
                    for 2 years.  Aastha has also served as a professional GST
                    consultant for a period of 2 years.  However, Aastha is most
                    passionate about educating children. Since, Aastha understands
                    the Indian context very well, she started teaching a group of
                    slum children at the bus stand for a few months, after which,
                    with the help of a team, she started with the Evening Learning
                    Centre in August 2016 and later Co-Founded Asaadharan
                    Foundation in 2019.  Aastha has a wealth of experience in
                    working with children and understanding the Indian education
                    system, as she herself studied in India throughout her life.</p>`,
                    "#dff0d8",
                  )
                }
              >
                {/* <!-- Image --> */}
                <img
                  src="img/a-reedit.jpg"
                  className="img-curved img-responsive"
                  alt=""
                />
                <h4 className="mt-5">Aastha A Kanodia</h4>
                <p>Co-Founder</p>
              </div>
              <div
                className="col-md-6 col-lg-4 mt-x-50 cursor-p"
                onClick={() =>
                  this.handleClick(
                    "Avani Chadha Fatehpuria",
                    "(Co-Founder)",
                    `<p> Avani comes from a diverse and multicultural background. She
                    is a Canadian national of Indian descent who was born in
                    Muscat, Oman. Avani has been privileged to study in many
                    international schools throughout her childhood and then
                    completed her undergraduate degree in Economics from the
                    University of Toronto, Canada and her Masters in Sustainable
                    Development from SOAS, University of London. Prior to moving
                    to Surat in 2017, Avani was working with the United Nation in
                    Bahrain for a period of 3 years (2013 – 2016), where she got
                    the opportunity to experience the nature of humanitarian and
                    developmental work. She continued this passion by Co-Founding,
                    Asaadharan Foundation, in 2019 in Surat, which focuses on the
                    improvement of foundational education of the underserved
                    children in Surat city. Both Aastha and Avani are dedicated
                    and committed towards changing the education system in a way
                    that is child-centred, fun, and easy to grasp irrespective of
                    your personal background!</p>`,
                    "#dff0d8",
                  )
                }
              >
                {/* <!-- Image --> */}
                <img
                  src="img/avni-edited.jpg"
                  className="img-curved img-responsive"
                  alt=""
                />
                <h4 className="mt-5">Avani Chadha Fatehpuria</h4>
                <p>Co-Founder</p>
              </div>
          
              <div
                className="col-md-6 col-lg-4 mt-50 cursor-p"
                onClick={() =>
                  this.handleClick(
                    "Disha Chaudhary",
                    "Program Manager for Fellowship - disha@asaadharan.org",
                    `<p>Disha is a Chartered Accountant by profession and has also
                  done her Master of Business Administration (MBA) in Finance
                  from the Institute of Management, Nirma University,
                  Ahmedabad.  She has prior teaching experience with Prodigy,
                  Surat and has also taught students privately with creative
                  pedagogy. </p> <p>Disha is extremely passionate about teaching and has
                  always preferred alternative methods of learning as compared
                  to traditional textbooks. She is an ever-smiling and
                  ever-enthusiastic person with an array of opinions on every
                  topic. She joined Asaadharan in 2021, with the determination
                  to work for the education of underserved children in the best
                  possible way with the available resources.</p>`,
                    "#f2dede",
                  )
                }
              >
                {/* <!-- Image --> */}
                <img
                  src="img/Disha_Chaudhary.jpg"
                  className="img-curved img-responsive"
                  alt=""
                />
                <h4 className="mt-5">Disha Chaudhary</h4>
                <p>Program Manager for Fellowship</p>
              </div>
              <div
                  className="col-md-6 col-lg-4 mt-50 cursor-p"
                  onClick={() =>
                    this.handleClick(
                      "Surbhi Goyal",
                      "Social Media Manager - surbhi@asaadharan.org",
                      `<p>Surbhi Goyal has done her Bachelors in Commerce from S.K.P Commerce College, Surat.</br>
                    She has also completed her Fashion Designing from INIFD, Surat.  In addition to this, Surabhi also 
                    has a diploma degree in Interior Designing from IDT, Surat.  In line with her academic qualifications, 
                    she has experience in designing & social media marketing through her family business.  Surabhi is 
                    experienced in teaching underprivileged children.  Surbhi is a happy person who is always eager to 
                    learn new things and share her experiences with everyone around her.  She joined Asaadharan in 2021, 
                    with the motive to help bring a change in the society and dedicate herself for the betterment of 
                    childrens’ education.</p>`,
                      "#fcf8e3",
                    )
                  }
                >
                  {/* <!-- Image --> */}
                  <img
                    src="img/team_img.jpg"
                    className="img-curved img-responsive"
                    alt=""
                  />
                  <h4 className="mt-5">Surbhi Goyal</h4>
                  <p>Social Media Manager</p>
                </div>
              <div
                className="col-md-6 col-lg-4 mt-50 cursor-p"
                onClick={() =>
                  this.handleClick(
                    "Roma Patel",
                    "Administration & Outreach - roma@asaadharan.org",
                    `<p>Roma has been a part of the Asaadharan Foundation for the past
                    4 years, even before the organization was officially
                    founded. She is a passionate teacher and manager of several
                    programs conducted by the Asaadharan Foundation. She is a
                    dedicated and committed core member of our team, who has truly
                    seen the organization and its work grow and has been a very
                    important part of that growth. She has completed her Bachelors
                    in Commerce (B.Com) in Advertising and Business Law from
                    Mithibai College, Mumbai University.<p/><p>Roma also has a wide
                    array of work experience, she also serves as a Chairperson for
                    Surat Metro Jaycee and Southern Gujarat Chamber of Commerce. 
                    In addition to this, she is a passionate cook and has worked
                    as an Assistant Chef in Taj Hotel and as a Jury Member for
                    Food Festivals and Cultural Programs in Surat. She always
                    ensures that the team is well fed with her lovely home-made and delicious food!</p>`,
                    "#d9edf7",
                  )
                }
              >
                {/* <!-- Image --> */}
                <img
                  src="img/pic2.jpg"
                  className="img-curved img-responsive"
                  alt=""
                />
                <h4 className="mt-5">Roma Patel</h4>
                <p>Administration & Outreach</p>
              </div>

              {/* <div className="col-lg-12 col-md-6 "> */}
                <div
                className="col-md-6 col-lg-4 mt-50 cursor-p"
                onClick={() =>
                  this.handleClick(
                    "Smriti Verma",
                    "Curriculum Developer - smriti@asaadharan.org",
                    `<p>Smriti is a self-motivated and dependable teacher with 14
                  years of experience in delivering educational assistance and
                  instruction to various levels of learners. Committed to
                  providing students with the necessary tools to achieve
                  academic goals, while instilling the love of learning and
                  cooperative teamwork among them. </P> <p> Smriti comes from a very
                  qualified educational background with a Bachelors in Education
                  (B.Ed) from Kutch University, a Graduation and Post-Graduation
                  Degree from the University of Delhi and holds a Post Graduate
                  Diploma in Pre -Primary teachers training from the University
                  of Symbiosis, Pune.</p><p> In addition to her qualifications, Smriti is currently serving
                  as a Teach for India Fellow in Ahmedabad. She has also worked
                  as a Pre-Primary Head with Welspun Vidya Mandir in Anjar for a
                  period of 5 years from 2014-2019 and with Euro Kids in
                  Gandhidham for 6 years from 2007-2013.  Smriti has supported
                  Asaadharan Foundation through her years of knowledge,
                  expertise and skills and developed the entire English
                  Curriculum for the Accelerated Learning Program.</p>`,
                    "#dff0d8",

                  )
                }
              >
                {/* <!-- Image --> */}
                <img
                  src="img/pic1.jpg"
                  className="img-curved img-responsive"
                  alt=""
                />
                <h4 className="mt-5">Smriti Verma</h4>
                <p>Curriculum Developer</p>
              </div>
                
              {/* </div> */}
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Team;
