import React from "react";
import { withStyles } from '@material-ui/core/styles';
import DonatePopup from './DonatePopup';

const styles = theme => ({
    typography: {
        margin: theme.spacing(2),
    }
});
function FloatDonate() {

    const childRef = React.useRef();
    const open = Boolean(childRef.anchorEl);
    return (
        <div className=" float-btn social-media">
            <DonatePopup ref={childRef} />
                <button
                    type="submit"
                    aria-describedby={'simple-popover'}
                    value="Submit"
                    className="btn float-btn circle-radius"
                    aria-owns={open ? 'render-props-popover' : undefined}
                    aria-haspopup="true"
                    variant="contained"
                    onClick={(event) => childRef.current.handleClick(event)}
                >
                    Donate Now
                </button>
        </div>
    )
}

export default withStyles(styles)(FloatDonate);
