import React, { Component } from "react";
import CustomSnackbar from "../Components/CustomSnackbar";
import Loader from "../Components/Loader";
import { setInquire } from "../Sevices/service";

class ReachUs extends Component {
  constructor() {
    super();
    this.myRef = React.createRef();

    this.state = {
      fields: {
        name: undefined,
        email: undefined,
        subject: undefined,
        message: undefined
      },
      open: false,
      alertMessage: "",
      errors: {},
      loaderAction: true
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    setTimeout(() => {
      this.setState({ loaderAction: false });
    }, 2500);
  }

  handleChange = (e) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields
    });
  }

  onSubmit = (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      let fields = {};
      fields["name"] = "";
      fields["email"] = "";
      fields["subject"] = "";
      fields["message"] = "";
      this.setState({ fields: fields });

      const formData = new FormData();
      formData.append('name', this.state.fields.name);
      formData.append('email', this.state.fields.email);
      formData.append('subject', this.state.fields.subject);
      formData.append('message', this.state.fields.message);

      setInquire(formData)
        .then(response => {
          this.setState({ open: true, alertMessage: "Message successfully sent" });
          this.triggerChildAlert();
        })
        .catch(error => {
          this.setState({ open: true, alertMessage: "Somthing Went wrong. Please try again" });
          this.triggerChildAlert();
        })
    }
  }

  validateForm = () => {

    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (typeof fields["name"] !== "undefined") {
      if (!fields["name"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["name"] = "*Please enter alphabet characters only.";
        this.setState({ open: true, alertMessage: "Please enter valid Name" });
      }
    }

    if (typeof fields["email"] !== "undefined") {
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(fields["email"])) {
        formIsValid = false;
        errors["email"] = "*Please enter valid email-ID.";
        this.setState({ open: true, alertMessage: "Please enter valid email-ID" });
      }

    }

    this.setState({
      errors: errors
    });
    return formIsValid;

  }

  triggerChildAlert() {
    this.myRef.current.handleClick(this.state.open, this.state.alertMessage);
  }

  render() {

    return (
      <div className="full" id="reach_us">
        {this.state.loaderAction ? <Loader /> : null}

        <CustomSnackbar ref={this.myRef} />
        {/* <!-- Section Contact --> */}
        <section id="contact" className="color-section white-bgcolor">
          <div className="container pt-150">
            <div className="col-lg-12">
              {/* <!-- Section heading --> */}
              <div className="section-heading flex-center">
                {/* <h2>Advisors</h2> */}
                <img src="img/reach_us.jpg" alt="" className="img-responsive" />
              </div>
            </div>
            {/* <!-- Contact --> */}
            <div className="col-lg-5 text-center">
              <h4>We can be reached at -</h4>

              <div className="row mt-10 mb-10">
                <div className="contact-info d-flex justify-content-around flex-wrap">
                  <p>
                    <i className="flaticon-back"></i>
                    <a href="mailto:support@asaadharan.org">
                      support@asaadharan.org
                    </a>
                  </p>
                  <p>
                    <i className="fa fa-phone margin-icon"></i> <a href="tel:+919825018314">Call us +91 9825018314</a>
                    <a href="tel:+919825766685">, +91 9825766685</a>
                  </p>
                </div>
              </div>
              <div className="row mt-10 mb-10">
                <span className="font-wb"><i className="fa fa-map-marker icon-gray"></i> Office : 310-311, Central Bazzar,
                  <p> Near Reliance Mart, Vesu, Surat - 395007</p>
                </span>

              </div>

              {/* <!-- Map --> */}
              <iframe title="Location" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3721.088445848452!2d72.77241341433064!3d21.148878189044012!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04d1e95429241%3A0xd82c068d55da64be!2sAsaadharan%20Foundation!5e0!3m2!1sen!2sin!4v1639133204157!5m2!1sen!2sin" style={{ border: 0 }} allowFullScreen="" loading="lazy" className="mt-10 mb-20 col-md-12 map-size"></iframe>
            </div>
            {/* <!-- Contact Form --> */}
            <div className="col-lg-6 col-lg-offset-1">
              <h4>Write us</h4>
              {/* <!-- Form Starts --> */}
              <div id="contact_form">
                <form method="post" name="userInquireForm" onSubmit={this.onSubmit}>
                  <div className="form-group">
                    <input
                      type="text"
                      name="name"
                      className="form-control input-field"
                      placeholder="Name"
                      value={this.state.fields.name}
                      onChange={this.handleChange}
                      required="Enter Your Name"
                    />
                    <div className="errorMsg">{this.state.errors.name}</div>
                    <input
                      type="email"
                      name="email"
                      className="form-control input-field"
                      placeholder="Email ID"
                      value={this.state.fields.email}
                      onChange={this.handleChange}
                      required="Enter Your Email"
                    />
                    <div className="errorMsg">{this.state.errors.email}</div>

                    <input
                      type="text"
                      name="subject"
                      className="form-control input-field"
                      placeholder="Subject"
                      value={this.state.fields.subject}
                      onChange={this.handleChange}
                      required="Enter Subject"
                    />
                  </div>
                  <textarea
                    name="message"
                    id="message"
                    className="textarea-field form-control"
                    rows="4"
                    placeholder="Enter your message"
                    value={this.state.fields.message}
                    onChange={this.handleChange}
                    required="Enter Message"
                  ></textarea>
                  <button
                    type="submit"
                    id="submit_btn_reach"
                    value="Submit"
                    className="btn center-block"
                  >
                    Send message
                  </button>
                </form>
              </div>
              {/* <!-- Contact results --> */}
              <div id="contact_results"></div>
            </div>
            {/* <!--/Contact form --> */}
          </div>
          {/* <!-- /container--> */}
        </section >
        {/* <!--Section ends --> */}
      </div >
    );
  }
}
export default (ReachUs);