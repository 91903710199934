import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import DonatePopup from './DonatePopup';

const styles = theme => ({
  typography: {
    margin: theme.spacing(2),
  }
});

function Donate() {
  // const [anchorEl, setAnchorEl] = React.useState(null);

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const id = open ? 'simple-popover' : undefined;
  const childRef = React.useRef();
  const open = Boolean(childRef.anchorEl);

  return (
    <div>
      <DonatePopup ref={childRef} />
      <button
        type="submit"
        aria-describedby={'simple-popover'}
        value="Submit"
        className="btn center-block donate-btn"
        aria-owns={open ? 'render-props-popover' : undefined}
        aria-haspopup="true"
        variant="contained"
        onClick={(event) => childRef.current.handleClick(event)}
      >
        Donate Now
      </button>
    </div>
  );
}

export default withStyles(styles)(Donate);
