import React, { Component } from "react";
import ImgSlider from "../Components/ImgSlider";
import Loader from "../Components/Loader";
// import OwlSilder from "../Components/OwlSlider";

class FellowshipProgram extends Component {
  state = {
    loaderAction: true
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    setTimeout(() => {
      this.setState({ loaderAction: false });
    }, 2000)
  }

  render() {
    return (
      <div className="full" id="our_programs">
        {this.state.loaderAction ? <Loader /> : null}

        {/* <!-- Section Programs --> */}
        <section className="color-section white-bgcolor">
          <div className="container pt-150">
            <div className="col-lg-12">
              {/* <!-- Section heading --> */}
              <div className="section-heading flex-center">
                <img src="img/fellowship.jpg" alt="" className="img-responsive" />
              </div>
            </div>
            <div id="blog-page" className="single-page">
              <div className="col-md-12 blog-home">
                <div className="row ">
                  <div className="col-sm-12 col-md-12 col-lg-6 mb-40">
                    <img className=" img-responsive img-curved" src="img/3_year.jpg" alt="" />
                  </div>
                  <div className=" post-main col-sm-12 col-md-12 col-lg-6 mb-40">
                    <p>Asaadharan Foundation has developed a two-fold approach to address the pressing issue of lack of holistic foundational skills and the necessary awareness of holistic foundational education in Gujarat.  We will be beginning our journey with six municipal schools in Surat, through our combined partnerships with the Sarva Shiksha Abhiyan and the Nagar Prathmik Shikshan Samithi.  Our government partnerships aim at a larger vision of aligning our fellowship program with the National Education Policy (NEP) at the national level and the Sustainable Development Goals (SDGs) at the international level.</p>
                  </div>
                </div>
                <div className="row d-flex justify-content-center flex-wrap">
                  <div className="col-md-12 col-lg-4 mb-40">
                    <img className="media-object img-responsive img-circle m-auto" src="img/program_1.jpg" alt="" />
                  </div>
                  <div className="col-md-12 col-lg-8 mb-40">
                    <img className="media-object img-responsive img-curved" src="img/fellow_gain.jpg" alt="" />
                    {/* <blockquote>In the short-term, our fellows will work as teachers in these municipal schools for a period of two years to provide holistic foundational education.  By working with municipal schools, our fellows will gain the necessary understanding of the education system by developing necessary partnerships with primary stakeholders and they will work closely with grassroot issues.  This will be the start of their leadership journey, as they will be motivated to identify gaps in the existing system that deeply resonate with them and will work towards developing the relevant knowledge, skills and mindsets to eventually become part of the larger movement.</blockquote> */}
                  </div>

                </div>
                <div className="row d-flex justify-content-center flex-wrap">
                  <div className="col-md-12 col-lg-8">
                    <img className="media-object img-responsive img-curved" src="img/our_solution.jpg" alt="" />
                    {/* <blockquote>In the long-term, our fellowship will begin a movement of advocates and leaders who will be encouraged to work directly or indirectly in diverse areas of education at different levels throughout the system.  Asaadharan fellowship alumni will continue to build the necessary network and partnerships where everyone is working to constantly advocate for holistic foundation education starting with Surat city and gradually encompassing Gujarat and one day the nation, while simultaneously achieving the collective vision of the NEP and SDGs.</blockquote> */}
                  </div>
                  <div className="col-md-12 col-lg-4 mb-40">
                    <img className="media-object img-responsive img-circle m-auto" src="img/program_2.jpg" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <ImgSlider id={"owl-testimonials"} />
          </div>
          {/* <!-- /container--> */}
        </section>
        {/* <!-- /Section ends --> */}

      </div>
    );
  }
}

export default FellowshipProgram;
