import React, { Component } from "react";
import Loader from "../Components/Loader";

class Fellow extends Component {
    state = {
        loaderAction: true
    };
    componentDidMount() {
        window.scrollTo(0, 0);
        setTimeout(() => {
            this.setState({ loaderAction: false });
          }, 2500);
    }
    handleApply = (route) => {
        if (window.location.pathname === "/beta/" && route === "/") {
        } else {
            this.props.history.push(process.env.PUBLIC_URL + route);
            window.location.reload(true);
        }
    }
    render() {
        return (
            <div className="full" id="join_us">
                {this.state.loaderAction ? <Loader /> : null}

                {/* <!-- Section Programs --> */}
                <section className="color-section white-bgcolor">
                    <div className="container pt-150">
                        <div className="col-lg-12">
                            {/* <!-- Section heading --> */}
                            <div className="section-heading flex-center">
                                {/* <h2>Advisors</h2> */}
                                <img src="img/volunteer.jpg" alt="" className="img-responsive" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12 mb-20">
                                <h3 className="blue-color">“Volunteering is an act of heroism on a grand scale. And it matters profoundly. It does more than help people beat the odds; it changes the odds.” – William J. Clinton</h3>
                                <p>​​Join us to collectively change the odds in children’s lives  by providing them with holistic foundational education. Do know that every bit of your contribution can make a massive impact in the lives of every child, who maybe denied this basic but crucial right for multiple reasons. As Shahrukh Khan rightly said “ Agar kisi cheez ko dil se chaho toh puri kayanat usey tumse milane ki koshish mei lag jaati hai.'' So yes, we do believe that with commitment, we can achieve this vision.  For this, all you have to do is work with us for a period of 3-12 months as you deem fit. You can choose to work with our various fields such as - marketing, reading campaign, fundraising, Fellowship Programme. (links to these) For details, kindly read our volunteer’s handout. (link)</p>
                            </div>
                            <a href="https://docs.google.com/file/d/1GFQBDdmUm7RmE3l47hZlSFeI8RE0bwoI/edit?usp=docslist_api&filetype=msword" target="_blank" rel="noreferrer">
                             <button  className="btn" style={{float:'left', clear: 'both',marginBottom:'10px'}}>
                                Checkout the Volunteer Handout
                             </button>
                            </a>

                            <div className="col-lg-12 position-relative">
                            <img src="img/fellow-cover.jpg" alt="career" className="img-responsive zig-zag-border" />
                                <button
                                //  onClick={(e) => {
                                //     e.preventDefault();
                                //     window.location.href = 'https://forms.gle/CdS9iH3EP2r6kQLAA';
                                // }}
                                    onClick={() => this.handleApply("/volunteer-questions")}
                                    className="btn center-block mt-40 float-right career-btn"
                                >
                                    Apply Now
                                </button>
                            </div>

                        </div>
                    </div>
                    {/* <!-- /container--> */}
                </section>
                {/* <!-- /Section ends --> */}
            </div>
        );
    }
}

export default Fellow;
