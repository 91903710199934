import React, { Component } from 'react';

class OwlSilder extends Component {

    render() { 
        return ( <div className="col-sm-12 col-lg-5">
        <div id={this.props.id} className="owl-carousel">
            <div className="item">
                <img className="img-responsive" src="img/q11.jpg" alt="" />
            </div>
            <div className="item">
                <img className="img-responsive" src="img/q12.jpg" alt="" />
            </div>
            <div className="item">
                <img className="img-responsive" src="img/q13.jpg" alt="" />
            </div>
            <div className="item">
                <img className="img-responsive" src="img/q14.jpg" alt="" />
            </div>
        </div>
    </div>  );
    }
}
 
export default OwlSilder;

