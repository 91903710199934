import React, { Component } from "react";
import Loader from "../Components/Loader";

class TeamAndMembers extends Component {
  state = {
    loaderAction: true
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    setTimeout(() => {
      this.setState({ loaderAction: false });
    }, 2500);
  }
  render() {
    return (
      <div className="full" id="core_team">
        {/* <!-- Preloader --> */}
        {this.state.loaderAction ? <Loader /> : null}

        {/* <!-- Section Founder --> */}
      </div>
    );
  }
}

export default TeamAndMembers;
