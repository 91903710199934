import React, { Component } from "react";
import Loader from "../Components/Loader";

class LearningAddaProgram extends Component {
  state = {
    loaderAction: true
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    setTimeout(() => {
      this.setState({ loaderAction: false });
    }, 2500);
  }
  render() {
    return (
      <div className="full" id="our_programs">
        {this.state.loaderAction ? <Loader /> : null}

        {/* <!-- Section Programs --> */}
        <section className="color-section white-bgcolor">
          <div className="container pt-150">
            <div className="col-lg-12">
              {/* <!-- Section heading --> */}
              <div className="section-heading flex-center">
                <img src="img/learning.jpg" alt="" className="img-responsive" />
              </div>
            </div>
            <h3>Coming Soon !!</h3>
          </div>
          {/* <!-- /container--> */}
        </section>
        {/* <!-- /Section ends --> */}
      </div>
    );
  }
}

export default LearningAddaProgram;
