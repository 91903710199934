import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import {
  createTheme,
  MuiThemeProvider,
} from "@material-ui/core";

const myTheme = createTheme({
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        borderRadius: "225px 45px 325px 75px/15px 125px 35px 155px !important",
        boxShadow: "6px 6px 0px rgb(103 102 102 / 20%) !important",
        padding: "0 0 !important",
      },
      paper: {
        'overflow-y': 'auto'
      },
      paperScrollPaper: {
        'max-height': 'calc(100% - 5px)'
      }
    },
  },
});

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2, 3),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  pFont: {
    fontFamily: "'Nunito',Helvetica,Arial,Lucida,sans-serif !important",
    fontSize: 14,
    padding: "10px 20px !important",
  },
  popupStyle: {
    borderRadius: "225px 45px 325px 75px/15px 125px 35px 155px !important",
    boxShadow: "6px 6px 0px rgb(103 102 102 / 20%) !important",
    padding: "10px 20px !important",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h5">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);


class TeamPopup extends Component {
  state = {
    open: false,
    bgcolor: this.props.bgcolor,
    color: this.props.color,
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        <MuiThemeProvider theme={myTheme}>
          <Dialog
            onClose={this.handleClose}
            aria-labelledby="customized-dialog-title"
            open={this.state.open}
          >
            <div
              className="popupStyle"
              style={{
                background: this.props.bgcolor || "#a94442",
                // color: this.props.color || "#f2dede",
              }}
            >
              <DialogTitle
                id="customized-dialog-title"
                onClose={this.handleClose}
              >
                <h4>{this.props.name}</h4>
                {this.props.post}
              </DialogTitle>
              <DialogContent dividers>
                <Typography
                  gutterBottom
                  className={classes.pFont}
                  dangerouslySetInnerHTML={{ __html: this.props.content }}
                ></Typography>
              </DialogContent>
            </div>
          </Dialog>
        </MuiThemeProvider>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(TeamPopup);
