import React, { Component } from "react";
import CustomSnackbar from "../Components/CustomSnackbar";
import Loader from "../Components/Loader";
import { setCareerForm } from "../Sevices/service";

class CareerQuestions extends Component {
    constructor() {
        super();
        this.myRef = React.createRef();
        this.state = {
            fields: {
                full_name: undefined,
                address: undefined,
                city: undefined,
                mobile_number: undefined,
                email: undefined,
                age: undefined,
                qualification: undefined,
                occupation: "Graduate Student",
                occupationOther: "",
                apply: "Program Lead",
                marrital_status: "Married",
                careerAtAsaadharan: undefined,
                youWorkedUnder: undefined,
                workedWithYou: undefined
                // gender: "Male",
                // fluency_english: "0",
                // previous_wrk_exp: undefined,
                // teaching_exp: "Yes",
                // leadership_skills: "0",
                // teaching_skills: "0",
                // interpersonal_skills: "0",
                // work_commitment: "Yes",
                // duration_week: undefined,
                // weekends_avaliablity: undefined,
                // school_visits: undefined,
                // start_working_from: undefined,
                // wrk_with_asadharan: undefined,
                // strengths: undefined,
                // weakness: undefined,
                // wrk_with_philanthropic: undefined,
                // salary_expectations: undefined,
                // goals: undefined,
                // file: undefined,
                // core_value: undefined,
                // prev_org_details: undefined,
                // training_trachers: undefined
            },
            open: false,
            alertMessage: "",
            errors: {},
            loaderAction: true
        }
    }

    handleChange = (e) => {
        console.log('radio', e.target.value);
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields
        });
        if (e.target.name === "occupationOther") {
            fields["occupation"] = e.target.value;
            this.setState({ fields });
        }
    }

    handleFileUpload = event => {
        let fields = this.state.fields;
        fields['file'] = event.target.files[0];
        this.setState({ fields });
    };

    submituserRegistrationForm = (e) => {
        e.preventDefault();
        if (this.validateForm()) {
            let fields = {};
            fields["full_name"] = "";
            fields["address"] = "";
            fields["city"] = "";
            fields["mobile_number"] = "";
            fields["email"] = "";
            fields["age"] = "";
            fields["qualification"] = "";
            fields["occupation"] = "";
            fields["occupationOther"] = "";
            fields["apply"] = "";
            fields["marrital_status"] = "Marride";
            fields["careerAtAsaadharan"] = "";
            fields["youWorkedUnder"] = "";
            fields["workedWithYou"] = "";
            // fields["gender"] = "Male";
            // fields["fluency_english"] = "0";
            // fields["previous_wrk_exp"] = "";
            // fields["teaching_exp"] = "Yes";
            // fields["leadership_skills"] = "0";
            // fields["teaching_skills"] = "0";
            // fields["interpersonal_skills"] = "0";
            // fields["work_commitment"] = "Yes";
            // fields["duration_week"] = "";
            // fields["weekends_avaliablity"] = "";
            // fields["school_visits"] = "";
            // fields["start_working_from"] = "";
            // fields["wrk_with_asadharan"] = "";
            // fields["strengths"] = "";
            // fields["weakness"] = "";
            // fields["wrk_with_philanthropic"] = "";
            // fields["salary_expectations"] = "";
            // fields["goals"] = "";
            // fields["core_value"] = "";
            // fields["prev_org_details"] = "";
            // fields["training_trachers"] = "";
            // fields["file"] = "";

            this.setState({ fields: fields });

            const formData = new FormData();
            formData.append('full_name', this.state.fields.full_name);
            formData.append('address', this.state.fields.address);
            formData.append('city', this.state.fields.city);
            formData.append('mobile_number', this.state.fields.mobile_number);
            formData.append('email', this.state.fields.email);
            formData.append('age', this.state.fields.age);
            formData.append('qualification', this.state.fields.qualification);
            formData.append('occupation', this.state.fields.occupation);
            formData.append('apply', this.state.fields.apply);
            formData.append('marrital_status', this.state.fields.marrital_status);
            formData.append('careerAtAsaadharan', this.state.fields.careerAtAsaadharan);
            formData.append('youWorkedUnder', this.state.fields.youWorkedUnder);
            formData.append('workedWithYou', this.state.fields.workedWithYou);
            // formData.append('gender', this.state.fields.gender);
            // formData.append('fluency_english', this.state.fields.fluency_english);
            // formData.append('previous_wrk_exp', this.state.fields.previous_wrk_exp);
            // formData.append('teaching_exp', this.state.fields.teaching_exp);
            // formData.append('leadership_skills', this.state.fields.leadership_skills);
            // formData.append('teaching_skills', this.state.fields.teaching_skills);
            // formData.append('interpersonal_skills', this.state.fields.interpersonal_skills);
            // formData.append('work_commitment', this.state.fields.work_commitment);
            // formData.append('duration_week', this.state.fields.duration_week);
            // formData.append('weekends_avaliablity', this.state.fields.weekends_avaliablity);
            // formData.append('school_visits', this.state.fields.school_visits);
            // formData.append('start_working_from', this.state.fields.start_working_from);
            // formData.append('wrk_with_asadharan', this.state.fields.wrk_with_asadharan);
            // formData.append('strengths', this.state.fields.strengths);
            // formData.append('weakness', this.state.fields.weakness);
            // formData.append('wrk_with_philanthropic', this.state.fields.wrk_with_philanthropic);
            // formData.append('salary_expectations', this.state.fields.salary_expectations);
            // formData.append('goals', this.state.fields.goals);
            // formData.append('core_value', this.state.fields.core_value);
            // formData.append('prev_org_details', this.state.fields.prev_org_details);
            // formData.append('training_trachers', this.state.fields.training_trachers);
            // formData.append('file', this.state.fields.file);

            setCareerForm(formData)
                .then(response => {
                    // console.log('response', response);

                    this.setState({ open: true, alertMessage: "Message successfully sent" });
                    this.triggerChildAlert();
                })
                .catch(error => {
                    // console.log('error', error);

                    this.setState({ open: true, alertMessage: "Somthing Went wrong. Please try again" });
                    this.triggerChildAlert();
                })
        }

    }
    validateForm = () => {

        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        if (typeof fields["name"] !== "undefined") {
            if (!fields["name"].match(/^[a-zA-Z ]*$/)) {
                formIsValid = false;
                errors["name"] = "*Please enter valid Name.";
            }
        }

        if (typeof fields["email"] !== "undefined") {
            //regular expression for email validation
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(fields["email"])) {
                formIsValid = false;
                errors["email"] = "*Please enter valid email-ID.";
            }
        }

        if (typeof fields["mobile_number"] !== "undefined") {
            if (!fields["mobile_number"].match(/^[0-9]{10}$/)) {
                formIsValid = false;
                errors["mobile_number"] = "*Please enter valid mobile no.";
            }
        }


        if (typeof fields["age"] !== "undefined") {
            if (!fields["age"].match(/^(0?[1-9]|[1-9][0-9]|[1][1-9][1-9]|200)$/)) {
                formIsValid = false;
                errors["age"] = "*Please enter valid Age.";
            }
        }

        this.setState({
            errors: errors
        });
        return formIsValid;
    }

    triggerChildAlert() {
        this.myRef.current.handleClick(this.state.open, this.state.alertMessage);
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        setTimeout(() => {
            this.setState({ loaderAction: false });
        }, 2000)
    }

    render() {
        return (
            <div className="full" id="join_us">
                {this.state.loaderAction ? <Loader /> : null}

                {/* <!-- Section Programs --> */}
                <CustomSnackbar ref={this.myRef} />
                <section className="color-section white-bgcolor">

                    <div className="container pt-150">
                        <div className="col-lg-12">
                            <div className="section-heading flex-center">
                                <img src="img/careerr.jpg" alt="" className="img-responsive" />
                            </div>
                            <img src="img/boy.png" alt="" className="img-responsive mt-50 boy-icon" />
                            <img src="img/girl.png" alt="" className="img-responsive mt-50 girl-icon" />
                        </div>
                        <div className="col-lg-12 form-border">
                            <div className="row pad-vh">
                                <form method="post" name="userRegistrationForm" onSubmit={this.submituserRegistrationForm}>
                                    <div className="row">
                                        <div className="form-group col-lg-6">
                                            <label htmlFor="name">1. Full Name</label>
                                            <input
                                                type="text"
                                                name="full_name"
                                                className="form-control input-field"
                                                placeholder="Enter Full Name"
                                                value={this.state.fields.full_name}
                                                onChange={this.handleChange}
                                                required
                                            />
                                            <div className="errorMsg">{this.state.errors.full_name}</div>
                                        </div>
                                        <div className="form-group col-lg-6">
                                            <label htmlFor="address">2. Address</label>

                                            <input
                                                type="text"
                                                name="address"
                                                className="form-control input-field"
                                                placeholder="Enter Address"
                                                value={this.state.fields.address}
                                                onChange={this.handleChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-lg-6">
                                            <label htmlFor="city">3. Current City</label>

                                            <input
                                                type="text"
                                                name="city"
                                                className="form-control input-field"
                                                placeholder="Enter Current City"
                                                value={this.state.fields.city}
                                                onChange={this.handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="form-group col-lg-6">
                                            <label htmlFor="number">4. Mobile Number</label>

                                            <input
                                                type="number"
                                                name="mobile_number"
                                                className="form-control input-field"
                                                placeholder="Enter Mobile Number"
                                                value={this.state.fields.mobile_number}
                                                onChange={this.handleChange}
                                                required
                                            />
                                            <div className="errorMsg">{this.state.errors.mobile_number}</div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-lg-6">
                                            <label>5. Email Address</label>

                                            <input
                                                type="email"
                                                name="email"
                                                className="form-control input-field"
                                                placeholder="Enter Email"
                                                value={this.state.fields.email}
                                                onChange={this.handleChange}
                                                required
                                            />
                                            <div className="errorMsg">{this.state.errors.email}</div>
                                        </div>
                                        <div className="form-group col-lg-6">
                                            <label>6. Age</label>

                                            <input
                                                type="number"
                                                name="age"
                                                className="form-control input-field"
                                                placeholder="Enter Age"
                                                value={this.state.fields.age}
                                                onChange={this.handleChange}
                                                required
                                            />
                                            <div className="errorMsg">{this.state.errors.age}</div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-lg-6">
                                            <label>7. Education Qualification</label>

                                            <input
                                                type="text"
                                                name="qualification"
                                                className="form-control input-field"
                                                placeholder="Enter Education Qualification"
                                                value={this.state.fields.qualification}
                                                onChange={this.handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="form-group col-lg-6">
                                            <label htmlFor="message">8. Marital status (If unmarried, are you getting married soon? Will you be willing to work post-marriage?)</label>
                                            <ul className="radio-btn">
                                                <li>
                                                    <label className="container-cust text-left">
                                                        Married
                                                        <input type="radio" checked={this.state.fields.marrital_status === "Married"} name="marrital_status" value="Married" onChange={this.handleChange}
                                                        />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="container-cust text-left">
                                                        Not Married
                                                        <input type="radio" name="marrital_status" checked={this.state.fields.marrital_status === "notMarried"} value="notMarried" onChange={this.handleChange} />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="container-cust text-left">
                                                        Other
                                                        <input type="radio" name="marrital_status" checked={this.state.fields.marrital_status === "Engaged"} value="Engaged" onChange={this.handleChange}
                                                        />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="container-cust text-left">
                                                        Other
                                                        <input type="radio" name="marrital_status" checked={this.state.fields.marrital_status === "Divorced"} value="Divorced" onChange={this.handleChange}
                                                        />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>

                                    </div>

                                    <div className="row">
                                        <div className="form-group col-lg-6">
                                            <label>9. Applying For *</label>
                                            <ul className="radio-btn">
                                                <li className="col-lg-6">
                                                    <label className="container-cust text-left">
                                                        Program Lead
                                                        <input type="radio" checked={this.state.fields.apply === "Program Lead"} name="apply" value="Program Lead" onChange={this.handleChange} />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </li>
                                                <li className="col-lg-6">
                                                    <label className="container-cust text-left">
                                                        Program Manager
                                                        <input type="radio" checked={this.state.fields.apply === "Program Manager"} name="apply" value="Program Manager" onChange={this.handleChange} />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </li>
                                            </ul>
                                            <h3 className="text-left mt-20">
                                                Detailed Information - Program Manager
                                            </h3>
                                            {/* <p > */}
                                            <ul className="text-left">
                                                <li>Coaching, Training, Mentoring and Connecting Fellows</li>
                                                <li>Pedagogy and Leadership: Training, Learning and Research.</li>
                                                <li>Create Recruitment and Alumni Support.</li>
                                                <li>Helping in Social Media and communication:
                                                    <ul>
                                                        <li>by sharing growth stories with pictures.</li>
                                                        <li>sharing pictures & videos of creative activities undertaken by the fellows., etc.</li>
                                                    </ul>
                                                </li>
                                                <li>Build on Partnerships with different stakeholders.</li>
                                                <li>Assessment and feedback generation calendar.</li>
                                                <li>Build & Maintain the Culture of the Organisation.</li>
                                                <li>Human resources of fellows (onboarding, relationships, leaves management).</li>
                                            </ul>
                                        </div>

                                        <div className="form-group col-lg-6">
                                            <label>10. Current Occupation *</label>
                                            <ul className="radio-btn">
                                                <li className="col-lg-6">
                                                    <label className="container-cust text-left">
                                                        Graduate Student
                                                        <input type="radio" checked={this.state.fields.occupation === "Graduate Student"} name="occupation" value="Graduate Student" onChange={this.handleChange} />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </li>
                                                <li className="col-lg-6">
                                                    <label className="container-cust text-left">
                                                        Post Graduate Student
                                                        <input type="radio" checked={this.state.fields.occupation === "Post Graduate Student"} name="occupation" value="Post Graduate Student" onChange={this.handleChange} />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </li>
                                                <li className="col-lg-6">
                                                    <label className="container-cust text-left">
                                                        Part-time Working Professional
                                                        <input type="radio" checked={this.state.fields.occupation === "Part-time Working Professional"} name="occupation" value="Part-time Working Professional" onChange={this.handleChange} />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </li>
                                                <li className="col-lg-6">
                                                    <label className="container-cust text-left">
                                                        Full-time Working Professional
                                                        <input type="radio" checked={this.state.fields.occupation === "Full-time Working Professional"} name="occupation" value="Full-time Working Professional" onChange={this.handleChange} />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </li>
                                                <li className="col-lg-6">
                                                    <label className="container-cust text-left">
                                                        <textarea
                                                            type="text"
                                                            name="occupationOther"
                                                            className="form-control input-field"
                                                            placeholder="Other"
                                                            value={this.state.fields.occupationOther || ""}
                                                            onChange={this.handleChange}
                                                            rows={1}
                                                        />

                                                        <input type="radio" checked={this.state.fields.occupation === this.state.fields.occupationOther} name="timing" value={this.state.fields.occupationOther} onChange={this.handleChange}
                                                        />
                                                        <span className="checkmark"></span>

                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="form-group col-lg-6">
                                            <label>11. Kindly tell us in detail, why do you want to take up this career at Asaadharan Foundation? (Go through your role and responsibilities) *</label>
                                            <input
                                                type="text"
                                                name="career-asaadharan"
                                                className="form-control input-field"
                                                placeholder="Enter Answer"
                                                value={this.state.fields.careerAtAsaadharan}
                                                onChange={this.handleChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-lg-6">
                                            <label>12. References: Kindly provide names & contacts of 2 people under whom you have worked with. *</label>
                                            <input
                                                type="text"
                                                name="worked-under"
                                                className="form-control input-field"
                                                placeholder="Enter Answer"
                                                value={this.state.fields.youWorkedUnder}
                                                onChange={this.handleChange}
                                                required
                                            />
                                        </div>

                                        <div className="form-group col-lg-6">
                                            <label>13. References: Kindly provide names & contacts of 2 people who have either worked with you in your team or under your leadership. *</label>
                                            <input
                                                type="text"
                                                name="co-worker"
                                                className="form-control input-field"
                                                placeholder="Enter Answer"
                                                value={this.state.fields.workedWithYou}
                                                onChange={this.handleChange}
                                                required
                                            />
                                        </div>
                                    </div>

                                    {/* <div className="form-group col-lg-6">
                                            <label>8. Occupation</label>

                                            <input
                                                type="text"
                                                name="occupation"
                                                className="form-control input-field"
                                                placeholder="Enter Occupation"
                                                value={this.state.fields.occupation}
                                                onChange={this.handleChange}
                                                required
                                            />
                                        </div> */}
                                    {/* <div className="form-group col-lg-6">
                                            <label>8. Gender (Male / Female / Others / Prefer not to tell)</label>
                                            <ul className="radio-btn">
                                                <li>
                                                    <label className="container-cust text-left">
                                                        Male
                                                        <input type="radio" checked={this.state.fields.gender === "Male"} name="gender" value="Male" onChange={this.handleChange}
                                                        />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="container-cust text-left">
                                                        Female
                                                        <input type="radio" name="gender" checked={this.state.fields.gender === "Female"} value="Female" onChange={this.handleChange} />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="container-cust text-left">
                                                        Other
                                                        <input type="radio" name="gender" checked={this.state.fields.gender === "Other"} value="Other" onChange={this.handleChange}
                                                        />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="container-cust text-left">
                                                        Prefer not to tell
                                                        <input type="radio" name="gender" checked={this.state.fields.gender === "Prefer not to tell"} value="Prefer not to tell" onChange={this.handleChange}
                                                        />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div> */}
                                    {/* <div className="form-group col-lg-6">
                                            <label>10. How will you rate your fluency in English?</label>
                                            <div className="d-flex">
                                                <label className="pink-color slider-no">{this.state.fields.fluency_english}</label><input
                                                    type="range"
                                                    name="fluency_english"
                                                    min="0" max="5"
                                                    value={this.state.fields.fluency_english}
                                                    onChange={this.handleChange}
                                                    step="1"
                                                    className="slider"
                                                    required
                                                />
                                            </div>
                                        </div> */}

                                    {/* <div className="row">

                                    
                                        <div className="form-group col-lg-6">
                                            <label>12. References: Kindly provide names & contacts of 2 people under whom you have worked with.</label>
                                            <ul className="radio-btn">
                                                <li>
                                                    <label className="container-cust text-left">
                                                        Yes
                                                        <input type="radio" checked={this.state.fields.work_commitment === "Yes"} name="work_commitment" value="Yes" onChange={this.handleChange}
                                                        />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="container-cust text-left">
                                                        No
                                                        <input type="radio" name="work_commitment" checked={this.state.fields.work_commitment === "No"} value="No" onChange={this.handleChange} />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>


                                        <div className="form-group col-lg-6">
                                            <label>14. Do you have prior teaching experience?</label>
                                            <ul className="radio-btn">
                                                <li>
                                                    <label className="container-cust text-left">
                                                        Yes
                                                        <input type="radio" checked={this.state.fields.teaching_exp === "Yes"} name="teaching_exp" value="Yes" onChange={this.handleChange}
                                                        />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="container-cust text-left">
                                                        No
                                                        <input type="radio" name="teaching_exp" checked={this.state.fields.teaching_exp === "No"} value="No" onChange={this.handleChange} />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-lg-6">
                                            <label>15. If yes, kindly share name of the organization along with roles & responsibilities.</label>
                                            <input
                                                type="text"
                                                name="prev_org_details"
                                                className="form-control input-field"
                                                placeholder="Enter Answer"
                                                value={this.state.fields.prev_org_details}
                                                onChange={this.handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="form-group col-lg-6">
                                            <label>16. Will you be comfortable in doing Field visits to SMC Schools, other Government bodies, etc. for taking training sessions and/or for Observation?</label>
                                            <input
                                                type="text"
                                                name="school_visits"
                                                className="form-control input-field"
                                                placeholder="Enter Answer"
                                                value={this.state.fields.school_visits}
                                                onChange={this.handleChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-lg-6">
                                            <label>17. How will you rate your Leadership skills?</label>
                                            <div className="d-flex">
                                                <label className="pink-color slider-no">{this.state.fields.leadership_skills}</label>
                                                <input
                                                    type="range"
                                                    name="leadership_skills"
                                                    min="0" max="5"
                                                    value={this.state.fields.leadership_skills}
                                                    onChange={this.handleChange}
                                                    step="1"
                                                    className="slider"
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="form-group col-lg-6">
                                            <label>18. How will you rate your Teaching skills?</label>
                                            <div className="d-flex">
                                                <label className="pink-color slider-no">{this.state.fields.teaching_skills}</label>
                                                <input
                                                    type="range"
                                                    name="teaching_skills"
                                                    min="0" max="5"
                                                    value={this.state.fields.teaching_skills}
                                                    onChange={this.handleChange}
                                                    step="1"
                                                    className="slider"
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-lg-6">
                                            <label>19. How will you rate your Interpersonal skills?</label>
                                            <div className="d-flex">
                                                <label className="pink-color slider-no">{this.state.fields.interpersonal_skills}</label>
                                                <input
                                                    type="range"
                                                    name="interpersonal_skills"
                                                    min="0" max="5"
                                                    value={this.state.fields.interpersonal_skills}
                                                    onChange={this.handleChange}
                                                    step="1"
                                                    className="slider"
                                                    required
                                                />
                                            </div>

                                        </div>
                                        <div className="form-group col-lg-6">
                                            <label>20. How much time can you spare in a week?</label>
                                            <input
                                                type="text"
                                                name="duration_week"
                                                className="form-control input-field"
                                                placeholder="Enter Answer"
                                                value={this.state.fields.duration_week}
                                                onChange={this.handleChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-lg-6">
                                            <label>21. Will you be comfortable in working on weekends?</label>
                                            <input
                                                type="text"
                                                name="weekends_avaliablity"
                                                className="form-control input-field"
                                                placeholder="Enter Answer"
                                                value={this.state.fields.weekends_avaliablity}
                                                onChange={this.handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="form-group col-lg-6">
                                            <label>22. Will you be comfortable in training teachers?</label>
                                            <input
                                                type="text"
                                                name="training_trachers"
                                                className="form-control input-field"
                                                placeholder="Enter Answer"
                                                value={this.state.fields.training_trachers}
                                                onChange={this.handleChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-lg-6">
                                            <label>23. How soon can you start working with us?</label>
                                            <input
                                                type="text"
                                                name="start_working_from"
                                                className="form-control input-field"
                                                placeholder="Enter Answer"
                                                value={this.state.fields.start_working_from}
                                                onChange={this.handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="form-group col-lg-6">
                                            <label>24. Share your strengths (Answer in 50-75 words)</label>
                                            <input
                                                type="text"
                                                name="strengths"
                                                className="form-control input-field"
                                                placeholder="Enter Answer"
                                                value={this.state.fields.strengths}
                                                onChange={this.handleChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-lg-6">
                                            <label>25. Share your weaknesses (Answer in 50-75 words)</label>
                                            <input
                                                type="text"
                                                name="weakness"
                                                className="form-control input-field"
                                                placeholder="Enter Answer"
                                                value={this.state.fields.weakness}
                                                onChange={this.handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="form-group col-lg-6">
                                            <label>26. What core values are close to you?</label>
                                            <input
                                                type="text"
                                                name="core_value"
                                                className="form-control input-field"
                                                placeholder="Enter Answer"
                                                value={this.state.fields.core_value}
                                                onChange={this.handleChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-lg-6">
                                            <label>27. What do you expect to gain from working with Asaadharan Foundation?(Answer in 100-150 words)</label>
                                            <input
                                                type="text"
                                                name="wrk_with_asadharan"
                                                className="form-control input-field"
                                                placeholder="Enter Answer"
                                                value={this.state.fields.wrk_with_asadharan}
                                                onChange={this.handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="form-group col-lg-6">
                                            <label>28. Why do you want to work with a philanthropic organization? (Answer in 100-150 words)</label>
                                            <input
                                                type="text"
                                                name="wrk_with_philanthropic"
                                                className="form-control input-field"
                                                placeholder="Enter Answer"
                                                value={this.state.fields.wrk_with_philanthropic}
                                                onChange={this.handleChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-lg-6">
                                            <label>29. What is the biggest goal of your life?</label>
                                            <input
                                                type="text"
                                                name="goals"
                                                className="form-control input-field"
                                                placeholder="Enter Answer"
                                                value={this.state.fields.goals}
                                                onChange={this.handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="form-group col-lg-6">
                                            <label>30. What is your monthly salary expectation?</label>
                                            <input
                                                type="text"
                                                name="salary_expectations"
                                                className="form-control input-field"
                                                placeholder="Enter Answer"
                                                value={this.state.fields.salary_expectations}
                                                onChange={this.handleChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <label>Attach Your Resume</label>
                                            <input type="file"
                                                onChange={this.handleFileUpload} />
                                        </div>
                                    </div> */}
                                    <div className="col-lg-12 d-flex justify-content-center">
                                        <button
                                            type="submit"
                                            id="submit_btn_career"
                                            value="Submit"
                                            className="btn center-block mt-40 col-lg-4"
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    {/* <!-- /container--> */}
                </section>
                {/* <!-- /Section ends --> */}
            </div>
        );
    }
}

export default CareerQuestions;