import React, { Component } from "react";
import Loader from "../Components/Loader";
import NumberCounter from 'number-counter';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: true,
      finishStatus: true,
      loaderAction: true
    };
  }

  handleReload = (route) => {
    if (window.location.pathname === '/beta/' && route === '/') {
    }
    else {
      this.props.history.push(process.env.PUBLIC_URL + route);
      window.location.reload(true);
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    setTimeout(() => {
      this.setState({ loaderAction: false });
    }, 2500);
  }

  render() {
    return (
      // <!-- Page width 'Boxed' of 'Full' -->
      <div className="full" >
        {this.state.loaderAction ? <Loader /> : null}


        {/* <!-- Slider --> */}
        <div id="layerslider">
          {/* <!-- Slide 1 --> */}
          <div className="ls-slide" data-ls="transition2d:104;timeshift:-2000;">
            {/* <!-- Background image --> */}
            <img
              src="img/banner1-re.jpg"
              className="ls-bg"
              alt="Slide background"
            />
            {/* <!-- Parallax Image --> */}
            <img
              src="img/sun.png"
              className="ls-l img-responsive hidden-xs hidden-sm parallax1"
              alt=""
              data-ls="delayin:1000;easingin:fadeIn;parallaxlevel:7;"
            />
            {/* <!-- Text --> */}
            <div
              className="ls-l header-text container"
              data-ls="offsetxin:0;durationin:2000;delayin:1500;easingin:easeOutElastic;rotatexin:-90;transformoriginin:50% top 0;offsetxout:-200;durationout:1000;parallaxlevel:2;"
            >
              <p className="subtitle hidden-xs">
                {" "}
                Learning Today.. Leading Tomorrow{" "}
              </p>
              {/* <!-- Button --> */}
              <div className="page-scroll hidden-xs">
                <a
                  className="btn"
                  href="#reach_us"
                  onClick={() => this.handleReload("/reachus")}
                >
                  Reach Us
                </a>
              </div>
            </div>
            {/* <!-- Parallax Image --> */}
            <img
              src="img/flower.png"
              className="ls-l img-responsive hidden-xs hidden-sm parallax2"
              alt=""
              data-ls="delayin:1500;easingin:fadeIn;parallaxlevel:6;"
            />
          </div>

          {/* <!-- Slide 2 --> */}
          <div className="ls-slide" data-ls="transition2d:104;timeshift:-2000;">
            <img
              src="img/banner.jpg"
              className="ls-bg"
              alt="Slide background"
            />
            <img
              src="img/sun.png"
              className="ls-l img-responsive hidden-xs hidden-sm parallax1"
              alt=""
              data-ls="delayin:1000;easingin:fadeIn;parallaxlevel:7;"
            />
            <div
              className="ls-l header-text"
              data-ls="offsetxin:0;durationin:2000;delayin:1500;easingin:easeOutElastic;rotatexin:-90;transformoriginin:50% top 0;offsetxout:-200;durationout:1000;parallaxlevel:2;"
            >
              {/* <h1>Welcome to Asaadharan</h1> */}
              <p className="subtitle hidden-xs">
                {" "}
                New Fellowship 2023 Forms are Out{" "}
              </p>
              <div className="page-scroll hidden-xs">
                <a className="btn" href="#our_programs" onClick={() => this.handleReload("/fellow")}>
                  Apply Now
                </a>
              </div>
            </div>
            <img
              src="img/flower.png"
              className="ls-l img-responsive hidden-xs hidden-sm parallax2"
              alt=""
              data-ls="delayin:1500;easingin:fadeIn;parallaxlevel:6;"
            />
          </div>

          {/* <!-- Slide 3 --> */}
          <div className="ls-slide" data-ls="transition2d:104;timeshift:-2000;">
            {/* <!-- Background image --> */}
            <img
              src="img/banner4.jpg"
              className="ls-bg"
              alt="Slide background"
            />
            {/* <!-- Parallax Image --> */}
            <img
              src="img/bee.png"
              className="ls-l img-responsive hidden-xs hidden-sm parallax1"
              alt=""
              data-ls="delayin:1500;easingin:fadeIn;parallaxlevel:7;"
            />
            {/* <!-- Text --> */}
            <div
              className="ls-l header-text"
              data-ls="offsetxin:0;durationin:2000;delayin:1500;easingin:easeOutElastic;rotatexin:-90;transformoriginin:50% top 0;offsetxout:-200;durationout:1000;parallaxlevel:2;"
            >
              <p className="subtitle hidden-xs">
                {" "}
                Be a story-teller for children{" "}
              </p>
              <div className="page-scroll hidden-xs">
                <a className="btn" href="#our_programs" onClick={() => this.handleReload("/")}>
                Reading Campaign
                </a>
              </div>
            </div>
            {/* <!-- Parallax Image --> */}
            <img
              src="img/star.png"
              className="ls-l img-responsive hidden-xs hidden-sm parallax2"
              alt=""
              data-ls="delayin:1500;easingin:fadeIn;parallaxlevel:6;"
            />
          </div>

          {/* <!-- Slide 4 --> */}
          <div className="ls-slide" data-ls="transition2d:104;timeshift:-2000;">
            {/* <!-- Background image --> */}
            <img
              src="img/banner-2-re.jpg"
              className="ls-bg"
              alt="Slide background"
            />
            {/* <!-- Parallax Image --> */}
            <img
              src="img/bee.png"
              className="ls-l img-responsive hidden-xs hidden-sm parallax1"
              alt=""
              data-ls="delayin:1500;easingin:fadeIn;parallaxlevel:7;"
            />
            {/* <!-- Text --> */}
            <div
              className="ls-l header-text"
              data-ls="offsetxin:0;durationin:2000;delayin:1500;easingin:easeOutElastic;rotatexin:-90;transformoriginin:50% top 0;offsetxout:-200;durationout:1000;parallaxlevel:2;"
            >
              <p className="subtitle hidden-xs">
                {" "}
                Efforts to make reach the quality education to under
                privileged's{" "}
              </p>
            </div>
            {/* <!-- Parallax Image --> */}
            <img
              src="img/star.png"
              className="ls-l img-responsive hidden-xs hidden-sm parallax2"
              alt=""
              data-ls="delayin:1500;easingin:fadeIn;parallaxlevel:6;"
            />
          </div>


        </div>
        {/* <!-- /Layerslider ends--> */}

        {/* <!-- Clouds SVG Divider --> */}
        <div className="hidden-xs container-fluid cloud-divider white-bgcolor">
          <svg
            id="deco-clouds1"
            className="head"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            width="100%"
            height="100"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
          >
            <path
              d="M-5 100 Q 0 20 5 100 Z
                M0 100 Q 5 0 10 100
                M5 100 Q 10 30 15 100
                M10 100 Q 15 10 20 100
                M15 100 Q 20 30 25 100
                M20 100 Q 25 -10 30 100
                M25 100 Q 30 10 35 100
                M30 100 Q 35 30 40 100
                M35 100 Q 40 10 45 100
                M40 100 Q 45 50 50 100
                M45 100 Q 50 20 55 100
                M50 100 Q 55 40 60 100
                M55 100 Q 60 60 65 100
                M60 100 Q 65 50 70 100
                M65 100 Q 70 20 75 100
                M70 100 Q 75 45 80 100
                M75 100 Q 80 30 85 100
                M80 100 Q 85 20 90 100
                M85 100 Q 90 50 95 100
                M90 100 Q 95 25 100 100
                M95 100 Q 100 15 105 100 Z"
            ></path>
          </svg>
        </div>
        {/* <!-- / section ends--> */}

        {/* <!-- Section Services --> */}
        <section id="services" className="color-section white-bgcolor">

          <div className="container pt-20 pb-40">
            <div className="col-lg-12">
              <div className="section-heading mb-40">
                <h2 className="gallery-head">Problem</h2>
                <h3 className="mt-40">The Problem we are trying to address in the State of Gujarat</h3>
              </div>
            </div>
            <div >
              {/* <!-- main text --> */}
              <div id="blog-page" className="single-page">
                <div className="col-md-12 col-lg-12 blog-home">
                  <div className="row mb-40 d-flex justify-content-center flex-wrap">

                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <div className="problem-section">
                        <div className="d-flex blur-bg" >
                          <h2 className="h2-heading">
                            27%
                          </h2>
                          <span>Grade 8 students cannot read a simple text which has the difficulty of  grade 2 level.</span>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <div className="problem-section_1">

                        <div className="d-flex blur-bg">
                          <h2 className="h2-heading">
                            65%
                          </h2><span>Grade 8 students cannot solve simple 3 digits by digit division problems of a grade 2 level.</span></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-12">
                  <div className="mb-20">
                    <p className="font-17"><b className="yellow-color">Lack of awareness</b>  and importance of ensuring <b className="yellow-color">holistic foundational education</b> amongst students, parents and other important <b className="yellow-color">stakeholders</b>
                    </p>
                    <p className="font-17">Students are unable to first <b className="yellow-color">'learn to read'</b> to be able to <b className="yellow-color">'read to learn'</b> in later years
                    </p>
                  </div>
                </div>

              </div>
              <div className="single-page">
                <div className="col-md-3">
                  <div className="comment media mb-40 mb-sm-0">
                    <div className="media-body">
                      <p className="font-17"><b className="yellow-color">Poor foundation</b>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-1">
                  <i className="fa fa-arrow-right font-arrow"></i>
                </div>
                <div className="col-md-4">
                  <div className="comment media mb-40 mb-sm-0">
                    <div className="media-body">
                      <p className="font-17"><b className="yellow-color">Poor life long learning capacity</b>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-1">
                  <i className="fa fa-arrow-right font-arrow"></i>
                </div>
                <div className="col-md-3">
                  <div className="comment media mb-40">
                    <div className="media-body">
                      <p className="font-17"><b className="yellow-color">A vicious poverty cycle</b>
                      </p>
                    </div>
                  </div>
                </div>

              </div>

              <div className="single-page">
                <div className="blog-post post-main">
                  <div className="col-md-10 col-lg-12 home_page_quote">
                    <blockquote>We, at Asaadharan Foundation, believe that the root cause of the problem lies in the lack of skilled
                      educators and empowered stakeholders at different levels who are equipped enough to idetify, prioritize
                      and improve the quality holistic foundational ducation for every child.</blockquote>
                  </div>
                </div>
              </div>

            </div>
            <div className="row mt-60">
              <h2 className="gallery-head">Our Solution</h2>

              <h3 className="mb-40 mt-40">
                Asaadharan Foundation has designed a 3-Years fellowship program
              </h3>
              <div className="col-md-12 col-lg-12">
                <img src="img/bothcircle.png" alt="" className="img-responsive" />
                {/* <img src="img/long.png" alt="" className="img-responsive"/> */}
              </div>
            </div>
            {/* <!-- /row --> */}
          </div>
          {/* <!-- /container--> */}
        </section>
        {/* <!-- /Section ends --> */}

        <section id="call-to-action" className="small-section">
          <div className="blur-black-bgcolor">
            <div className="container text-center">
              <div className="col-lg-12">
                <div className="section-heading">
                  <h2 className="gallery-head">Our Approach</h2>
                </div>
              </div>
              <div className="col-lg-12 mt-40">
                <div className="row flex-center">
                  <div className="col-md-10 col-sm-10 text-center">
                    <div className="page-scroll d-flex justify-content-center flex-sm-wrap">
                      <a className="btn font-2em approach-btn" href="#our_programs" onClick={() => this.handleReload('/fellowship')}>
                        Asaadharan Fellowship
                      </a>
                      {/* <a className="btn font-2em approach-btn" href="#our_programs" onClick={() => this.handleReload('/adda')}>
                        Buddy Project
                      </a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </section>
        {/* <!-- Section Callout --> */}
        <section id="callout" className="small-section white-bgcolor mt-60 mb-35">
          <h2 className="gallery-head">Our Impact</h2>

          <div className="hidden-xs">
            <div className="cloud x1"></div>
            <div className="cloud x2"></div>
            <div className="cloud x3"></div>
            <div className="cloud x4"></div>
            <div className="cloud x5"></div>
            <div className="cloud x6"></div>
            <div className="cloud x7"></div>
          </div>
          <div>
            <div className="sun hidden-sm hidden-xs">
              <div className="sun-face">
                <div className="sun-hlight"></div>
                <div className="sun-leye"></div>
                <div className="sun-reye"></div>
                <div className="sun-lred"></div>
                <div className="sun-rred"></div>
                <div className="sun-smile"></div>
              </div>
              <div className="sun-anime">
                <div className="sun-ball"></div>
                <div className="sun-light">
                  <b></b>
                  <s></s>
                </div>
                <div className="sun-light">
                  <b></b>
                  <s></s>
                </div>
                <div className="sun-light">
                  <b></b>
                  <s></s>
                </div>
                <div className="sun-light">
                  <b></b>
                  <s></s>
                </div>
                <div className="sun-light">
                  <b></b>
                  <s></s>
                </div>
                <div className="sun-light">
                  <b></b>
                  <s></s>
                </div>
                <div className="sun-light">
                  <b></b>
                  <s></s>
                </div>
                <div className="sun-light">
                  <b></b>
                  <s></s>
                </div>
                <div className="sun-light">
                  <b></b>
                  <s></s>
                </div>
                <div className="sun-light">
                  <b></b>
                  <s></s>
                </div>
                <div className="sun-light">
                  <b></b>
                  <s></s>
                </div>
                <div className="sun-light">
                  <b></b>
                  <s></s>
                </div>
              </div>
            </div>

            <div className="col-lg-10 mt-40">
              <div className="row flex-center flex-sm-wrap">
                <div className="col-lg-3 col-md-4 col-sm-12">
                  <div className="story-detail border-css">
                    <span className="counter-home pink-color"><NumberCounter end={51} delay={6} /></span><span className="counter-home pink-color">+</span>
                  </div>
                  <h4>teachers</h4>
                </div>
                <div className="col-lg-1 col-sm-12 visible-lg-block hidden">
                </div>
                <div className="col-lg-3 col-md-4 col-sm-12">
                  <div className="story-detail border-css">
                    <span className="counter-home pink-color"><NumberCounter end={1260} delay={6} /></span><span className="counter-home pink-color">+</span>
                  </div>
                  <h4>students</h4>
                </div>
                <div className="col-lg-1 col-sm-12 visible-lg-block hidden">
                </div>
                <div className="col-lg-3 col-md-4 col-sm-12">
                  <div className="story-detail border-css">
                    <span className="counter-home pink-color"><NumberCounter end={36} delay={6} /></span><span className="counter-home pink-color">+</span>
                  </div>
                  <h4>Duration (Months)</h4>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- Section Ends--> */}
        <section id="partner" className="color-section white-bgcolor mt-60">
          <div>
            <div className="col-lg-8 col-lg-offset-2">
              <div className="section-heading mb-20">
                <h2 className="gallery-head">Our Partners & Collaborations</h2>
              </div>
            </div>
            <div id="owl-team" className="owl-carousel mt-xs-40">
              <div className="col-lg-12">
                <div className="team-item">
                  <img src="img/tfi-new.png" alt="" />
                  {/* <div className="team-caption">
                    <h5 className="text-light font-20">TFIX</h5>
                  </div> */}
                </div>
              </div>
              <div className="col-lg-12">
                <div className="team-item">
                  <img src="img/involve-new.png" alt="" />
                  {/* <div className="team-caption">
                    <h5 className="text-light font-20">Involve</h5>
                  </div> */}
                </div>
              </div>
              <div className="col-lg-12">
                <div className="team-item">
                  <img src="img/LIFI logo.png" alt="" />
                  {/* <div className="team-caption">
                    <h5 className="text-light font-20">Involve</h5>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Home;
