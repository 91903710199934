import React, { Component } from "react";
import Loader from "../Components/Loader";
import Faqs from "./Faqs";

class Fellow extends Component {
    state = { loaderAction: true };
    handleApply = (route) => {
        if (window.location.pathname === "/beta/" && route === "/") {
        } else {
            this.props.history.push(process.env.PUBLIC_URL + route);
            window.location.reload(true);
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        setTimeout(() => {
            this.setState({ loaderAction: false });
        }, 2000)
    }
    render() {
        return (
            <div className="full" id="join_us">
                {this.state.loaderAction ? <Loader /> : null}

                {/* <!-- Section Programs --> */}
                <section className="color-section white-bgcolor">
                    <div className="container pt-150">
                        <div className="col-lg-12">
                            {/* <!-- Section heading --> */}
                            <div className="section-heading flex-center">
                                {/* <h2>Advisors</h2> */}
                                <img src="img/fellow2.jpg" alt="" className="img-responsive" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 mb-20">
                                <h3 className="blue-color">“We make a living by what we get, But we make a life by what we give.” Winston Churchill</h3>
                                <p>As a fellow, you will work as a teacher, in the school system at the foundational level for a period of two years.  You will work towards providing holistic foundational education to the students.  By entering schools in the urban-slum pockets of Surat, you will get first-hand experience of understanding the challenges faced in existing foundational development of the child and the education system by engaging with all stakeholders. It will give you an opportunity to identify gaps in the existing system that resonate with you the most. Through this, you will attain the crucial awareness of grassroot challenges and become empathetic enough to identify your role in the larger movement, and work towards developing the relevant knowledge, skills and mindsets to bring about change at a bigger level. Let us be the platform for you to become a leader and also an advocate of foundational education.</p>
                            </div>
                            <a href="https://docs.google.com/file/d/1GFQBDdmUm7RmE3l47hZlSFeI8RE0bwoI/edit?usp=docslist_api&filetype=msword" target="_blank" rel="noreferrer">
                                <button className="btn" style={{ float: 'left', clear: 'both', marginBottom: '10px' }}>
                                    Checkout the Fellowship Handout
                                </button>
                            </a>
                            <div className="col-lg-12 position-relative">
                                {/* <img src="img/fellow_cover.jpg" alt="career" className="img-responsive zig-zag-border" /> */}
                                <img src="img/fellow-career.jpg" alt="career" className="img-responsive zig-zag-border" />
                                <div>
                                    <button
                                        onClick={(e) => {
                                            e.preventDefault();
                                            window.location.href = 'https://docs.google.com/file/d/1_JGA2Umxz7tBu7hjFGsYJwXju-9rYtXP/edit?usp=docslist_api&filetype=msword';
                                        }}
                                        // onClick={() => this.handleApply("/fellow-questions")}
                                        className="btn center-block mt-40 float-right career-eng-btn"
                                    >
                                        Apply In English
                                    </button>
                                    <button
                                        onClick={(e) => {
                                            e.preventDefault();
                                            window.location.href = 'https://docs.google.com/file/d/1yLuNAY1F9szhMwwdU6dhbKVz90yFx0dc/edit?usp=docslist_api&filetype=msword';
                                        }}
                                        // onClick={() => this.handleApply("/fellow-questions")}
                                        className="btn center-block mt-40 float-right career-btn"
                                    >
                                        Apply In Hindi
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- /container--> */}
                </section>
                <section className="color-section white-bgcolor">
                    <div className="container pt-0 pb-40">
                        <div className="row">
                            <div className="col-lg-12 mb-20">
                                <h2 className="gallery-head mb-20">Frequently Asked Questions</h2>
                                <Faqs />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <img src="img/Fellowship_Journey.png" alt="" className="img-responsive" />
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- /Section ends --> */}
            </div>
        );
    }
}

export default Fellow;
