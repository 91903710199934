import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
const { forwardRef, useImperativeHandle } = React;

const styles = theme => ({
  typography: {
    margin: theme.spacing(2),
  }
});

const DonatePopup = forwardRef((props, ref) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    useImperativeHandle(ref, () => ({
        handleClick (event) {
            setAnchorEl(event.currentTarget);
          }
    }));


  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className="pricing pricing-palden">
          <div className="pricing-item pricing-item-featured p-0">
            <div className="pricing-deco pledge-pad">
              <svg
                className="pricing-deco-img"
                enableBackground="new 0 0 300 100"
                height="100px"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 300 100"
                width="300px"
                x="0px"
                xmlSpace="preserve"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                xmlns="http://www.w3.org/2000/svg"
                y="0px"
              >
                <path
                  className="deco-layer deco-layer--1"
                  d="M30.913,43.944c0,0,42.911-34.464,87.51-14.191c77.31,35.14,113.304-1.952,146.638-4.729&#x000A;	c48.654-4.056,69.94,16.218,69.94,16.218v54.396H30.913V43.944z"
                  fill="#FFFFFF"
                  opacity="0.6"
                ></path>
                <path
                  className="deco-layer deco-layer--2"
                  d="M-35.667,44.628c0,0,42.91-34.463,87.51-14.191c77.31,35.141,113.304-1.952,146.639-4.729&#x000A;	c48.653-4.055,69.939,16.218,69.939,16.218v54.396H-35.667V44.628z"
                  fill="#FFFFFF"
                  opacity="0.6"
                ></path>
                <path
                  className="deco-layer deco-layer--3"
                  d="M43.415,98.342c0,0,48.283-68.927,109.133-68.927c65.886,0,97.983,67.914,97.983,67.914v3.716&#x000A;	H42.401L43.415,98.342z"
                  fill="#FFFFFF"
                  opacity="0.7"
                ></path>
                <path
                  className="deco-layer deco-layer--4"
                  d="M-34.667,62.998c0,0,56-45.667,120.316-27.839C167.484,57.842,197,41.332,232.286,30.428&#x000A;	c53.07-16.399,104.047,36.903,104.047,36.903l1.333,36.667l-372-2.954L-34.667,62.998z"
                  fill="#FFFFFF"
                ></path>
              </svg>
              <div className="pricing-price line-height-03 m-0">
                <p className="pricing-currency">
                  “It’s easy to make a buck. It’s a lot tougher to make a difference.” -Tom Brokaw
                </p>
              </div>
            </div>
            <div className="page-scroll mb-20 ps-30">
              <h3 className="relative-22">Donate Now</h3>
            </div>
            <div className="donate-txt m-0 pt-25 mb-20 plr-30">
              <span>The Organization Bank Details to facilitate easy contributions</span>
            </div>
            <div className="page-scroll plr-40 text-left pb-40">
              <div><b>Account Name</b> - <span className="font-14">Asaadharan Foundation</span></div>
              <div><b>Account No.</b> - <span className="font-14">919010010962075</span></div>
              <div><b>Bank Name</b> - <span className="font-14">AXIS Bank</span></div>
              <div><b>Bank Branch</b> - <span className="font-14">Surat</span></div>
              <div><b>IFS Code</b> - <span className="font-14">UTIB0000047</span></div>
            </div>
          </div>
        </div>
      </Popover>
  );
})

export default withStyles(styles)(DonatePopup);
