import "./App.css";
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./Pages/Home";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Blog from "./Pages/Blog";
import Team from "./Pages/Team";
import TeamAndMembers from "./Pages/TeamAndMembers";
import Advisors from "./Pages/Advisors";
import AboutUs from "./Pages/AboutUs";
import OurCoreValues from "./Pages/OurCoreValues";
import OurPrograms from "./Pages/OurPrograms";
import ReachUs from "./Pages/ReachUs";
import 'dotenv';
import Gallery from "./Pages/Gallery";
import Fellow from "./Pages/Fellow";
import Career from "./Pages/Career";
import Volunteer from "./Pages/Volunteer";
import FellowshipProgram from "./Pages/FellowshipProgram";
import LearningAddaProgram from "./Pages/LearningAddaProgram";
import FellowQuestions from "./Pages/FellowQuestions";
import CareerQuestions from "./Pages/CareerQuestions";
import VolunteerQuestions from "./Pages/VolunteerQuestions";
import GalleryGroup from "./Pages/GalleryGroup";
import OurStory from "./Components/OurStory";
// import ReactLoading from "react-loading";

function App() {
  // const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);

    // setTimeout(() => {
    //   setLoading(false);
    // }, 2000);
  }, []);
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/`}
            render={(props) => (
              <>
                <Header {...props} />
                <Home {...props} />
              </>
            )}
          />

          <Route
            path={`${process.env.PUBLIC_URL}/blog`}
            render={(props) => (
              <>
                <Header {...props} />
                <Blog {...props} />
              </>
            )}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/team`}
            render={(props) => (
              <>
                <Header {...props} />
                <Team {...props} />
              </>
            )}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/teamAndMembers`}
            render={(props) => (
              <>
                <Header {...props} />
                <TeamAndMembers {...props} />
              </>
            )}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/advisors`}
            render={(props) => (
              <>
                <Header {...props} />
                <Advisors {...props} />
              </>
            )}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/aboutus`}
            render={(props) => (
              <>
                <Header {...props} />
                <AboutUs {...props} />
              </>
            )}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/ourcorevalues`}
            render={(props) => (
              <>
                <Header {...props} />
                <OurCoreValues {...props} />
              </>
            )}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/ourprograms`}
            render={(props) => (
              <>
                <Header {...props} />
                <OurPrograms {...props} />
              </>
            )}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/fellowship`}
            render={(props) => (
              <>
                <Header {...props} />
                <FellowshipProgram {...props} />
              </>
            )}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/adda`}
            render={(props) => (
              <>
                <Header {...props} />
                <LearningAddaProgram {...props} />
              </>
            )}
          />
          {/* <Route
            path={`${process.env.PUBLIC_URL}/contribution`}
            render={(props) => (
              <>
                <Header {...props} />
                <Contributions {...props} />
              </>
            )}
          /> */}
          <Route
            path={`${process.env.PUBLIC_URL}/gallery-group`}
            render={(props) => (
              <>
                <Header {...props} />
                <GalleryGroup {...props} />
              </>
            )}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/gallery`}
            render={(props) => (
              <>
                <Header {...props} />
                <Gallery {...props} />
              </>
            )}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/fellow`}
            render={(props) => (
              <>
                <Header {...props} />
                <Fellow {...props} />
              </>
            )}
          />
          {/* {loading ?
            <div className="loader-css">
              <ReactLoading type={"balls"} color="#000" />
            </div>
            : */}
          <Route
            path={`${process.env.PUBLIC_URL}/career`}
            render={(props) => (
              <>
                <Header {...props} />
                <Career {...props} />
              </>
            )}
          />
          {/* } */}
          <Route
            path={`${process.env.PUBLIC_URL}/volunteer`}
            render={(props) => (
              <>
                <Header {...props} />
                <Volunteer {...props} />
              </>
            )}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/reachus`}
            render={(props) => (
              <>
                <Header {...props} />
                <ReachUs {...props} />
              </>
            )}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/fellow-questions`}
            render={(props) => (
              <>
                <Header {...props} />
                <FellowQuestions {...props} />
              </>
            )}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/career-questions`}
            render={(props) => (
              <>
                <Header {...props} />
                <CareerQuestions {...props} />
              </>
            )}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/volunteer-questions`}
            render={(props) => (
              <>
                <Header {...props} />
                <VolunteerQuestions {...props} />
              </>
            )}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/our-story`}
            render={(props) => (
              <>
                <Header {...props} />
                <OurStory {...props} />
              </>
            )}
          />
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
