import React, { Component } from "react";

class OurPrograms extends Component {
  state = {};
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="full" id="our_programs">
        {/* <!-- Section Programs --> */}
        <section className="color-section white-bgcolor">
          <div className="container pt-150">
            <div className="col-lg-8 col-lg-offset-2">
              {/* <!-- Section heading --> */}
              <div className="section-heading">
                <h2>Our Programs</h2>
              </div>
            </div>
            {/* 1 details left side*/}
            <div className="row">
              {/* <!-- main text --> */}
              <div className="col-md-12 col-lg-7 text-center">
                <h3 className="text-center">Evening learning Centre, Udhana</h3>
                <p className="text-left">
                  In the Udhana area, Asaadharan was running an evening learning centre from May 
                  2016-February 2020.  For the past 4 years, we catered to approximately 500 children 
                  between 5-17 years of age and 15 teachers in the Udhana area.  What started as a 
                  small program of just 40 children under a bridge, quickly turned into a learning 
                  centre in a private school building that welcomed children from all castes and 
                  creeds, dropouts, children already attending school and out of school children. 
                  The learning centre focused on core subjects like Maths, English, Hindi, Science, 
                  Geography and Art, but beyond that to ensure holistic child development we aimed 
                  to inculcate basic manners, etiquettes and values.  All learning was done through 
                  activity-based teaching techniques, contextual examples and peer learning. 
                  Our unique token-based assessment technique always kept children attentive in 
                  class, motivated, excited and most importantly allowed them to learn to spend their 
                  resources (tokens in their case) wisely.
                </p>
              </div>
              {/* <!-- /col-sm-12--> */}
              <div className="col-md-12 col-lg-5">
                {/* <!-- Image --> */}
                <img
                  src="img/services.png"
                  alt=""
                  className="img-responsive center-block"
                />
              </div>
              {/* <!-- /col-md-12--> */}
            </div>
            <div className="row">
              <div className="col-md-12 col-lg-12">
                <div className="text-left">
                  <table>
                    <tbody>
                      <tr>
                        <td className="width-50p">
                          <h6 className="table-h6">Total no. of teachers :</h6>
                        </td>
                        <td>
                          <span>15</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="width-50p">
                          <h6 className="table-h6">
                            Total no. of students in 2016-2020 :
                          </h6>
                        </td>
                        <td>
                          <span>500</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="width-50p">
                          <h6 className="table-h6">Subjects focused on :</h6>
                        </td>
                        <td>
                          <span>
                            Hindi, English, Maths, Science, Geography, and Art &
                            Craft.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td className="width-50p">
                          <h6 className="table-h6">Duration of program :</h6>
                        </td>
                        <td>
                          <span>4 Years</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/* 2 details right side*/}
            <div className="row mt-90">
              {/* <!-- /col-sm-12--> */}
              <div className="col-md-12 col-lg-5">
                {/* <!-- Image --> */}
                <img
                  src="img/services.png"
                  alt=""
                  className="img-responsive center-block"
                />
              </div>
              {/* <!-- /col-md-12--> */}
              {/* <!-- main text --> */}
              <div className="col-md-12 col-lg-7 text-center">
                <h3 className="text-center">
                  Student Teacher Training Program (STTP), Pandesara
                </h3>
                <p className="text-left">
                In the Pandesara area, Asaadharan started a pilot programme in June 2019, 
                Student Teachers Training Programme (STTP), with just one school with the 
                permission of Nagar Prathmik Shikshan Samithi that had 1400 girls and just 8 
                teachers. It aimed at focusing on Grade 8 government school students who had 
                an affinity towards teaching.  These students were imparted with life skills 
                and values required to become a teacher.  This was done through a short training 
                program with the aim to later utilize these skills to develop lesson plans for the 
                existing curriculum of younger Grade 2 students.  This programme was also designed 
                to improve the basic literacy and numeracy of the students that were trained to 
                become teachers along with making the school system more sustainable through the 
                development of student-leaders. The pilot ended in January 2020 giving us a lot 
                of learnings for the future.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="text-left">
                  <table>
                    <tbody>
                      <tr>
                        <td className="width-50p">
                          <h6 className="table-h6">Total no. of teachers :</h6>
                        </td>
                        <td>
                          <span>10</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="width-50p">
                          <h6 className="table-h6">
                            Total no. of students impacted through the program :
                          </h6>
                        </td>
                        <td>
                          <span>200</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="width-50p">
                          <h6 className="table-h6">Subjects focused on :</h6>
                        </td>
                        <td>
                          <span>Hindi & Maths</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="width-50p">
                          <h6 className="table-h6">Duration of program :</h6>
                        </td>
                        <td>
                          <span>7 Months</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/* 3 details left side*/}
            <div className="row mt-90">
              {/* <!-- main text --> */}
              <div className="col-md-12 col-lg-7 text-center">
                <h3 className="text-center">
                  Accelerated Learning Program (ALP): Teachers Training
                </h3>
                <p className="text-left">
                  During the pandemic, we developed an Accelerated Learning Program (ALP) which 
                  aimed to focus on children between 8-14 years of age who are dropouts or never 
                  been to a school before to support them in reaching a Standard 4 level of Literacy 
                  and Numeracy .  The program focused on developing the basic foundation of children 
                  in English Language and Mathematics.  In view of this, we condensed the existing 
                  NCERT curriculum of Standards 1,2 & 3 into 14 months in line with the NCERT learning 
                  outcomes as a basis of assessing the growth of children.
                </p>
                <p className="text-left">
                With the support of large organizations like Teach for India and Aavishkaar - Palampur 
                we got the necessary support to develop a teachers training program to execute the ALP 
                by providing necessary skills to teachers.  We then collaborated with the Sarva Shiksha 
                Abhiyan (SSA), Surat, to execute our training program with 51 Balmitras / teachers who 
                work specifically in the Special Training Program (STP) that reaches out to and educates 
                dropout children in the major slum pockets of Surat.  In December 2020, we supported and 
                trained the Balmitras in enhancing their pedagogical skills, building subject proficiency 
                and application of subject specific TLM (Teaching Learning Material) using the ALP as a base.
                </p>
              </div>
              {/* <!-- /col-sm-12--> */}
              <div className="col-md-12 col-lg-5">
                {/* <!-- Image --> */}
                <img
                  src="img/services.png"
                  alt=""
                  className="img-responsive center-block"
                />
              </div>
              {/* <!-- /col-md-12--> */}
            </div>
            
            <div className="row mt-90">
              {/* <!-- /col-sm-12--> */}
              <div className="col-md-12 col-lg-5">
                {/* <!-- Image --> */}
                <img
                  src="img/services.png"
                  alt=""
                  className="img-responsive center-block"
                />
              </div>
              {/* <!-- /col-md-12--> */}
              {/* <!-- main text --> */}
              <div className="col-md-12 col-lg-7 text-center">
                <h3 className="text-center">Cluster Program</h3>
                <p className="text-left">
                Given the current global pandemic situation, we used the learning techniques established 
                from our Accelerated Learning Program (ALP) to reach out to children in-person that had no 
                access to learning resources (TV, phones, radios, etc.).  The ALP curriculum supported the 
                growth of the children in a time bound and accelerated manner through fun learning and 
                activity-based techniques.  The Cluster Program for students commenced in November 2020 
                and ended in February 2021, given the gradual opening of schools and tuition centres all over 
                the country.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="text-left">
                  <table>
                    <tbody>
                      <tr>
                        <td className="width-50p">
                          <h6 className="table-h6">Total no. of teachers :</h6>
                        </td>
                        <td>
                          <span>4</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="width-50p">
                          <h6 className="table-h6">Total no. of students :</h6>
                        </td>
                        <td>
                          <span>40</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="width-50p">
                          <h6 className="table-h6">Subjects focused on :</h6>
                        </td>
                        <td>
                          <span>Maths, English, Gujarati</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="width-50p">
                          <h6 className="table-h6">Duration of program :</h6>
                        </td>
                        <td>
                          <span>3 Months</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

          </div>
          {/* <!-- /container--> */}
        </section>
        {/* <!-- /Section ends --> */}
      </div>
    );
  }
}

export default OurPrograms;
