import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import {
    createTheme,
    MuiThemeProvider,
} from "@material-ui/core";

const myTheme = createTheme({
    overrides: {
        MuiDialog: {
            paperWidthSm: {
                borderRadius: "225px 45px 325px 75px/15px 125px 35px 155px !important",
                boxShadow: "6px 6px 0px rgb(103 102 102 / 20%) !important",
                padding: "0 0 !important",
                maxWidth: "1000px"
            },
            paper: {
                'overflow-y': 'auto'
            },
            paperScrollPaper: {
                'max-height': 'calc(100% - 5px)'
            }
        },
    },
});

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2, 3),
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    pFont: {
        fontFamily: "'Nunito',Helvetica,Arial,Lucida,sans-serif !important",
        fontSize: 14,
        padding: "10px 20px !important",
    },
    popupStyle: {
        borderRadius: "225px 45px 325px 75px/15px 125px 35px 155px !important",
        boxShadow: "6px 6px 0px rgb(103 102 102 / 20%) !important",
        padding: "10px 20px !important",
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h5">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

class TimelinePopup extends Component {
    state = {
        open: false
    };

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    render() {
        return (
            <div>
                <MuiThemeProvider theme={myTheme}>
                    <Dialog
                        onClose={this.handleClose}
                        aria-labelledby="customized-dialog-title"
                        open={this.state.open}
                    >
                        <div
                            className="popupStyle"
                            style={{
                                background: this.props.bgcolor || "rgb(166 241 255)"
                            }}
                        >
                            <DialogTitle
                                id="customized-dialog-title"
                                onClose={this.handleClose}
                            >
                                <h4>{this.props.year}</h4>

                            </DialogTitle>
                            <DialogContent dividers>
                                <div >
                                    <div className="text-center">

                                        {/* any arbitrary component can go here */}

                                        <h3>{this.props.title}</h3>
                                        <p>{this.props.description}</p>
                                    </div>


                                    <div className="row mt-40 d-flex justify-content-center flex-md-wrap">

                                        {/* <OwlSilder id={"owl-about"} /> */}
                                        {this.props.exp_teachers !== undefined ?
                                            <div className="col-xs-10 col-sm-6 col-md-4 col-lg-3">
                                                <div className="story-detail" style={{ borderColor: this.props.borderColor }}>
                                                    <span className="counter-text">{this.props.exp_teachers}</span>
                                                </div>
                                                <h4 className="text-center">{this.props.year === "June 2019"? "Student teachers" : "teachers"}</h4>
                                            </div>
                                            :
                                            null}
                                        {this.props.exp_students !== undefined ?
                                            <div className="col-xs-10 col-sm-6 col-md-4 col-lg-3">
                                                <div className="story-detail" style={{ borderColor: this.props.borderColor }}>
                                                    {/* <CountUp className="counter-text" end={exp_students} /> */}
                                                    <span className="counter-text">{this.props.exp_students}</span>
                                                </div>
                                                <h4 className="text-center">students</h4>
                                            </div>
                                            :
                                            null}
                                        {this.props.exp_subjects !== undefined ?
                                            <div className="col-xs-10 col-sm-6 col-md-4 col-lg-3">
                                                <div className="story-detail" style={{ borderColor: this.props.borderColor }}>
                                                    <span className="counter-text">{this.props.exp_subjects}</span>
                                                </div>
                                                <h4 className="text-center">Subjects</h4>
                                            </div>
                                            :
                                            null}
                                        {this.props.exp_years !== undefined ?
                                            <div className="col-xs-10 col-sm-6 col-md-4 col-lg-3">
                                                <div className="story-detail" style={{ borderColor: this.props.borderColor }}>
                                                    <span className="counter-text">{this.props.exp_years}</span>
                                                </div>
                                                <h4 className="text-center">Duration</h4>
                                            </div>
                                            :
                                            null}
                                    </div>
                                </div>
                            </DialogContent>
                        </div>
                    </Dialog>
                </MuiThemeProvider>
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(TimelinePopup);
