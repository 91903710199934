import axios from 'axios';

const backendUrl = 'https://asaadharan.org/beta/sitemanager';

export function create(createParam) {
    // console.log('http axios', createParam);
    return axios.post(backendUrl + createParam.route, createParam.jsonObject, createParam.headers);
}

export function read(getParam) {
    // console.log('http axios', getParam);
    return axios.get(backendUrl + getParam.route, getParam.headers);
}

export function remove(getParam) {
    // console.log('http axios', getParam);
    return axios.delete(backendUrl + getParam.route, getParam.headers);
} 