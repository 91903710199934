import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import InboxIcon from '@material-ui/icons/ArrowForward';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  head: {
    backgroundColor: '#2f2179',
    color: 'white',
    borderRadius: '20px',
    "&:hover": {
      backgroundColor: '#4836a4',
    }
  }
});

const myTheme = createTheme({
  overrides: {
    MuiTypography: {
      body1: {
        fontSize: "15px",
        lineHeight: "29px",
        fontFamily: "'Nunito',Helvetica,Arial,Lucida,sans-serif"
      }
    }
  }
});

class Faqs extends React.Component {
  state = {
    openOne: true,
    openTwo: false,
    openThree: false,
    openFour: false,
  };

  handleClickOne = () => {
    this.setState(state => ({ openOne: !state.openOne }));
  };

  handleClickTwo = () => {
    this.setState(state => ({ openTwo: !state.openTwo }));
  };

  handleClickThree = () => {
    this.setState(state => ({ openThree: !state.openThree }));
  };

  handleClickFour = () => {
    this.setState(state => ({ openFour: !state.openFour }));
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        <MuiThemeProvider theme={myTheme}>
          <List
            component="nav"
            className={classes.root}
          >
            <ListItem button className={classes.head} onClick={this.handleClickOne}>
              <ListItemText inset primary="Who can be our Fellow?" />
              {this.state.openOne ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={this.state.openOne} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary="Anyone who is 21 years and above," />
                </ListItem>
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary="Anyone who is a graduate with at least a Bachelor’s degree in any branch." />
                </ListItem>
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary="Anyone who is fluent in at least 2 languages - English, Hindi or Gujarati." />
                </ListItem>
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary="Anyone who is passionate to teach, willing to learn and grow." />
                </ListItem>
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary="Anyone who is passionate to work towards bringing change in the education sector." />
                </ListItem>
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary="Anyone who has a basic knowledge of computers/laptops - (Google Drive, Microsoft Office, etc)." />
                </ListItem>
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary="Anyone who is 21 years and above," />
                </ListItem>
              </List>
            </Collapse>
          </List>

          <List
            component="nav"
            className={classes.root}
          >
            <ListItem button className={classes.head} onClick={this.handleClickTwo}>
              <ListItemText inset primary="What will be the Responsibilities of a Fellow?" />
              {this.state.openTwo ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={this.state.openTwo} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary="Teaching students in grades 1,2 & 3 in Government schools of Surat." />
                </ListItem>
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary="Attend all the training sessions conducted by Asaadhaarn Foundation." />
                </ListItem>
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary="To implement the teachings and learning in the classroom and school to make it more relatable for children." />
                </ListItem>
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary="Build relations and create a safe space for students." />
                </ListItem>
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary="Build relations with parents, teachers and principals" />
                </ListItem>
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary="Be active and participate in all the learning spaces." />
                </ListItem>
              </List>
            </Collapse>
          </List>

          <List
            component="nav"
            className={classes.root}
          >
            <ListItem button className={classes.head} onClick={this.handleClickThree}>
              <ListItemText inset primary="What do you get?" />
              {this.state.openThree ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={this.state.openThree} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary="Monthly Salary of Rs. 12,000/-" />
                </ListItem>
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary="In-depth training on pedagogy and leadership skills." />
                </ListItem>
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary="21st Century skills." />
                </ListItem>
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary="Understanding the grassroot challenges in the education sector and working on the solution." />
                </ListItem>
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary="Chance to join Asaadharan Team." />
                </ListItem>
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary="Varied Career options in the education sector (starting your own organisation, teacher trainer, so on.)" />
                </ListItem>
              </List>
            </Collapse>
          </List>

          <List
            component="nav"
            className={classes.root}
          >
            <ListItem button className={classes.head} onClick={this.handleClickFour}>
              <ListItemText inset primary="How long will I have to commit?" />
              {this.state.openFour ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={this.state.openFour} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary="Asaadharan Fellowship is a 3 year (May 2022 - April 2025)  full-time paid fellowship program. " />
                </ListItem>
              </List>
            </Collapse>
          </List>
        </MuiThemeProvider>
      </div>
    );
  }
}

Faqs.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Faqs);
