import React, { Component } from "react";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

const styles = theme => ({
    close: {
        padding: theme.spacing(1 / 2),
    },
    messageSize: {
        fontSize: "14px"
    }
});

function TransitionRight(props) {
    return <Slide {...props} direction="right" />;
}

class CustomSnackbar extends Component {
    constructor() {
        super();
        this.state = {
            open: false,
            alertMessage: ""
        }
    }

    handleClick = (open, message) => {
        this.setState({ open: open, alertMessage: message });
    };

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({ open: false });
    };

    render() {
        const { classes } = this.props;

        return (<Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            open={this.state.open}
            autoHideDuration={4000}
            onClose={this.handleClose}
            TransitionComponent={TransitionRight}
            ContentProps={{
                'aria-describedby': 'message-id',
            }}
            message={<span id="message-id" className={classes.messageSize}
            >{this.state.alertMessage}</span>}
            action={[
                <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    className={classes.close}
                    onClick={this.handleClose}
                >
                    <CloseIcon />
                </IconButton>,
            ]}
        />);
    }
}

CustomSnackbar.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CustomSnackbar);