import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    head: {
        backgroundColor: '#2f2179',
        color: 'white',
        borderRadius: '20px',
        "&:hover": {
            backgroundColor: '#4836a4',
        }
    },
    list: {
        paddingLeft: '70px',
        marginBottom: '40px'
    }
});

const myTheme = createTheme({
    overrides: {
        MuiTypography: {
            body1: {
                fontSize: "15px",
                lineHeight: "29px",
                fontFamily: "'Nunito',Helvetica,Arial,Lucida,sans-serif"
            }
        }
    }
});

class JobOpening extends React.Component {
    state = {
        openOne: true,
        openTwo: false,
    };

    handleClickOne = () => {
        this.setState(state => ({ openOne: !state.openOne }));
    };

    handleClickTwo = () => {
        this.setState(state => ({ openTwo: !state.openTwo }));
    };

    render() {
        const { classes } = this.props;

        return (
            <div>
                <MuiThemeProvider theme={myTheme}>
                    <List
                        component="nav"
                        className={classes.root}
                    >
                        <ListItem button className={classes.head} onClick={this.handleClickOne}>
                            <ListItemText inset primary="Job Role - Program Lead" />
                            {this.state.openOne ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={this.state.openOne} timeout="auto" unmountOnExit className={classes.list}>
                            <h3 className="text-left mt-20">
                                Job Description
                            </h3>
                            {/* <p > */}
                            <ul className="text-left">
                                <li>Provide fellow support, training and management of monitoring and evaluation of program.</li>
                                <li>Design and conduct open and safe spaces for fellows to replax and recharge and receive emotional support to ensure well being.</li>
                                <li>Provide program managers support, training and management of monitoring and evaluation.</li>
                                <li>Modify and revise the curriculums for better and updated learning spaces.</li>
                                <li>Design support and interventions/support in other fields of the program.</li>
                                <li>Analyze the data collected from the programs and design interventions according to the data.</li>
                            </ul>

                            <h3 className="text-left">
                                Required Skills
                            </h3>

                            <ul className="text-left">
                                <li>2 Years of Teaching/Teaching Fellowship experience.</li>
                                <li>Preferably 1-2 years of managerial experience in education sector.</li>
                                <li>Strong understanding of existing best pedagogical practices and different educational experiments happening around in the world.</li>
                                <li>Bachelor's or Master's in any field.</li>
                                <li> Effective communication and as we call it, obsessive communication.</li>
                                <li>Experience in driving operations in high pressure, goal driven environments.</li>
                                <li>Experience of having collaborated effectively with a broad range of individuals and groups and have a big sibling(brother/sister) temperament.</li>
                                <li>Experience and excitement to document learnings and goals.</li>
                            </ul>
                        </Collapse>
                    </List>

                    <List
                        component="nav"
                        className={classes.root}
                    >
                        <ListItem button className={classes.head} onClick={this.handleClickTwo}>
                            <ListItemText inset primary="Job Role - Human Resources Manager" />
                            {this.state.openTwo ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={this.state.openTwo} timeout="auto" unmountOnExit className={classes.list}>
                            <h3 className="text-left mt-20">
                                Job Description
                            </h3>

                            <ul className="text-left">
                                <li>Talent Hunt & Recruiting:</li>
                                <ul>
                                    <li>Finding right people,</li>
                                    <li>Interviewing team and fellows,</li>
                                    <li>Checking on CV and form application of team and media collaterals, presentations fellows, etc</li>
                                </ul>
                                <li>Grievance & Redressal:</li>
                                <ul>
                                    <li>solve problems in team or fellows or other stakeholders.</li>
                                </ul>
                                <li>Build and maintain the Org. Culture.</li>
                                <li>Orientation:</li>
                                <ul>
                                    <li>Orient the new staff, team or Volunteer.</li>
                                </ul>
                                <li>Preparing outreach collaterals:</li>
                                <ul>
                                    <li>Recruitment criteria,</li>
                                    <li>Job description,</li>
                                    <li>Reaching out to the people and different stakeholders.</li>
                                </ul>
                                <li>Preparing & updating MOUs, Contracts, Org. Policies, Org. Structure, etc.</li>
                                <li>Follow-up conversations and tasks with supporters related to donations, visits, reports etc</li>
                                <li>Assist in compilation of data:</li>
                                <ul>
                                    <li>Total students, fellows, etc.</li>
                                    <li>Staff personal information,</li>
                                    <li>Vacancies in the org., etc</li>
                                </ul>
                                <li>Work closely with the leadership team to manage documentation and communications in the organization.</li>
                                <li>Conducting regular Checkins with the team to track their growth and prepare support structure accordingly.</li>
                                <li>Take care of Reimbursements</li>
                            </ul>

                            <h3 className="text-left">
                                Required Skills
                            </h3>
                            <ul className="text-left">
                                <li>Basic understanding of google sheets/excel and other office tools and use of laptop</li>
                                <li>Demonstrated interest and understanding of writing reports, social media collaterals, presentations.</li>
                                <li>Passionate about data collection, documentation.</li>
                                <li>Able to write proficiently in one or more of the 3 languages - English, Hindi and Gujarati.</li>
                                <li>Preferably experience of working with Canva, Adobe or any other designing tools.</li>
                                <li>Strong & Effective Communication.</li>
                                <li>Good at Organising things.</li>
                            </ul>
                        </Collapse>
                    </List>
                </MuiThemeProvider>
            </div>
        );
    }
}

JobOpening.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(JobOpening);
