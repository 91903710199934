import React, { Component } from "react";
import Loader from "./Loader";
import TimelinePopup from "../Components/TimelinePopup";

const STORY = [
  {
    data: "August 2016",
    year: "August 2016 - February 2020",
    title: "Evening learning Centre, Udhana",
    description: "In the Udhana area, Asaadharan was running an evening learning centre from May 2016-February 2020.  For 4 years, we catered to approximately 500 children between 5-17 years of age and 15 teachers in the Udhana area.  What started as a small program of just 40 children under a bridge, quickly turned into a learning centre in a private school building that welcomed children from all castes and creeds, dropouts, children already attending school and out of school children.  The learning centre focused on core subjects like Maths, English, Hindi, Science, Geography and Art, but beyond that to ensure holistic child development we aimed to inculcate basic manners, etiquettes and values.  All learning was done through activity-based teaching techniques, contextual STORYs and peer learning.  Our unique token-based assessment technique always kept children attentive in class, motivated, excited and most importantly allowed them to learn to spend their resources (tokens in their case) wisely.",
    exp_teachers: 15 + "+",
    exp_students: 500 + "+",
    exp_subjects: "Hindi, English, Maths, Science, Geography, Arts, Values",
    exp_years: 4 + " Years"
  },
  {
    data: "June 2019",
    year: "June 2019",
    title: "Student Teacher Training Program (STTP), Pandesara",
    description: "In the Pandesara area, Asaadharan started a pilot programme in June 2019, Student Teachers Training Programme (STTP), with just one school with the permission of Nagar Prathmik Shikshan Samithi that had 1400 girls and just 8 teachers. It aimed at focusing on Grade 8 government school students who had an affinity towards teaching.  These students were imparted with life skills and values required to become a teacher.  This was done through a short training program with the aim to later utilise these skills to develop lesson plans for the existing curriculum of younger Grade 2 students.  This programme was also designed to improve the basic literacy and numeracy of the students that were trained to become teachers along with making the school system more sustainable through the development of student-leaders. The pilot ended in January 2020 giving us a lot of learnings for the future.",
    exp_teachers: 10 + "+",
    exp_students: 200 + "+",
    exp_subjects: "Hindi, Maths & Values",
    exp_years: 8 + " Months"
  },
  {
    data: "April 2020",
    year: "April 2020 - January 2021",
    title: "Accelerated Learning Program (ALP) & Teachers Training Program",
    description: "During the pandemic, we developed an Accelerated Learning Program (ALP) which aimed to focus on children between 8-14 years of age who are dropouts or never been to a school before to support them in reaching a Standard 4 level of Literacy and Numeracy .  The program focused on developing the basic foundation of children in English Language and Mathematics.  In view of this, we condensed the existing NCERT curriculum of Standards 1,2 & 3 into 14 months in line with the NCERT learning outcomes as a basis of assessing the growth of children. With the support of large organizations like Teach for India and Aavishkaar-Palampur we got the necessary support to develop a teachers training program to execute the ALP by providing necessary skills to teachers.  We then collaborated with the Sarva Shiksha Abhiyan (SSA), Surat, to execute our training program with 51 Balmitras/teachers who work specifically in the Special Training Program (STP) that reaches out to and educates dropout children in the major slum pockets of Surat.  In December 2020, we supported and trained the Balmitras in enhancing their pedagogical skills, building subject proficiency and application of subject specific TLM (Teaching Learning Material) using the ALP as a base.",
    exp_teachers: 51 + "+",
    exp_students: 700 + "+",
    exp_subjects: "English & Maths",
    exp_years: undefined
  },
  {
    data: "October 2020",
    year: "October 2020 - January 2021",
    title: "Cluster Program",
    description: "Given the current global pandemic situation, we used the learning techniques established from our Accelerated Learning Program (ALP) to reach out to children in-person that had no access to learning resources (TV, phones, radios, etc.).  The ALP curriculum supported the growth of the children in a time bound and accelerated manner through fun learning and activity-based techniques.  The Cluster Program for students commenced in November 2020 and ended in February 2021, given the gradual opening of schools and tuition centres all over the country.",
    exp_teachers: 4,
    exp_students: 40 + "+",
    exp_subjects: "Maths, English, Gujarati",
    exp_years: 4 + " Months"
  },
];

class OurStory extends Component {
  constructor(props) {
    super(props);
    this.timelinePopupRef = React.createRef();
    this.state = {
      curIdx: 0,
      prevIdx: -1,
      marginLeft: '0',
      loaderAction: true,
      year: '',
      title: '',
      description: '',
      exp_students: '',
      exp_subjects: '',
      exp_teachers: '',
      exp_years: '',
      bgcolor: '',
      borderColor: '',
      width: window.innerWidth,
      x1: '110',
      x2: '280',
      x3: '545',
      x4: '670',
      y1: '140',
      y2: '140',
      y3: '215',
      y4: '370',
      r1: '68',
      r2: '70',
      r3: '75',
      r4: '80'
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

  }


  componentDidMount() {
    window.scrollTo(0, 0);
    setTimeout(() => {
      this.setState({ loaderAction: false });
    }, 2000);

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });

    if (window.innerWidth <= '1199' && window.innerWidth >= '601') {

      this.setState({
        x1: '90',
        y1: '110',
        r1: '50',

        x2: '240',
        y2: '110',
        r2: '60',

        x3: '450',
        y3: '180',
        r3: '70',

        x4: '560',
        y4: '310',
        r4: '70'
      })
    }

    if (window.innerWidth <= '600' && window.innerWidth >= '426') {

      this.setState({
        x1: '40',
        y1: '50',
        r1: '30',

        x2: '120',
        y2: '60',
        r2: '30',

        x3: '250',
        y3: '90',
        r3: '35',

        x4: '300',
        y4: '160',
        r4: '35'
      })
    }

    if (window.innerWidth <= '425' && window.innerWidth >= '321') {

      this.setState({
        x1: '40',
        y1: '50',
        r1: '30',

        x2: '120',
        y2: '60',
        r2: '30',

        x3: '210',
        y3: '80',
        r3: '30',

        x4: '250',
        y4: '140',
        r4: '30'
      })
    }

      if (window.innerWidth <= '320' ) {

        this.setState({
          x1: '40',
          y1: '50',
          r1: '30',
  
          x2: '100',
          y2: '50',
          r2: '30',
  
          x3: '180',
          y3: '60',
          r3: '30',
  
          x4: '230',
          y4: '120',
          r4: '30'
        })
      }
  }

  handleClick = (year, title, description, exp_students, exp_subjects, exp_teachers, exp_years, bgcolor, borderColor) => {
    this.timelinePopupRef.current.handleClickOpen();
    this.setState({ year: year, title: title, description: description, exp_students: exp_students, exp_subjects: exp_subjects, exp_teachers: exp_teachers, exp_years: exp_years, bgcolor: bgcolor, borderColor: borderColor });
  };

  render() {
    return (
      <div className="full" id="about_foundation">
        {this.state.loaderAction ? <Loader /> : null}
        {/* <!-- Section Programs --> */}
        <section className="color-section white-bgcolor">
          <div className="container pt-150">
            <div className="col-lg-12">
              {/* <!-- Section heading --> */}
              <div className="section-heading flex-center">
                <img src="img/ourstory.jpg" alt="" className="img-responsive" />
              </div>
            </div>
            {/* 1 details left side*/}
            <div className="row">
              <div className="col-md-12 col-lg-12 mb-40">
                <p>We started under a bridge construction site in the Citylight area, after we noticed a few children notoriously wasting their time on a daily basis.  We started with the children of construction workers where we gave them basic knowledge of how to maintain hygiene, cross the road and manners.  We taught them at a BRTS bus stand for about 4 months as those children went back to their respective villages during the monsoon season.  We quickly realized that this was not the most sustainable technique for a greater goal.  In line with this, we started conducting research in various slum pockets all over Surat city, and we got the best response in the Udhana area. Where we started with our first ever Evening Learning Centre with 200 kids and 5 teachers.</p>
              </div>
              {/* <!-- main text --> */}

              <div className="col-md-12 col-lg-12">
                <div className="d-flex justify-content-center">
                  <div className="col-md-9 col-lg-9">
                    <img src="img/road_map.svg" alt="Timeline" className="img-responsive road-map" useMap="#timeline" width={825} height={600}></img>
                    <map name="timeline">
                      <area shape="circle" coords={this.state.x1 + ',' + this.state.y1 + ',' + this.state.r1} href="#" alt="" onClick={() => this.handleClick(STORY[0].year, STORY[0].title, STORY[0].description, STORY[0].exp_students, STORY[0].exp_subjects, STORY[0].exp_teachers, STORY[0].exp_years, 'rgb(253 204 224)', '#d12881')} />
                      <area shape="circle" coords={this.state.x2 + ',' + this.state.y2 + ',' + this.state.r2} href="#" alt="" onClick={() => this.handleClick(STORY[1].year, STORY[1].title, STORY[1].description, STORY[1].exp_students, STORY[1].exp_subjects, STORY[1].exp_teachers, STORY[1].exp_years, 'rgb(253 253 204)', 'rgb(226 205 0)')} />
                      <area shape="circle" coords={this.state.x3 + ',' + this.state.y3 + ',' + this.state.r3} href="#" alt="" onClick={() => this.handleClick(STORY[2].year, STORY[2].title, STORY[2].description, STORY[2].exp_students, STORY[2].exp_subjects, STORY[2].exp_teachers, STORY[2].exp_years, 'rgb(215 253 204)', 'rgb(50 124 66)')} />
                      <area shape="circle" coords={this.state.x4 + ',' + this.state.y4 + ',' + this.state.r4} href="#" alt="" onClick={() => this.handleClick(STORY[3].year, STORY[3].title, STORY[3].description, STORY[3].exp_students, STORY[3].exp_subjects, STORY[3].exp_teachers, STORY[3].exp_years, 'rgb(255 177 121)', '#c75900')} />
                    </map>
                  </div>
                </div>

                <TimelinePopup
                  ref={this.timelinePopupRef}
                  year={this.state.year}
                  title={this.state.title}
                  description={this.state.description}
                  exp_students={this.state.exp_students}
                  exp_subjects={this.state.exp_subjects}
                  exp_teachers={this.state.exp_teachers}
                  exp_years={this.state.exp_years}
                  bgcolor={this.state.bgcolor}
                  borderColor={this.state.borderColor}
                ></TimelinePopup>
              </div>
            </div>
          </div>
          {/* <!-- /container--> */}
        </section>
        {/* <!-- /Section ends --> */}
      </div>
    );
  }
}

export default OurStory;