import React, { Component } from "react";
import Loader from "../Components/Loader";
import TeamPopup from "../Components/TeamPopup";

class Advisors extends Component {
  constructor(props) {
    super(props);
    this.teamPopupRef = React.createRef();
    this.state = {
      name: "",
      post: "",
      content: "",
      bgcolor: "",
      loaderAction: true
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    setTimeout(() => {
      this.setState({ loaderAction: false });
    }, 2000)
  }
  handleClick = (name, post, content, bgcolor) => {
    this.teamPopupRef.current.handleClickOpen();
    this.setState({ name: name, post: post, content: content, bgcolor: bgcolor });
  };
  render() {
    return (
      <div>
        <div className="full" id="core_team">
          {/* <!-- Preloader --> */}
          {this.state.loaderAction ? <Loader /> : null}

          {/* <!-- Section Founder --> */}
          <section id="team" className="color-section white-bgcolor">
            <svg
              className="triangleShadow"
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              width="100%"
              height="100"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
            >
              <path className="trianglePath1" d="M0 0 L50 100 L100 0 Z" />
            </svg>

            <div className="container pt-75">
              <div className="col-lg-12">
                {/* <!-- Section heading --> */}
                <div className="section-heading flex-center">
                  <img src="img/ad1.jpg" alt="" className="img-responsive" />
                </div>
              </div>
              <TeamPopup
                ref={this.teamPopupRef}
                name={this.state.name}
                post={this.state.post}
                content={this.state.content}
                bgcolor={this.state.bgcolor}
              />

              <div className="row d-flex justify-content-center flex-wrap">
                <div
                  className="col-md-6 col-lg-4 mt-50 cursor-p"
                  onClick={() =>
                    this.handleClick(
                      "Dr. Yeap Ban Har",
                      "",
                      `<p>Dr. Yeap Ban Har is one of the world’s leading experts in
                    professional development for teachers in Singapore
                    Mathematics. He has presented many keynote lectures at
                    international education conferences and has given seminars
                    at various universities around the world.  An accomplished
                    and inspirational speaker, Dr. Yeap spent 10 years at the
                    National Institute of Education in Singapore where he taught
                    a range of teacher education courses, including graduate
                    courses, and was involved in several funded research
                    programmes in mathematics education.  He sat on the advisory
                    board of several schools in Singapore and Asia and was also
                    a consultant for World Bank projects.</p>
                    <p>
                    Dr. Yeap is also the consultant of the New Syllabus
                    Mathematics Series adopted by most secondary schools in
                    Singapore. He is also the consultant and author of Think!
                    Mathematics series for primary schools that has been adapted
                    into several languages and various education systems
                    including those in the UK and USA. He is Currently with
                    Pathlight School as its director of  curriculum and Teacher
                    Development and with Anglo Singapore International School,
                    holding a similar position.</p>
                    <p>
                    We are very glad to have an advisor like Dr. Yeap, he has
                    always been welcoming and very easy to talk to.  Even from
                    his busy schedule he always takes out time for us as and
                    when required to us and his insights have always been
                    helpful for our Teachers Training Program.</p>`,
                      "#f2dede"
                    )
                  }
                >
                  {/* <!-- Image --> */}
                  <img
                    src="img/pic7.jpg"
                    className="img-curved img-responsive"
                    alt=""
                  />
                  <h4 className="mt-5">Dr. Yeap Ban Har</h4>
                </div>

                <div
                  className="col-md-6 col-lg-4 mt-50 cursor-p"
                  onClick={() =>
                    this.handleClick(
                      "Madhukar Banuri",
                      "",
                      `<p>Madhukar is the founder and CEO of Leadership For Equity
                    (LFE), a systems change and research organisation that helps
                    strengthen the effectiveness of public school systems to
                    deliver quality education at scale. He has more than 11
                    years of experience in the education sector and had
                    previously worked with Pune City Connect and Teach For
                    India. Madhukar has an engineering degree from BITS Pilani.
                    He is an Ashoka Fellow, a 2015 Acumen India Fellow, and also
                    serves on the advisory board of Teach For India. He is our
                    GO-TO person for everything.</p>`,
                      "#dff0d8"
                    )
                  }
                >
                  {/* <!-- Image --> */}
                  <img
                    src="img/pic6.jpg"
                    className="img-curved img-responsive"
                    alt=""
                  />
                  <h4 className="mt-5">Madhukar Banuri</h4>
                </div>

                <div
                  className="col-md-6 col-lg-4 mt-50 cursor-p"
                  onClick={() =>
                    this.handleClick(
                      "Pratiksha Mishra",
                      "",
                      `<p>A teacher at heart, Pratiksha holds a triple major in
                  Journalism, Psychology and English Literature from Bangalore
                  University. Her passion for teaching and learning brought
                  her to Ahmedabad, where she started as a teaching Fellow at
                  Teach For India from 2016 to 2018. In these two years, she
                  taught foundational literacy and numeracy to 2nd and 3rd
                  graders and mentored them in projects like publishing a
                  newsletter. Post that, she joined the role of a Program
                  Manager at Teach For India-Ahmedabad (2018-2021) and coached
                  ~50 Fellows in their leadership journeys. She also took up
                  the role of an Instructional Trainer at Teach For India's
                  Summer Training Institute, facilitating learning on
                  classroom culture, pedagogy, SEL (Social-Emotional
                  Learning), and foundational literacy for the incoming
                  cohort.</p>`,
                      "#d9edf7"
                    )
                  }
                >
                  {/* <!-- Image --> */}
                  <img
                    src="img/p-re.jpg"
                    className="img-curved img-responsive"
                    alt=""
                  />
                  <h4 className="mt-5">Pratiksha Mishra</h4>
                </div>

                {/* <div className="col-lg-12 col-md-12 d-flex justify-content-center"> */}
                  <div
                    className="col-md-6 col-lg-4 mt-50 cursor-p"
                    onClick={() =>
                      this.handleClick(
                        "Jaysukh Kathiriya",
                        "",
                        `<p>Jaysukh Kathiriya is Managing Director (MD) of several
                  affordable private schools (APS) in Surat, he is supporting
                  low-income families’ children with an excellent education
                  through his various educational institutions.  He is the MD
                  of three major APS schools in the Udhana area, a large
                  urban-slum pocket in Surat city-Sungrace, Sunlight and
                  Sunrays schools. He also serves as the Managing Director
                  (MD) of Leo group of educational institutions, which provide
                  after school support in the form of coaching classes to
                  several young students. He also serves as a Trustee for
                  Saurashtra Patel Seva Samaj and Jai Jawan Nagarik Samiti
                  Parmveer Shahid Sahay Trust.  In addition to this, he is a
                  motivational speaker and was also the Past Chairman for Self
                  Finance School Management Association.</p>`,
                        "#fcf8e3"
                      )
                    }
                  >
                    {/* <!-- Image --> */}
                    <img
                      src="img/pic4.jpg"
                      className="img-curved img-responsive"
                      alt=""
                    />
                    <h4 className="mt-5">Jaysukh Kathiriya</h4>
                  </div>

                  <div
                    className="col-md-6 col-lg-4 mt-50 cursor-p"
                    onClick={() =>
                      this.handleClick(
                        "Sandhya Gupta",
                        "",
                        `<p>Founder of Aavishkar Palampur, Himachal Pradesh.  A leader
                  with science and mathematics in her veins. She believes
                  science and mathematics to be the fundamental tools to
                  understand and formulate the world around us. To foster the
                  same interest and love for these subjects, she began
                  Aavishkaar. A hands-on experiential mathematics and science
                  program for the underprivileged. Their unique and innovative
                  modules, get children to truly engage with the subject
                  matter. <p/><p>She is an amazing teacher trainer who encourages
                  teachers to facilitate discussions, make lesson plans and
                  explore solutions together with students. Our Founders have
                  also attended her Primary Math training sessions and were
                  mesmerized by the techniques and methods taught by her.  She
                  is also our amazing Advisor and has always guided us in the
                  right direction. She is an Alumni of TFIx Cohort 2017 and
                  since then she has been running a very successful fellowship
                  program.</p>`,
                        "#d9edf7"
                      )
                    }
                  >
                    {/* <!-- Image --> */}
                    <img
                      src="img/sg-edited.jpg"
                      className="img-curved img-responsive"
                      alt=""
                    />
                    <h4 className="mt-5">Sandhya Gupta</h4>
                  </div>
                </div>
              </div>
            {/* </div> */}
          </section>
        </div>
      </div>
    );
  }
}

export default Advisors;
