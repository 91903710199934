import React, { Component } from "react";
import CustomSnackbar from "../Components/CustomSnackbar";
import Loader from "../Components/Loader";
import { setVolunteerForm } from "../Sevices/service";

class FellowQuestions extends Component {
    constructor() {
        super();
        this.myRef = React.createRef();

        this.state = {
            fields: {
                full_name: "",
                mobile_number: undefined,
                email: undefined,
                age: undefined,
                days: "",
                areas: [],
                allAreas: [],
                // areasOther: undefined,
                // commitment: "",
                // commitmentOther: undefined,
                // timing: "",
                // timingOther: undefined,
                hear: "",
                hearOther: undefined,
                // ambassador: "",
                intrested: "",
                college: undefined
            },
            errors: {},
            loaderAction: true,
            isChecked: true,
            isAllEvent: null,
            isCheckAll: false,
            links: []
        }
    }

    componentDidMount() {
        this._isMounted = true;

        window.scrollTo(0, 0);
        setTimeout(() => {
            this.setState({ loaderAction: false });
        }, 2500);
    }

    handleChange = (e) => {

        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields
        });
        console.log('e.target.days', e.target.value);


        if (e.target.value === "Weekends") {
            this.setState({ isAllEvent: false });
        }
        else if (e.target.value === "AllDays") {
            //alert(1)
            this.setState({ isAllEvent: true });
        }
        else if (e.target.value === "Weekdays") {
            this.setState({ isAllEvent: null });
        }

        this.setState({ links: [] });

        // if (e.target.name === "timingOther") {
        //     fields["timing"] = e.target.value;
        //     this.setState({ fields });
        // }

        // if (e.target.name === "commitmentOther") {
        //     fields["commitment"] = e.target.value;
        //     this.setState({ fields });
        // }

        if (e.target.name === "hearOther") {
            fields["hear"] = e.target.value;
            this.setState({ fields });
        }
    }

    handleInputChange = (event) => {
        const target = event.target;
        var value = target.value;
        var name = target.name;
        let fields = this.state.fields;
        let links = this.state.links;
        let res = '';


        if (value === 'Field work in Marketing') {
            res = 'https://chat.whatsapp.com/E7yiZ8aaRp06zASgtN4ggl'
        }
        if (value === 'Translation from English/ Hindi to Gujarati or Visa Versa') {
            res = 'https://chat.whatsapp.com/JEinEhbiXqo2RpJ1v9PKha'
        }
        if (value === ' Reading Campaign') {
            res = 'https://chat.whatsapp.com/JkCTZ5TQ4ztBna8RQNfRZ6'
        }
        if (value === 'Working with Asaadharan Fellow in Government School') {
            res = 'https://chat.whatsapp.com/BJek5YnqezgEkTGpGt9jqk'
        }

        //alert(res)

        if (target.checked) {
            fields[name] = [...this.state.fields[name], value]
            this.setState({ fields });

            if (value === target.value) {
                links = [...this.state.links, res];
                this.setState({ links });
            }
        } else {
            const index = fields[name].indexOf(value)
            fields[name].splice(index, 1);
            links.splice(index, 1);
            // console.log(index);
            this.setState({ links });
        }

    }

    toggle = () => {
        this.setState({ isChecked: !this.state.isChecked });
    }

    validateForm = () => {

        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        if (typeof fields["full_name"] !== "undefined") {
            if (!fields["full_name"].match(/^[a-zA-Z ]*$/)) {
                formIsValid = false;
                errors["full_name"] = "*Please enter alphabet characters only.";
            }
        }

        if (typeof fields["age"] !== "undefined") {
            if (!fields["age"].match(/^(0?[1-9]|[1-9][0-9]|[1][1-9][1-9]|200)$/)) {
                formIsValid = false;
                errors["age"] = "*Please enter valid Age.";
            }
        }

        if (typeof fields["mobile_number"] !== "undefined") {
            if (!fields["mobile_number"].match(/^[0-9]{10}$/)) {
                formIsValid = false;
                errors["mobile_number"] = "*Please enter valid mobile no.";
            }
        }

        if (typeof fields["email"] !== "undefined") {
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(fields["email"])) {
                formIsValid = false;
                errors["email"] = "*Please enter valid email-ID.";
            }
        }

        if (typeof fields["college"] !== "undefined") {
            if (!fields["college"].match(/^[a-zA-Z ]*$/)) {
                formIsValid = false;
                errors["college"] = "*Please enter current College / School Name.";
            }
        }

        // if (fields["areasOther"] !== undefined || fields["areasOther"] !== "") {
        //     if(!fields["areas"].includes(this.state.fields.areasOther)) {
        //         fields["areas"] = [...this.state.fields["areas"], this.state.fields.areasOther]
        //         this.setState({ fields });
        //     }
        // }

        this.setState({
            errors: errors
        });
        return formIsValid;

    }

    submitVolunteerForm = (e) => {
        e.preventDefault();

        if (this.validateForm()) {
            let fields = {};
            fields["full_name"] = "";
            fields["mobile_number"] = "";
            fields["age"] = "";
            fields["email"] = "";
            fields["days"] = "";
            fields["areas"] = "";
            fields["allAreas"] = "";
            // fields["commitment"] = "";
            // fields["commitmentOther"] = "";
            // fields["timing"] = "";
            // fields["timingOther"] = "";
            fields["hear"] = "";
            fields["hearOther"] = "";
            // fields["ambassador"] = "";
            fields["intrested"] = "";
            fields["college"] = "";

            const formData = new FormData();

            formData.append('name', this.state.fields.full_name);
            formData.append('mobile_number', this.state.fields.mobile_number);
            formData.append('age', this.state.fields.age);
            formData.append('email', this.state.fields.email);
            formData.append('prefer_days', this.state.fields.days);
            formData.append('areas', this.state.isAllEvent ? this.state.fields.allAreas.join() : this.state.fields.areas.join());
            // formData.append('time_commitment', this.state.fields.commitment);
            // formData.append('prefer_timing', this.state.fields.timing);
            formData.append('about_asadharan', this.state.fields.hear);
            // formData.append('ambassador', this.state.fields.ambassador);
            formData.append('intrested', this.state.fields.intrested === "Yes" ? "1" : "0");
            formData.append('education', this.state.fields.college);


            for (var pair of formData.entries()) {
                console.log(pair[0] + ', ' + pair[1]);
                console.log('log', this.state.isAllEvent, this.state.fields.areas.join(), this.state.fields.allAreas.join());
            }
            setVolunteerForm(formData)
                .then(response => {
                    if (response.data.errors) {
                        this.setState({ open: true, alertMessage: response.data.errors.name ? response.data.errors.name : "" || response.data.errors.email ? response.data.errors.email : "" || response.data.errors.mobile_number ? response.data.errors.mobile_number : "" });
                    }
                    else if (response.data.message) {
                        this.setState({ open: true, alertMessage: response.data.message === "Data Save successfully." ? "Message successfully sent" : "Something went wrong" });
                        this.setState({ fields: fields });
                    }

                    this.triggerChildAlert();
                })
                .catch(error => {
                    // alert("Somthing Went wrong. Please try again");

                    this.setState({ open: true, alertMessage: "Somthing Went wrong. Please try again" });
                    this.triggerChildAlert();
                })
        }

    }

    triggerChildAlert() {
        this.myRef.current.handleClick(this.state.open, this.state.alertMessage);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const isAllEvent = this.state.isAllEvent;
        console.log('links', this.state.links);
        return (
            <div className="full" id="join_us">
                {this.state.loaderAction ? <Loader /> : null}
                <CustomSnackbar ref={this.myRef} />

                {/* <!-- Section Programs --> */}
                <section className="color-section white-bgcolor">
                    <div className="container pt-150">
                        <div className="col-lg-12">
                            <div className="section-heading flex-center">
                                <img src="img/volunteer.jpg" alt="" className="img-responsive" />
                            </div>
                            <img src="img/boy.png" alt="" className="img-responsive mt-50 boy-icon" />
                            <img src="img/girl.png" alt="" className="img-responsive mt-50 girl-icon" />
                        </div>
                        <div className="col-lg-12 form-border">
                            <div className="row pad-vh">
                                <form method="post" name="userVolunteerForm" onSubmit={this.submitVolunteerForm}>
                                    {/* <!-- Form Starts --> */}
                                    <div id="fellow_form" className="row">
                                        <div className="row">
                                            <div className="form-group col-lg-6">
                                                <label>1. EmailID *</label>

                                                <input
                                                    type="email"
                                                    name="email"
                                                    className="form-control input-field"
                                                    placeholder="Enter Email"
                                                    value={this.state.fields.email || ""}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                                <div className="errorMsg">{this.state.errors.email}</div>
                                            </div>
                                            <div className="form-group col-lg-6">
                                                <label htmlFor="name">2. Full Name *</label>
                                                <input
                                                    type="text"
                                                    name="full_name"
                                                    id="name"
                                                    className="form-control input-field"
                                                    placeholder="Enter Full Name"
                                                    value={this.state.fields.full_name || ""}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                                <div className="errorMsg">{this.state.errors.full_name || ""}</div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-lg-6">
                                                <label htmlFor="number">3. Contact Number *</label>

                                                <input
                                                    type="number"
                                                    name="mobile_number"
                                                    id="number"
                                                    className="form-control input-field"
                                                    placeholder="Enter Contact Number"
                                                    value={this.state.fields.mobile_number || ""}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                                <div className="errorMsg">{this.state.errors.mobile_number}</div>
                                            </div>
                                            <div className="form-group col-lg-6">
                                                <label>4. Age *</label>

                                                <input
                                                    type="number"
                                                    name="age"
                                                    className="form-control input-field"
                                                    placeholder="Enter Age"
                                                    value={this.state.fields.age || ""}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                                <div className="errorMsg">{this.state.errors.age}</div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            {/* <div className="form-group col-lg-6">
                                                <label>1. EmailID *</label>

                                                <input
                                                    type="email"
                                                    name="email"
                                                    className="form-control input-field"
                                                    placeholder="Enter Email"
                                                    value={this.state.fields.email || ""}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                                <div className="errorMsg">{this.state.errors.email}</div>
                                            </div> */}
                                            <div className="form-group col-lg-6">
                                                <label htmlFor="college">5. Current College/school *</label>
                                                <input
                                                    type="text"
                                                    name="college"
                                                    id="college"
                                                    className="form-control input-field"
                                                    placeholder="Enter Full Name"
                                                    value={this.state.fields.college || ""}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                                <div className="errorMsg">{this.state.errors.college || ""}</div>
                                            </div>
                                            <div className="form-group col-lg-6">
                                                <label>6. Are you interested in being our fellow? (it's a full-time commitment job)? * </label>
                                                <ul className="radio-btn">
                                                    <li className="col-lg-6">
                                                        <label className="container-cust text-left">
                                                            Yes
                                                            <input type="radio" checked={this.state.fields.intrested === "Yes"} name="intrested" value="Yes" onChange={this.handleChange}
                                                            />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </li>
                                                    <li className="col-lg-6">
                                                        <label className="container-cust text-left">
                                                            No
                                                            <input type="radio" checked={this.state.fields.intrested === "No"} name="intrested" value="No" onChange={this.handleChange}
                                                            />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="form-group col-lg-6">
                                                <label>7. Where did you hear about Asaadharan Foundation? *</label>
                                                <ul className="radio-btn">
                                                    <li className="col-lg-6">
                                                        <label className="container-cust text-left">
                                                            Friends & Colleagues
                                                            <input type="radio" checked={this.state.fields.hear === "Friends & Colleagues"} name="hear" value="Friends & Colleagues" onChange={this.handleChange} />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </li>
                                                    <li className="col-lg-6">
                                                        <label className="container-cust text-left">
                                                            Asaadharan team
                                                            <input type="radio" checked={this.state.fields.hear === "Asaadharan team"} name="hear" value="Asaadharan team" onChange={this.handleChange} />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </li>
                                                    <li className="col-lg-6">
                                                        <label className="container-cust text-left">
                                                            Facebook
                                                            <input type="radio" checked={this.state.fields.hear === "Facebook"} name="hear" value="Facebook" onChange={this.handleChange} />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </li>
                                                    <li className="col-lg-6">
                                                        <label className="container-cust text-left">
                                                            Instagram
                                                            <input type="radio" checked={this.state.fields.hear === "Instagram"} name="hear" value="Instagram" onChange={this.handleChange} />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </li>
                                                    <li className="col-lg-6">
                                                        <label className="container-cust text-left">
                                                            Word of Mouth
                                                            <input type="radio" checked={this.state.fields.hear === "Word of Mouth"} name="hear" value="Word of Mouth" onChange={this.handleChange} />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </li>
                                                    <li className="col-lg-6">
                                                        <label className="container-cust text-left">
                                                            Website
                                                            <input type="radio" checked={this.state.fields.hear === "Website"} name="hear" value="Website" onChange={this.handleChange} />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </li>
                                                    {/* <li className="col-lg-6">
                                                        <label className="container-cust text-left">
                                                            Campus Ambasador
                                                            <input type="radio" checked={this.state.fields.hear === "Campus Ambasador"} name="hear" value="Campus Ambasador" onChange={this.handleChange} />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </li> */}
                                                    <li className="col-lg-6">

                                                        <label className="container-cust text-left">
                                                            <textarea
                                                                type="text"
                                                                name="hearOther"
                                                                className="form-control input-field"
                                                                placeholder="Other"
                                                                value={this.state.fields.hearOther || ""}
                                                                onChange={this.handleChange}
                                                                rows={1}
                                                            />

                                                            <input type="radio" checked={this.state.fields.hear === this.state.fields.hearOther} name="timing" value={this.state.fields.hearOther} onChange={this.handleChange}
                                                            />
                                                            <span className="checkmark"></span>

                                                        </label>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="form-group col-lg-6">
                                                <label>8. Preferred Days? * </label>
                                                {/* <div className="text-left"><span >We currently do not work with children on Sundays</span></div> */}

                                                <ul className="radio-btn">
                                                    <li className="col-lg-6">
                                                        <label className="container-cust text-left">
                                                            Weekdays
                                                            <input type="radio" checked={this.state.fields.days === "Weekdays"} name="days" value="Weekdays" onChange={this.handleChange}
                                                            />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </li>
                                                    <li className="col-lg-6">
                                                        <label className="container-cust text-left">
                                                            Weekends
                                                            <input type="radio" checked={this.state.fields.days === "Weekends"} name="days" value="Weekends" onChange={this.handleChange}
                                                            />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </li>
                                                    <li className="col-lg-6">
                                                        <label className="container-cust text-left">
                                                            All Days
                                                            <input type="radio" checked={this.state.fields.days === "AllDays"} name="days" value="AllDays" onChange={this.handleChange}
                                                            />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </li>
                                                </ul>
                                                {/* <div className="form-group row mt-20">
                                                    <div className="form-check form-check-inline chk col-md-6" style={{ 'display': 'flex', 'flexDirection': 'row' }} onClick={() => this.toggle()}>
                                                        <input className="form-check-input" checked={this.state.fields.days.includes("Monday")} id="inlineCheckboxh1" type="checkbox" name="days" value="Monday" onChange={this.handleInputChange} />
                                                        <label className="form-check-label" htmlFor="inlineCheckboxh1" style={{ 'margin': '0' }}>Monday</label>
                                                    </div>
                                                    <div className="form-check form-check-inline chk col-md-6" style={{ 'display': 'flex', 'flexDirection': 'row' }} onClick={() => this.toggle()}>
                                                        <input className="form-check-input" checked={this.state.fields.days.includes("Tuesday")} type="checkbox" name="days" id="inlineCheckboxh2" value="Tuesday" onChange={this.handleInputChange} />
                                                        <label className="form-check-label" htmlFor="inlineCheckboxh2" style={{ 'margin': '0' }}>Tuesday</label>
                                                    </div>
                                                    <div className="form-check form-check-inline chk col-md-6" style={{ 'display': 'flex', 'flexDirection': 'row' }} onClick={() => this.toggle()}>
                                                        <input className="form-check-input" checked={this.state.fields.days.includes("Wednesday")} type="checkbox" name="days" id="inlineCheckboxh3" value="Wednesday" onChange={this.handleInputChange} />
                                                        <label className="form-check-label" htmlFor="inlineCheckboxh3" style={{ 'margin': '0' }}>Wednesday</label>
                                                    </div>
                                                    <div className="form-check form-check-inline chk col-md-6" style={{ 'display': 'flex', 'flexDirection': 'row' }} onClick={() => this.toggle()}>
                                                        <input className="form-check-input" checked={this.state.fields.days.includes("Thursday")} type="checkbox" name="days" id="inlineCheckboxh4" value="Thursday" onChange={this.handleInputChange} />
                                                        <label className="form-check-label" htmlFor="inlineCheckboxh4" style={{ 'margin': '0' }}>Thursday</label>
                                                    </div>
                                                    <div className="form-check form-check-inline chk col-md-6" style={{ 'display': 'flex', 'flexDirection': 'row' }} onClick={() => this.toggle()}>
                                                        <input className="form-check-input" checked={this.state.fields.days.includes("Friday")} type="checkbox" name="days" id="inlineCheckboxh5" value="Friday" onChange={this.handleInputChange} />
                                                        <label className="form-check-label" htmlFor="inlineCheckboxh5" style={{ 'margin': '0' }}>Friday</label>
                                                    </div>
                                                    <div className="form-check form-check-inline chk col-md-6" style={{ 'display': 'flex', 'flexDirection': 'row' }} onClick={() => this.toggle()}>
                                                        <input className="form-check-input" checked={this.state.fields.days.includes("Saturday")} type="checkbox" name="days" id="inlineCheckboxh6" value="Saturday" onChange={this.handleInputChange} />
                                                        <label className="form-check-label" htmlFor="inlineCheckboxh6" style={{ 'margin': '0' }}>Saturday</label>
                                                    </div>
                                                    <div className="form-check form-check-inline chk col-md-6" style={{ 'display': 'flex', 'flexDirection': 'row' }} onClick={() => this.toggle()}>
                                                        <input className="form-check-input" checked={this.state.fields.days.includes("Sunday")} type="checkbox" name="days" id="inlineCheckboxh7" value="Sunday" onChange={this.handleInputChange} />
                                                        <label className="form-check-label" htmlFor="inlineCheckboxh7" style={{ 'margin': '0' }}>Sunday</label>
                                                    </div>
                                                </div> */}
                                            </div>
                                            {/* <div className="form-group col-lg-6">
                                                <label>7. Preferred Timings for Volunteering? * </label>
                                                <ul className="radio-btn">
                                                    <li className="col-lg-12">
                                                        <label className="container-cust text-left">
                                                            Morning: between 7:30am to 12:30pm
                                                            <input type="radio" checked={this.state.fields.timing === "Morning: between 7:30am to 12:30pm"} name="timing" value="Morning: between 7:30am to 12:30pm" onChange={this.handleChange}
                                                            />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </li>
                                                    <li className="col-lg-12">
                                                        <label className="container-cust text-left">
                                                            Afternoon: between 12:00pm to 5:30pm
                                                            <input type="radio" checked={this.state.fields.timing === "12:00pm - 5:30pm"} name="timing" value="12:00pm - 5:30pm" onChange={this.handleChange}
                                                            />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </li>
                                                    <li className="col-lg-12">
                                                        <label className="container-cust text-left">
                                                            Evening: between 4:00pm to 6:00pm
                                                            <input type="radio" checked={this.state.fields.timing === "4:00pm - 6:00pm"} name="timing" value="4:00pm - 6:00pm" onChange={this.handleChange}
                                                            />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </li>
                                                    <li className="col-lg-12">
                                                        <label className="container-cust text-left">
                                                            Late Evening: 6:00pm onwards
                                                            <input type="radio" checked={this.state.fields.timing === "6:00pm"} name="timing" value="6:00pm" onChange={this.handleChange}
                                                            />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </li>
                                                    <li className="col-lg-6">
                                                        <label className="container-cust text-left">
                                                            <textarea
                                                                type="text"
                                                                name="timingOther"
                                                                className="form-control input-field"
                                                                placeholder="Other"
                                                                value={this.state.fields.timingOther}
                                                                onChange={this.handleChange}
                                                                rows={1}
                                                            />

                                                            <input type="radio" checked={this.state.fields.timing === this.state.fields.timingOther} name="timing" value={this.state.fields.timingOther} onChange={this.handleChange}
                                                            />
                                                            <span className="checkmark"></span>

                                                        </label>
                                                    </li>
                                                </ul>
                                            </div> */}
                                        </div>
                                        {/* <div className="row">
                                            <div className="form-group col-lg-6">
                                                <label>8. Time Commitment? * </label>
                                                <ul className="radio-btn">
                                                    <li className="col-lg-6">
                                                        <label className="container-cust text-left">
                                                            As required by the program
                                                            <input type="radio" checked={this.state.fields.commitment === "As required by the program"} name="commitment" value="As required by the program" onChange={this.handleChange}
                                                            />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </li>
                                                    <li className="col-lg-6">
                                                        <label className="container-cust text-left">
                                                            3 Months
                                                            <input type="radio" checked={this.state.fields.commitment === "3 Months"} name="commitment" value="3 Months" onChange={this.handleChange}
                                                            />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </li>
                                                    <li className="col-lg-6">
                                                        <label className="container-cust text-left">
                                                            6 months
                                                            <input type="radio" checked={this.state.fields.commitment === "6 Months"} name="commitment" value="6 Months" onChange={this.handleChange}
                                                            />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </li>
                                                    <li className="col-lg-6">

                                                        <label className="container-cust text-left">
                                                            <textarea
                                                                type="text"
                                                                name="commitmentOther"
                                                                className="form-control input-field"
                                                                placeholder="Other"
                                                                value={this.state.fields.commitmentOther || ""}
                                                                onChange={this.handleChange}
                                                                rows={1}
                                                            />

                                                            <input type="radio" checked={this.state.fields.commitment === this.state.fields.commitmentOther} name="timing" value={this.state.fields.commitmentOther} onChange={this.handleChange}
                                                            />
                                                            <span className="checkmark"></span>

                                                        </label>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div> */}
                                        {this.state.fields.days !== "" ?
                                            <div className="row">
                                                {isAllEvent === false ?

                                                    <div className="form-group col-lg-12">
                                                        <label>9. Which areas of volunteering appeal to you? *</label>
                                                        <div className="form-group row mt-20">
                                                            {/* <div className="form-check form-check-inline chk col-md-6" style={{ 'display': 'flex', 'flexDirection': 'row' }} onClick={() => this.toggle()}>
                                                        <input className="form-check-input" checked={ this.state.fields.areas.includes("Fundraising")} id="inlineCheckboxh12" type="checkbox" name="areas" value="Fundraising" onChange={this.handleInputChange} />
                                                        <label className="form-check-label" htmlFor="inlineCheckboxh12" style={{ 'margin': '0' }}>Fundraising</label>
                                                    </div> */}
                                                            {/* <div className="form-check form-check-inline chk col-md-6" style={{ 'display': 'flex', 'flexDirection': 'row' }} onClick={() => this.toggle()}>
                                                        <input className="form-check-input" checked={this.state.fields.areas.includes("Report Writing & Newsletter Writing")} id="inlineCheckboxh16" type="checkbox" name="areas" value="Report Writing & Newsletter Writing" onChange={this.handleInputChange} />
                                                        <label className="form-check-label" htmlFor="inlineCheckboxh16" style={{ 'margin': '0' }}>Report Writing & Newsletter Writing</label>
                                                    </div> */}
                                                            <div className="form-check form-check-inline chk col-md-6" style={{ 'display': 'flex', 'flexDirection': 'row' }} onClick={() => this.toggle()}>
                                                                <input className="form-check-input" checked={this.state.fields.areas.includes(" Reading Campaign")} id="inlineCheckboxh17" type="checkbox" name="areas" value=" Reading Campaign" onChange={this.handleInputChange} />
                                                                <label className="form-check-label" htmlFor="inlineCheckboxh17" style={{ 'margin': '0' }}> Reading Campaign  (Telling stories to children and doing some activity with them.)</label>
                                                            </div>
                                                            <div className="form-check form-check-inline chk col-md-6" style={{ 'display': 'flex', 'flexDirection': 'row' }} onClick={() => this.toggle()}>
                                                                <input className="form-check-input" checked={this.state.fields.allAreas.includes("Translation from English/ Hindi to Gujarati or Visa Versa")} id="inlineCheckboxh19" type="checkbox" name="allAreas" value="Translation from English/ Hindi to Gujarati or Visa Versa" onChange={this.handleInputChange} />
                                                                <label className="form-check-label" htmlFor="inlineCheckboxh19" style={{ 'margin': '0' }}>Translation from English/ Hindi to Gujarati or Visa Versa</label>
                                                            </div>
                                                            {/* <div className="col-lg-6">
                                                        <input
                                                            type="text"
                                                            name="areasOther"
                                                            className="form-control input-field"
                                                            placeholder="Other"
                                                            value={this.state.fields.areasOther}
                                                            onChange={this.handleChange}
                                                        />
                                                    </div> */}
                                                        </div>
                                                    </div> : this.state.isAllEvent === true ?
                                                        <div className="form-group col-lg-12">
                                                            <label>9. Which areas of volunteering appeal to you? *</label>
                                                            <div className="form-group row">
                                                                <div className="form-check form-check-inline chk col-md-6" onClick={() => this.toggle()}>
                                                                    <input className="form-check-input" checked={this.state.fields.allAreas.includes("Working with Asaadharan Fellow in Government School")} id="inlineCheckboxh8" type="checkbox" name="allAreas" value="Working with Asaadharan Fellow in Government School" onChange={this.handleInputChange} />
                                                                    <label className="form-check-label" htmlFor="inlineCheckboxh8" style={{ 'margin': '0' }}>Working with Asaadharan Fellow in Government School (Timings would be between 8:00am to 12:00am or 1:00pm to 5:00pm)</label>
                                                                </div>
                                                                <div className="form-check form-check-inline chk col-md-6" onClick={() => this.toggle()}>
                                                                    <input className="form-check-input" checked={this.state.fields.allAreas.includes(" Reading Campaign")} id="inlineCheckboxh17" type="checkbox" name="allAreas" value=" Reading Campaign" onChange={this.handleInputChange} />
                                                                    <label className="form-check-label" htmlFor="inlineCheckboxh17" style={{ 'margin': '0' }}> Reading Campaign(Telling stories to children and doing some activity with them(only on weekends)</label>
                                                                </div>
                                                                <div className="form-check form-check-inline chk col-md-6" onClick={() => this.toggle()}>
                                                                    <input className="form-check-input" checked={this.state.fields.allAreas.includes("Translation from English/ Hindi to Gujarati or Visa Versa")} id="inlineCheckboxh19" type="checkbox" name="allAreas" value="Translation from English/ Hindi to Gujarati or Visa Versa" onChange={this.handleInputChange} />
                                                                    <label className="form-check-label" htmlFor="inlineCheckboxh19" style={{ 'margin': '0' }}>Translation from English/ Hindi to Gujarati or Visa Versa</label>
                                                                </div>
                                                                <div className="form-check form-check-inline chk col-md-6" onClick={() => this.toggle()}>
                                                                    <input className="form-check-input" checked={this.state.fields.allAreas.includes("Field work in Marketing")} id="inlineCheckboxh14" type="checkbox" name="allAreas" value="Field work in Marketing" onChange={this.handleInputChange} />
                                                                    <label className="form-check-label" htmlFor="inlineCheckboxh14" style={{ 'margin': '0' }}>Field work in Marketing</label>
                                                                </div>


                                                            </div>
                                                        </div> :
                                                        <div className="form-group col-lg-12">
                                                            <label>9. Which areas of volunteering appeal to you? *</label>
                                                            <div className="form-group row mt-20">
                                                                <div className="form-check form-check-inline chk col-md-6" style={{ 'display': 'flex', 'flexDirection': 'row' }} onClick={() => this.toggle()}>
                                                                    <input className="form-check-input" checked={this.state.fields.allAreas.includes("Working with Asaadharan Fellow in Government School")} id="inlineCheckboxh8" type="checkbox" name="allAreas" value="Working with Asaadharan Fellow in Government School" onChange={this.handleInputChange} />
                                                                    <label className="form-check-label" htmlFor="inlineCheckboxh8" style={{ 'margin': '0' }}>Working with Asaadharan Fellow in Government School (Timings would be between 8:00am to 12:00am or 1:00pm to 5:00pm)</label>
                                                                </div>
                                                                {/* <div className="form-check form-check-inline chk col-md-6" style={{ 'display': 'flex', 'flexDirection': 'row' }} onClick={() => this.toggle()}>
                                                  <input className="form-check-input" checked={this.state.fields.allAreas.includes("Helping in making Teaching Learning Materials")} id="inlineCheckboxh9" type="checkbox" name="allAreas" value="Helping in making Teaching Learning Materials" onChange={this.handleInputChange} />
                                                  <label className="form-check-label" htmlFor="inlineCheckboxh9" style={{ 'margin': '0' }}>Helping in making Teaching Learning Materials</label>
                                              </div> */}
                                                                {/* <div className="form-check form-check-inline chk col-md-6" style={{ 'display': 'flex', 'flexDirection': 'row' }} onClick={() => this.toggle()}>
                                                  <input className="form-check-input" checked={this.state.fields.allAreas.includes("Mentoring for Buddy Project (requires commitment of at least 12 - 10 months)")} id="inlineCheckboxh10" type="checkbox" name="allAreas" value="Mentoring for Buddy Project (requires commitment of at least 12 - 10 months)" onChange={this.handleInputChange} />
                                                  <label className="form-check-label" htmlFor="inlineCheckboxh10" style={{ 'margin': '0' }}>Mentoring for Buddy Project (requires commitment of at least 12 - 10 months)</label>
                                              </div> */}
                                                                {/* <div className="form-check form-check-inline chk col-md-6" style={{ 'display': 'flex', 'flexDirection': 'row' }} onClick={() => this.toggle()}>
                                                  <input className="form-check-input" checked={this.state.fields.allAreas.includes("Fundraising")} id="inlineCheckboxh12" type="checkbox" name="allAreas" value="Fundraising" onChange={this.handleInputChange} />
                                                  <label className="form-check-label" htmlFor="inlineCheckboxh12" style={{ 'margin': '0' }}>Fundraising</label>
                                              </div>
                                              <div className="form-check form-check-inline chk col-md-6" style={{ 'display': 'flex', 'flexDirection': 'row' }} onClick={() => this.toggle()}>
                                                  <input className="form-check-input" checked={this.state.fields.allAreas.includes("Photography & Videography for social media")} id="inlineCheckboxh13" type="checkbox" name="allAreas" value="Photography & Videography for social media" onChange={this.handleInputChange} />
                                                  <label className="form-check-label" htmlFor="inlineCheckboxh13" style={{ 'margin': '0' }}>Photography & Videography for social media</label>
                                              </div> */}
                                                                {/* <div className="form-check form-check-inline chk col-md-6" style={{ 'display': 'flex', 'flexDirection': 'row' }} onClick={() => this.toggle()}>
                                                  <input className="form-check-input" checked={this.state.fields.allAreas.includes("Marketing strategy (Social Media)")} id="inlineCheckboxh14" type="checkbox" name="allAreas" value="Marketing strategy (Social Media)" onChange={this.handleInputChange} />
                                                  <label className="form-check-label" htmlFor="inlineCheckboxh14" style={{ 'margin': '0' }}>Marketing strategy (Social Media)</label>
                                              </div> */}
                                                                <div className="form-check form-check-inline chk col-md-6" style={{ 'display': 'flex', 'flexDirection': 'row' }} onClick={() => this.toggle()}>
                                                                    <input className="form-check-input" checked={this.state.fields.allAreas.includes("Field work in Marketing")} id="inlineCheckboxh14" type="checkbox" name="allAreas" value="Field work in Marketing" onChange={this.handleInputChange} />
                                                                    <label className="form-check-label" htmlFor="inlineCheckboxh14" style={{ 'margin': '0' }}>Field work in Marketing</label>
                                                                </div>
                                                                {/* <div className="form-check form-check-inline chk col-md-6" style={{ 'display': 'flex', 'flexDirection': 'row' }} onClick={() => this.toggle()}>
                                                  <input className="form-check-input" checked={this.state.fields.allAreas.includes("Events Organising")} id="inlineCheckboxh15" type="checkbox" name="allAreas" value="Events Organising" onChange={this.handleInputChange} />
                                                  <label className="form-check-label" htmlFor="inlineCheckboxh15" style={{ 'margin': '0' }}>Events Organising</label>
                                              </div>
                                              <div className="form-check form-check-inline chk col-md-6" style={{ 'display': 'flex', 'flexDirection': 'row' }} onClick={() => this.toggle()}>
                                                  <input className="form-check-input" checked={this.state.fields.allAreas.includes("Report Writing & Newsletter Writing")} id="inlineCheckboxh16" type="checkbox" name="allAreas" value="Report Writing & Newsletter Writing" onChange={this.handleInputChange} />
                                                  <label className="form-check-label" htmlFor="inlineCheckboxh16" style={{ 'margin': '0' }}>Report Writing & Newsletter Writing</label>
                                              </div> */}
                                                                {/* <div className="form-check form-check-inline chk col-md-6" style={{ 'display': 'flex', 'flexDirection': 'row' }} onClick={() => this.toggle()}>
                                                  <input className="form-check-input" checked={this.state.fields.allAreas.includes(" Reading Campaign")} id="inlineCheckboxh17" type="checkbox" name="allAreas" value=" Reading Campaign" onChange={this.handleInputChange} />
                                                  <label className="form-check-label" htmlFor="inlineCheckboxh17" style={{ 'margin': '0' }}> Reading Campaign(Telling stories to children and doing some activity with them(only on weekends)</label>
                                              </div> */}
                                                                {/* <div className="form-check form-check-inline chk col-md-6" style={{ 'display': 'flex', 'flexDirection': 'row' }} onClick={() => this.toggle()}>
                                                  <input className="form-check-input" checked={this.state.fields.allAreas.includes("Translation from English to Hindi")} id="inlineCheckboxh18" type="checkbox" name="allAreas" value="Translation from English to Hindi" onChange={this.handleInputChange} />
                                                  <label className="form-check-label" htmlFor="inlineCheckboxh18" style={{ 'margin': '0' }}>Translation from English to Hindi</label>
                                              </div> */}
                                                                {/* <div className="form-check form-check-inline chk col-md-6" style={{ 'display': 'flex', 'flexDirection': 'row' }} onClick={() => this.toggle()}>
                                                  <input className="form-check-input" checked={this.state.fields.allAreas.includes("Translation from English/ Hindi to Gujarati")} id="inlineCheckboxh19" type="checkbox" name="allAreas" value="Translation from English/ Hindi to Gujarati" onChange={this.handleInputChange} />
                                                  <label className="form-check-label" htmlFor="inlineCheckboxh19" style={{ 'margin': '0' }}>Translation from English/ Hindi to Gujarati</label>
                                              </div> */}
                                                                {/* <div className="col-lg-6">
                                          <input
                                              type="text"
                                              name="areasOther"
                                              className="form-control input-field"
                                              placeholder="Other"
                                              value={this.state.fields.areasOther}
                                              onChange={this.handleChange}
                                          />
                                      </div> */}
                                                            </div>

                                                        </div>

                                                }


                                                {/* <div className="form-group col-lg-6">
                                            <label htmlFor="ambassador">11. Name of the Asaadharan Campus Ambassador</label>

                                            <input
                                                type="text"
                                                name="ambassador"
                                                id="ambassador"
                                                className="form-control input-field"
                                                placeholder="Enter Mobile Number"
                                                value={this.state.fields.ambassador || ""}
                                                onChange={this.handleChange}
                                            />
                                        </div> */}
                                            </div>
                                            : null}

                                        <div className="col-lg-12  justify-content-left">
                                            {
                                                (this.state.links && this.state.links.length > 0)
                                                && this.state.links.map((idx, index) => (
                                                    <div>
                                                        <a href={idx} key={index} target="_blank" rel="noreferrer" className="whatsapp-style">
                                                            <i class="fa fa-whatsapp mr-1" aria-hidden="true"></i>
                                                            Join our WhatsApp Group <h5 className="ml-2 text-danger">Compusory to join the group *</h5>
                                                        </a>
                                                    </div>
                                                ))
                                            }

                                        </div>
                                        <div className="col-lg-12 d-flex justify-content-center">
                                            <button
                                                type="submit"
                                                id="submit_btn_volunteer"
                                                value="Submit"
                                                className="btn center-block mt-40 col-lg-4"
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="mt-20 col-lg-12 d-flex">
                                <p className="text-left">PLEASE NOTE — </p>
                                <p className="text-left"> The Certificate will be provided on the basis of regularity and commitment to the work.</p>
                            </div>
                        </div>

                    </div >
                    {/* <!-- /container--> */}
                </section >
                {/* <!-- /Section ends --> */}
            </div >
        );
    }
}

export default FellowQuestions;
