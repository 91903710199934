import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  createTheme,
  MuiThemeProvider,
} from "@material-ui/core";
import { getGallery } from "../Sevices/service";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Loader from "../Components/Loader";

const styles = (theme) => ({
  formControl: {
    minWidth: "100%",
    padding: "10px 0 2px",
    margin: "8px 0",
    boxShadow: "6px 6px 0px rgb(103 102 102 / 20%)",
    borderRadius: "12px",
    // minWidth: 120,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(1),
  },
  chipLable: {
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen","Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue","sans-serif" !important',
    fontSize: "17px !important",
    padding: "0 15px",
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
});

const myTheme = createTheme({
  overrides: {
    MuiInput: {
      formControl: {
        "&&:hover": {
          "border-bottom": "0px solid rgba(0, 0, 0, 0.42) !important",
        },
      },
      underline: {
        "&:before": {
          "border-bottom": "0px solid rgba(0, 0, 0, 0.42) !important",
        },
        "&:after": {
          "border-bottom": "0px solid #3f51b5 !important",
        },
      },
    },
    MuiSelect: {
      select: {
        "&:focus": {
          borderRadius: "0",
          backgroundColor: "white",
        },
      },
    },
    "label+": {
      MuiInput: {
        formControl: {
          marginTop: "5px !important",
        },
      },
    },
    MuiChip: {
      root: {
        fontSize: "1rem",
      },
    },
    MuiDialog: {
      paperFullWidth: {
        width: "fit-content !important"
      }
    },
    MuiDialogContent: {
      root: {
        padding: "8px !important"
      }
    }
  },
});

class Gallery extends Component {
  state = {
    open: false,
    fullWidth: true,
    maxWidth: 'sm',
    personName: [],
    images: [],
    link: '',
    newImageObj: [],
    loaderAction: true
  };

  componentDidMount() {

    window.scrollTo(0, 0);

    getGallery(localStorage.getItem('groupId'))
      .then(response => {
        let newArray = [];
        // let newImageObj = {};
        let imagesArray = response.data.gallery.map((image, index) => {
          let jsonObj = {};
          if (image.images !== null && image.images.length !== 0) {
            let imagesSplit = image.images.split(',');
            jsonObj = {
              name: image.name,
              images: imagesSplit
            }
          }
          return jsonObj;
        });

        for (let i = 0; i <= imagesArray.length; i++) {
          if (imagesArray[i] !== undefined) {
            newArray = newArray.concat(imagesArray[i]);
          }
        }
        this.setState({ newImageObj: newArray }, () => console.log());
        setTimeout(() => {
          this.setState({ loaderAction: false });
        }, 5000);
      })
      .catch(error => {
        setTimeout(() => {
          this.setState({ loaderAction: false });
        }, 3000);
      })
  }

  handleClickOpen = (link) => {
    this.setState({ open: true, link: link });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleMaxWidthChange = event => {
    this.setState({ maxWidth: event.target.value });
  };

  handleFullWidthChange = event => {
    this.setState({ fullWidth: event.target.checked });
  };

  render() {
    const displayImages = this.state.newImageObj.map((image, index) => {
      return (
        <div className="col-md-12" key={index + '1'}>
          <div id="lightbox">
            {image.images.map((img, index2) => {
              if (img.match(/.(jpg|jpeg|png|gif)$/i)) {
                return (
                  <div className="col-sm-cust-7 col-md-6 col-lg-4 events  mb-50 mb-sm-50" key={index2 + '3'}>
                    <div className="portfolio-item">
                      <div className="gallery-thumb">
                        <img className="img-responsive" src={`https://asaadharan.org/beta/sitemanager/uploads/${img}`} alt="" />
                        <span className="overlay-mask"></span>
                        <a href="#" onClick={() => this.handleClickOpen(`https://asaadharan.org/beta/sitemanager/uploads/${img}`)} data-gal="prettyPhoto[gallery]" className="link centered"
                          title="You can add caption to pictures.">
                          <i className="fa fa-expand"></i></a>
                      </div>
                    </div>
                  </div>
                )
              }
              else {
                return null;
              }
            })}
          </div>
        </div>
      )
    });
    const videos = this.state.newImageObj.map((image, index) => {
      return (<div className="col-md-12" key={index + '2'}>
        <div id="lightbox">

          {image.images.map((img, index2) => {
            if (img.match(/.(mov|avi|wmv|flv|3gp|mp4|mpg)$/i)) {
              return (
                <div className="col-sm-6 col-md-6 col-lg-4 facilities  mb-50" key={index2 + '4'}>
                  <div className="portfolio-item">
                    <div className="gallery-thumb">
                      <video controls style={{ "width": "100%" }}>
                        <source className="img-responsive" src={`https://asaadharan.org/beta/sitemanager/uploads/${img}`} type="video/mp4" />
                        {/* <source src="#"  type="video/ogg"/> */}
                      </video>
                      <span className="overlay-mask"></span>
                      <a href="#" onClick={() => this.handleClickOpen(`https://asaadharan.org/beta/sitemanager/uploads/${img}`)} data-gal="prettyPhoto[gallery]" className="link centered"
                        title="You can add caption to pictures.">
                        <i className="fa fa-expand"></i></a>
                    </div>
                  </div>
                </div>
              )
            }
            else {
              return null;
            }
          })}
        </div>
      </div>
      )
    });

    return (
      <div className="full" id="gallery">
        {this.state.loaderAction ? <Loader /> : null}
        <MuiThemeProvider theme={myTheme}>
          <section id="gallery" className="color-section white-bgcolor">
            <svg
              id="triangleShadow"
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              width="100%"
              height="100"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
            >
              <path className="trianglePath1" d="M0 0 L50 100 L100 0 Z" />
            </svg>
            <div className="container pt-75">
              <div className="col-lg-8 col-lg-offset-2">
                <div className="section-heading">
                  <h2 className="gallery-head">
                    {localStorage.getItem('group_title') !== '' || localStorage.getItem('group_title') === null || localStorage.getItem('group_title') === undefined ? localStorage.getItem('group_title') : 'Images'}
                  </h2>
                </div>
              </div>
              <div className="text-center col-md-12 mb-20">
                <p className="text-left">
                  {localStorage.getItem('group_description') !== '' || localStorage.getItem('group_description') === null || localStorage.getItem('group_description') === undefined ? localStorage.getItem('group_description') : ''}
                </p>
              </div>
              <div className="text-center col-md-12">
              </div>
              <div className="row">
                {displayImages}
              </div>
              <div className="text-center col-md-12">
              </div>
              <div className="row">
                <div className="col-md-12 pt-20">
                  <div id="lightbox">
                    {videos}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Dialog
            fullWidth={this.state.fullWidth}
            maxWidth={this.state.maxWidth}
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby="max-width-dialog-title"
          >

            <DialogContent>
              {this.state.link.match(/.(jpg|jpeg|png|gif)$/i) ?
                <img src={this.state.link} alt="" style={{ width: '500px' }} />
                :
                <video controls className="video-border">
                  <source className="img-responsive" src={this.state.link} type="video/mp4" />
                  {/* <source src="#"  type="video/ogg"/> */}
                </video>
              }

            </DialogContent>

          </Dialog>
        </MuiThemeProvider>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Gallery);
